<template>
  <div class="input-wrapper" :class="{ focused, disabled }">
    <label v-if="label" :for="inputId" class="input-label">
      {{ label }}
      <span v-if="required" class="required-star">*</span>
    </label>

    <div class="input-container" :class="{ 'has-icon': icon }">
      <span v-if="icon" class="input-icon">
        <i :class="icon"></i>
      </span>

      <input :id="inputId" :type="type" :value="modelValue" :placeholder="placeholder" :required="required"
        :disabled="disabled" :class="[customClass, { 'has-error': error }]" @input="handleInput" @focus="focused = true"
        @blur="handleBlur" ref="input" />

      <span v-if="type === 'password'" class="toggle-password" @click="togglePasswordVisibility">
        <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
      </span>
    </div>

    <transition name="fade">
      <p v-if="error" class="error-message">
        <i class="fas fa-exclamation-circle"></i>
        {{ error }}
      </p>
    </transition>
  </div>
</template>

<script>
import { ref, computed } from 'vue'

export default {
  name: 'CustomInput',

  props: {
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    validation: {
      type: Function,
      default: null
    }
  },

  setup(props, { emit }) {
    const focused = ref(false)
    const showPassword = ref(false)
    const error = ref('')
    const input = ref(null)

    const inputId = computed(() =>
      `input-${props.label?.toLowerCase().replace(/\s+/g, '-') || Math.random().toString(36).substr(2, 9)}`
    )

    const handleInput = (event) => {
      emit('update:modelValue', event.target.value)
      if (props.validation) {
        const validationResult = props.validation(event.target.value)
        error.value = typeof validationResult === 'string' ? validationResult : ''
      }
    }

    const handleBlur = () => {
      focused.value = false
      emit('blur')
    }

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value
      if (input.value) {
        input.value.type = showPassword.value ? 'text' : 'password'
      }
    }

    return {
      focused,
      showPassword,
      error,
      input,
      inputId,
      handleInput,
      handleBlur,
      togglePasswordVisibility
    }
  }
}
</script>

<style scoped>
.input-wrapper {
  margin-bottom: 1rem;
  width: 100%;
}

.input-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  transition: color 0.2s ease;
  line-height: 1.2;
}

.required-star {
  color: #f270f9;
  margin-left: 0.25rem;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input-icon {
  position: absolute;
  left: 1rem;
  color: #9ca3af;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  pointer-events: none;
  /* Ensures the icon doesn't interfere with input interaction */
}

input {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 0.95rem;
  line-height: 1.5;
  border-radius: 0.75rem;
  border: 2px solid #e5e7eb;
  background-color: #ffffff;
  transition: all 0.2s ease;
  color: #1f2937;
  min-height: 2.75rem;
  /* Ensures consistent height across devices */
  -webkit-appearance: none;
  /* Removes default iOS styles */
  -moz-appearance: none;
  appearance: none;
}

/* Improve tap target size on mobile */
input,
.toggle-password {
  touch-action: manipulation;
}

input::placeholder {
  color: #9ca3af;
  opacity: 1;
  /* Ensures consistent opacity across browsers */
}

.has-icon input {
  padding-left: 2.75rem;
}

/* Focus states */
.input-wrapper.focused .input-label {
  color: #f270f9;
}

.input-wrapper.focused input {
  border-color: #f270f9;
  box-shadow: 0 0 0 3px rgba(242, 112, 249, 0.1);
}

.input-wrapper.focused .input-icon {
  color: #f270f9;
}

/* Hover states */
@media (hover: hover) {
  input:hover:not(:disabled) {
    border-color: #d1d5db;
  }

  .toggle-password:hover {
    color: #6b7280;
  }
}

/* Error states */
input.has-error {
  border-color: #ef4444;
}

.error-message {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #ef4444;
  display: flex;
  align-items: center;
  gap: 0.375rem;
  line-height: 1.2;
  padding: 0 0.25rem;
}

/* Disabled states */
.input-wrapper.disabled .input-label {
  color: #9ca3af;
}

input:disabled {
  background-color: #f3f4f6;
  border-color: #e5e7eb;
  color: #9ca3af;
  cursor: not-allowed;
  opacity: 1;
  /* Ensures consistent opacity across browsers */
}

/* Password toggle button */
.toggle-password {
  position: absolute;
  right: 1rem;
  color: #9ca3af;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  /* Ensures good tap target size */
  min-height: 2rem;
  border-radius: 0.375rem;
  margin: -0.25rem;
  /* Compensates for padding while maintaining visual alignment */
  background: transparent;
  border: none;
}

/* Animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-0.25rem);
}

/* Autofill styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  -webkit-text-fill-color: #1f2937;
  transition: background-color 5000s ease-in-out 0s;
  font-size: inherit;
  /* Prevents font size change on autofill */
}

/* Focus outline for accessibility */
input:focus {
  outline: none;
}

input:focus-visible {
  outline: none;
  border-color: #f270f9;
  box-shadow: 0 0 0 3px rgba(242, 112, 249, 0.1);
}

/* Media queries for responsive design */
@media (max-width: 640px) {
  input {
    padding: 0.625rem 0.875rem;
    font-size: 0.875rem;
  }

  .has-icon input {
    padding-left: 2.5rem;
  }

  .input-icon {
    left: 0.875rem;
  }

  .toggle-password {
    right: 0.875rem;
  }
}

/* Extra small devices */
@media (max-width: 360px) {
  .input-wrapper {
    margin-bottom: 0.75rem;
  }

  input {
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.5rem;
    min-height: 2.5rem;
  }

  .has-icon input {
    padding-left: 2.25rem;
  }

  .input-icon {
    left: 0.75rem;
    width: 1rem;
    height: 1rem;
  }

  .toggle-password {
    right: 0.75rem;
    padding: 0.375rem;
    min-width: 1.75rem;
    min-height: 1.75rem;
  }

  .input-label {
    font-size: 0.8125rem;
    margin-bottom: 0.375rem;
  }

  .error-message {
    font-size: 0.8125rem;
    margin-top: 0.375rem;
  }
}

/* Handle device-specific quirks */
@supports (-webkit-touch-callout: none) {

  /* iOS-specific fixes */
  input {
    font-size: 16px;
    /* Prevents zoom on focus in iOS */
  }

  @media (max-width: 640px) {
    input {
      font-size: 16px;
      /* Maintains no-zoom size on smaller screens */
    }
  }
}

/* High contrast mode support */
@media (forced-colors: active) {
  .input-wrapper.focused input {
    outline: 2px solid transparent;
    outline-offset: 2px;
    border: 2px solid SelectedItem;
  }

  .toggle-password:focus {
    outline: 2px solid SelectedItem;
  }
}
</style>