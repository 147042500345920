<template>
    <div class="landing-container">
        <!-- Hero Section -->
        <div class="hero-section">
            <div class="animated-background">
                <div class="floating-elements">
                    <!-- Decorative SVG Elements -->
                    <svg class="floating-element book-1" viewBox="0 0 24 24" width="120" height="120">
                        <path fill="#282772"
                            d="M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 18H6V4h2v8l2.5-1.5L13 12V4h5v16z" />
                    </svg>
                    <svg class="floating-element book-2" viewBox="0 0 24 24" width="100" height="100">
                        <path fill="#f27cf9"
                            d="M21 5c-1.11-.35-2.33-.5-3.5-.5-1.95 0-4.05.4-5.5 1.5-1.45-1.1-3.55-1.5-5.5-1.5S2.45 4.9 1 6v14.65c0 .25.25.5.5.5.1 0 .15-.05.25-.05C3.1 20.45 5.05 20 6.5 20c1.95 0 4.05.4 5.5 1.5 1.35-.85 3.8-1.5 5.5-1.5 1.65 0 3.35.3 4.75 1.05.1.05.15.05.25.05.25 0 .5-.25.5-.5V6c-.6-.45-1.25-.75-2-1zm0 13.5c-1.1-.35-2.3-.5-3.5-.5-1.7 0-4.15.65-5.5 1.5V8c1.35-.85 3.8-1.5 5.5-1.5 1.2 0 2.4.15 3.5.5v11.5z" />
                    </svg>
                    <svg class="floating-element book-3" viewBox="0 0 24 24" width="140" height="140">
                        <path fill="#282772" opacity="0.7"
                            d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0-2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H8V4h12v12z" />
                    </svg>

                    <!-- Abstract Shapes -->
                    <div class="shape circle-1"></div>
                    <div class="shape circle-2"></div>
                    <div class="shape circle-3"></div>
                </div>
            </div>

            <div class="hero-content">
                <h1 class="main-title">Welcome to B<span class="accent">oo</span>ksVille</h1>
                <p class="subtitle">Your Digital Haven for Book Lovers</p>
                <div class="features">
                    <div class="feature">
                        <i class="fas fa-book"></i>
                        <span>Vast Collection</span>
                    </div>
                    <div class="feature">
                        <i class="fas fa-users"></i>
                        <span>Community</span>
                    </div>
                    <div class="feature">
                        <i class="fas fa-truck-fast"></i>
                        <span>Fast Delivery</span>
                    </div>
                </div>
                <button @click="handleContinue" class="cta-button">
                    {{ isAuthenticated ? 'Continue to Library' : 'Get Started' }}
                    <i class="fas fa-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    name: 'Landing',
    setup() {
        const store = useStore()
        const router = useRouter()

        const isAuthenticated = computed(() => store.getters.isAuthenticated)

        const handleContinue = () => {
            if (isAuthenticated.value) {
                router.push('/home')
            } else {
                router.push('/login')
            }
        }

        return {
            isAuthenticated,
            handleContinue
        }
    }
}
</script>

<style scoped>
.landing-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.hero-section {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.animated-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.floating-elements {
    position: relative;
    width: 100%;
    height: 100%;
}

.floating-element {
    position: absolute;
    animation: float 6s ease-in-out infinite;
}

.book-1 {
    top: 20%;
    left: 10%;
    animation-delay: 0s;
}

.book-2 {
    top: 40%;
    right: 15%;
    animation-delay: -2s;
}

.book-3 {
    bottom: 25%;
    left: 20%;
    animation-delay: -4s;
}

/* Abstract shapes */
.shape {
    position: absolute;
    border-radius: 50%;
    opacity: 0.1;
    animation: float 8s ease-in-out infinite;
}

.circle-1 {
    width: 100px;
    height: 100px;
    background: #282772;
    top: 15%;
    right: 20%;
    animation-delay: -1s;
}

.circle-2 {
    width: 150px;
    height: 150px;
    background: #f27cf9;
    bottom: 20%;
    right: 25%;
    animation-delay: -3s;
}

.circle-3 {
    width: 80px;
    height: 80px;
    background: #282772;
    top: 30%;
    left: 25%;
    animation-delay: -5s;
}

.hero-content {
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 2.5rem;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    animation: fadeIn 1s ease-out;
    max-width: 600px;
    margin: 0 1rem;
}

.main-title {
    font-size: 3.5rem;
    color: #282772;
    margin-bottom: 1rem;
    animation: slideUp 1s ease-out;
}

.accent {
    color: #f27cf9;
}

.subtitle {
    font-size: 1.5rem;
    color: #666;
    margin-bottom: 2rem;
    animation: slideUp 1s ease-out 0.2s backwards;
}

.features {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
    animation: slideUp 1s ease-out 0.4s backwards;
}

.feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.feature i {
    font-size: 1.5rem;
    color: #f27cf9;
}

.cta-button {
    padding: 1rem 2rem;
    font-size: 1.2rem;
    background: linear-gradient(135deg, #282772, #f27cf9);
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: transform 0.3s ease;
    animation: slideUp 1s ease-out 0.6s backwards;
    margin: 0 auto;
}

.cta-button:hover {
    transform: translateY(-2px);
}

@keyframes float {

    0%,
    100% {
        transform: translateY(0) rotate(0deg);
    }

    50% {
        transform: translateY(-20px) rotate(5deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .main-title {
        font-size: 2.5rem;
    }

    .subtitle {
        font-size: 1.2rem;
    }

    .features {
        flex-direction: column;
        gap: 1rem;
    }

    .floating-element {
        transform: scale(0.8);
    }

    .hero-content {
        padding: 2rem;
    }
}

@media (max-width: 480px) {
    .main-title {
        font-size: 2rem;
    }

    .hero-content {
        padding: 1.5rem;
    }

    .floating-element {
        transform: scale(0.6);
    }
}
</style>