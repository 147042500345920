<template>
  <router-link :to="`/books/${book.id}`" class="book-card">
    <div class="book-image">
      <img :src="coverImageUrl" :alt="book.title" />
    </div>
    <div class="book-info">
      <div class="rating">
        <i v-for="n in 5" :key="n" class="fas fa-star" :class="{ filled: n <= Math.floor(book.rating || 0) }"></i>
      </div>
      <h3>{{ book.title }}</h3>
      <p class="author">{{ book.author.name }}</p>
      <p class="year">{{ book.year }}</p>
      <div class="price-cart">
        <span class="price">Rs {{ formatPrice(book.price) }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import { getAssetUrl } from '@/api/axios';

export default {
  name: 'BookCard',
  props: {
    book: {
      type: Object,
      required: true,
    },
  },
  computed: {
    coverImageUrl() {
      return getAssetUrl(this.book.cover_image);
    }
  },
  methods: {
    formatPrice(price) {
      return Number(price).toFixed(2);
    },
  },
};
</script>

<style scoped>
.book-card {
  text-decoration: none;
  color: inherit;
  display: block;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.book-card:hover {
  transform: translateY(-5px);
}

.book-image {
  position: relative;
  width: 100%;
  padding-top: 140%;
}

.book-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.book-info {
  padding: 1rem;
}

.rating {
  margin-bottom: 0.5rem;
}

.fa-star {
  color: #ddd;
  margin-right: 2px;
}

.fa-star.filled {
  color: #ffd700;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.author {
  color: #666;
  margin: 0.25rem 0;
  font-size: 0.9rem;
}

.year {
  color: #888;
  font-size: 0.8rem;
  margin: 0.25rem 0;
}

.price-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.price {
  font-weight: 600;
  color: #2c3e50;
}
</style>