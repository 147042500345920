<template>
    <div class="section-container">
        <div class="section-header">
            <h2>People you may know</h2>
        </div>
        <div class="people-grid" :class="{ 'single-column': people.length === 1 }">
            <template v-if="loading">
                <div class="loading-skeleton" v-for="n in 4" :key="`skeleton-${n}`">
                    <div class="skeleton-avatar"></div>
                    <div class="skeleton-content">
                        <div class="skeleton-text"></div>
                        <div class="skeleton-text small"></div>
                    </div>
                </div>
            </template>

            <template v-else-if="people.length">
                <PersonCard v-for="person in people" :key="person.id" :person="{
                    id: person.id,
                    name: `${person.first_name} ${person.last_name}`,
                    avatar: person.profile_picture_url,
                    commonInterests: formatGenres(person.mutual_favorite_genres),
                    requestSent: person.requestSent || false,
                    verified: person.is_verified
                }" @view-profile="goToProfile" @send-request="sendFriendRequest" />
            </template>

            <div v-else class="empty-state">
                <i class="fas fa-users"></i>
                <h3>No Suggestions Available</h3>
                <p>We'll notify you when we find people you might know</p>
            </div>
        </div>

        <div v-if="loading" class="loading-indicator">
            <i class="fas fa-spinner fa-spin"></i> Loading suggestions...
        </div>

        <div v-if="error" class="error-message">
            <i class="fas fa-exclamation-circle"></i>
            {{ error }}
        </div>

        <div v-if="hasMore && !loading" class="load-more">
            <button @click="loadMore" class="load-more-button">
                Load More Suggestions
            </button>
        </div>
    </div>
</template>

<script>
import PersonCard from '@/components/social/card/PersonCard.vue'
import { socialApi } from '@/api/social'

export default {
    name: 'SuggestedPeople',

    components: {
        PersonCard
    },

    data() {
        return {
            people: [],
            loading: false,
            error: null,
            nextPageUrl: null,
            hasMore: false,
            currentPage: 1
        }
    },

    created() {
        this.fetchRecommendations()
    },

    methods: {
        formatGenres(genres) {
            return genres?.map(genre => genre.name) || []
        },

        async fetchRecommendations(page = 1) {
            try {
                this.loading = true
                this.error = null

                const response = await socialApi.getFriendRecommendations(page)

                if (response.status === 'success') {
                    const newPeople = response.results || []

                    if (page === 1) {
                        this.people = newPeople
                    } else {
                        this.people = [...this.people, ...newPeople]
                    }

                    this.nextPageUrl = response.next
                    this.hasMore = !!response.next
                    this.currentPage = page
                }
            } catch (error) {
                this.error = 'Failed to load suggestions. Please try again later.'
                console.error('Error fetching recommendations:', error)
            } finally {
                this.loading = false
            }
        },

        async loadMore() {
            if (this.hasMore && !this.loading) {
                await this.fetchRecommendations(this.currentPage + 1)
            }
        },

        async sendFriendRequest(personId) {
            try {
                const personIndex = this.people.findIndex(p => p.id === personId)
                if (personIndex === -1) return

                // Create a new copy of the person object with updated requestSent status
                const updatedPerson = {
                    ...this.people[personIndex],
                    requestSent: true
                }

                // Create a new array with the updated person
                this.people = [
                    ...this.people.slice(0, personIndex),
                    updatedPerson,
                    ...this.people.slice(personIndex + 1)
                ]

                await socialApi.sendFriendRequest(personId)

                this.$emit('notification', {
                    message: `Friend request sent to ${updatedPerson.first_name}`,
                    type: 'success'
                })
            } catch (error) {
                // Revert the UI state if the API call fails
                const personIndex = this.people.findIndex(p => p.id === personId)
                if (personIndex !== -1) {
                    const updatedPerson = {
                        ...this.people[personIndex],
                        requestSent: false
                    }

                    this.people = [
                        ...this.people.slice(0, personIndex),
                        updatedPerson,
                        ...this.people.slice(personIndex + 1)
                    ]
                }

                this.$emit('notification', {
                    message: 'Failed to send friend request',
                    type: 'error'
                })
            }
        },

        goToProfile(userId) {
            this.$router.push(`/profile/${userId}`)
        }
    }
}
</script>

<style scoped>
.people-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 1.5rem;
}

.people-grid.single-column {
    grid-template-columns: 1fr;
    max-width: 600px;
    margin: 0 auto;
}

.empty-state {
    padding: 3rem 2rem;
    text-align: center;
    color: var(--text-gray);
    grid-column: 1 / -1;
}

.empty-state i {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.empty-state h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--text-dark);
}

.empty-state p {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
}

/* Loading Skeletons */
.loading-skeleton {
    display: flex;
    gap: 1rem;
    padding: 1.5rem;
    background: var(--white);
    border-radius: 1rem;
    box-shadow: var(--shadow-sm);
}

.skeleton-avatar {
    width: 80px;
    height: 80px;
    border-radius: 1rem;
    background: var(--bg-light);
    animation: pulse 1.5s infinite;
}

.skeleton-content {
    flex: 1;
}

.skeleton-text {
    height: 1.5rem;
    background: var(--bg-light);
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
    animation: pulse 1.5s infinite;
}

.skeleton-text.small {
    width: 60%;
    height: 1rem;
}

@keyframes pulse {

    0%,
    100% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }
}

/* Loading and Error States */
.loading-indicator,
.error-message {
    text-align: center;
    padding: 1rem;
    color: var(--text-gray);
    grid-column: 1 / -1;
}

.error-message {
    color: #ef4444;
}

.load-more {
    text-align: center;
    padding: 1rem;
    grid-column: 1 / -1;
}

.load-more-button {
    background: var(--primary-light);
    color: var(--primary-purple);
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.75rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.load-more-button:hover {
    background: var(--primary-purple);
    color: white;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .people-grid {
        gap: 1.5rem;
    }
}

@media (max-width: 768px) {
    .people-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .loading-skeleton {
        padding: 1rem;
    }

    .skeleton-avatar {
        width: 60px;
        height: 60px;
    }
}

@media (max-width: 480px) {
    .people-grid {
        padding: 1rem;
    }
}
</style>