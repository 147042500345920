<template>
  <div class="book-card-container">
    <div class="book-card">
      <router-link :to="`/books/${book.id}`" class="book-link">
        <div class="book-image">
          <img :src="getBookCoverUrl" :alt="book.title" />
          <button @click.prevent="handleCartAction" class="quick-add" :class="{ 'in-cart': cartStatus.exists }">
            <i :class="cartStatus.exists ? 'fas fa-check' : 'fas fa-plus'"></i>
          </button>
        </div>
        <div class="book-info">
          <div class="rating">
            <i v-for="n in 5" :key="n" class="fas fa-star" :class="{ filled: n <= Math.floor(book.rating || 0) }"></i>
          </div>
          <h3>{{ book.title }}</h3>
          <p class="author">{{ book.author.name }}</p>
          <p class="year">{{ book.year }}</p>
          <div class="price-cart">
            <span class="price">Rs {{ formatPrice(book.price) }}</span>
            <button @click.prevent="handleCartAction" class="add-cart" :class="{ 'in-cart': cartStatus.exists }">
              {{ cartStatus.exists ? 'In Cart' : 'Add to Cart' }}
            </button>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import api, { getAssetUrl } from '@/api/axios'

export default {
  name: 'BookCardWithCart',
  props: {
    book: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const toast = useToast()
    const cartStatus = ref({
      exists: false,
      cart_item_id: null,
      purchase_type: null,
      quantity: 0,
      rental_period: null
    })

    const formatPrice = (price) => {
      return Number(price).toFixed(2)
    }

    const getBookCoverUrl = computed(() => {
      return getAssetUrl(props.book.cover_image)
    })

    const checkCartStatus = async () => {
      try {
        if (!store.getters.isAuthenticated) return

        const response = await api.get(`/cart/${props.book.id}/check`, {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          }
        })

        if (response.data.success) {
          cartStatus.value = response.data.data
        }
      } catch (error) {
        console.error('Error checking cart status:', error)
        if (error.response?.status === 401) {
          store.commit('logout')
          cartStatus.value.exists = false
        }
      }
    }

    const removeFromCart = async () => {
      try {
        if (!cartStatus.value.cart_item_id) return

        const response = await api.delete(
          `/cart/${cartStatus.value.cart_item_id}/remove`,
          {
            headers: {
              Authorization: `Bearer ${store.getters.token}`,
              'Content-Type': 'application/json',
            },
          }
        )

        if (response.data.success) {
          cartStatus.value.exists = false
          cartStatus.value.cart_item_id = null
          toast.success('Removed from cart successfully', {
            timeout: 2000,
            position: 'top-center',
            icon: 'fas fa-check-circle',
          })
          store.commit('updateCart', response.data.cart)
        }
      } catch (error) {
        console.error('Error removing from cart:', error)
        toast.error('Failed to remove from cart', {
          timeout: 2000,
          position: 'top-center',
          icon: 'fas fa-exclamation-circle',
        })
        if (error.response?.status === 401) {
          store.commit('logout')
          router.push({
            path: '/login',
            query: { redirect: router.currentRoute.value.fullPath },
          })
        }
      }
    }

    const addToCart = async () => {
      try {
        if (!store.getters.isAuthenticated) {
          router.push({
            path: '/login',
            query: { redirect: router.currentRoute.value.fullPath },
          })
          return
        }

        const response = await api.post(
          `/cart/${props.book.id}/add`,
          {
            purchase_type: "buy"
          },
          {
            headers: {
              Authorization: `Bearer ${store.getters.token}`,
              'Content-Type': 'application/json',
            },
          }
        )

        if (response.data.success) {
          store.commit('updateCart', response.data.cart)
          await checkCartStatus()
          toast.success('Added to cart successfully', {
            timeout: 2000,
            position: 'top-center',
            icon: 'fas fa-check-circle',
          })
        }
      } catch (error) {
        console.error('Error adding to cart:', error)
        toast.error('Failed to add to cart', {
          timeout: 2000,
          position: 'top-center',
          icon: 'fas fa-exclamation-circle',
        })
        if (error.response?.status === 401) {
          store.commit('logout')
          router.push({
            path: '/login',
            query: { redirect: router.currentRoute.value.fullPath },
          })
        }
      }
    }

    const handleCartAction = async () => {
      if (cartStatus.value.exists) {
        await removeFromCart()
      } else {
        await addToCart()
      }
    }

    onMounted(() => {
      checkCartStatus()
    })

    return {
      cartStatus,
      formatPrice,
      handleCartAction,
      getBookCoverUrl,
    }
  }
}
</script>

<style scoped>
.book-card-container {
  position: relative;
  margin-top: 20px;
}

.book-card {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.book-card:hover {
  transform: translateY(-5px);
}

.book-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.book-image {
  position: relative;
  aspect-ratio: 2/3;
}

.book-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quick-add {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background: #f27cf9;
  color: white;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s;
  z-index: 2;
}

.quick-add.in-cart {
  opacity: 1;
  background-color: #4CAF50;
}

.book-card:hover .quick-add {
  opacity: 1;
}

.book-info {
  padding: 15px;
}

.rating {
  color: #ffd700;
  margin-bottom: 10px;
}

.book-info h3 {
  font-size: 16px;
  color: #282772;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.author {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.price {
  font-size: 18px;
  font-weight: bold;
  color: #282772;
}

.add-cart {
  background-color: #f27cf9;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.add-cart.in-cart {
  background-color: #4CAF50;
}

.rating .fa-star {
  color: #ddd;
}

.rating .filled {
  color: #ffd700;
}

/* Media queries for responsive design */
@media screen and (max-width: 480px) {
  .book-card-container {
    margin-top: 15px;
  }

  .book-image {
    aspect-ratio: 2/2;
  }

  .book-info {
    padding: 8px;
  }

  .book-info h3 {
    font-size: 14px;
    margin-bottom: 2px;
    -webkit-line-clamp: 1;
  }

  .author {
    font-size: 12px;
    margin-bottom: 2px;
  }

  .rating {
    margin-bottom: 4px;
  }

  .rating .fa-star {
    font-size: 12px;
  }

  .price-cart {
    margin-top: 8px;
  }

  .price {
    font-size: 14px;
  }

  .add-cart {
    padding: 4px 10px;
    font-size: 12px;
  }

  .quick-add {
    width: 1.5rem;
    height: 1.5rem;
    bottom: 0.5rem;
    right: 0.5rem;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .book-card-container {
    margin-top: 15px;
  }

  .book-image {
    aspect-ratio: 2/2.2;
  }

  .book-info {
    padding: 10px;
  }

  .book-info h3 {
    font-size: 15px;
    margin-bottom: 3px;
  }

  .author {
    font-size: 13px;
    margin-bottom: 3px;
  }

  .rating {
    margin-bottom: 6px;
  }

  .price-cart {
    margin-top: 10px;
  }

  .price {
    font-size: 16px;
  }

  .add-cart {
    padding: 6px 12px;
    font-size: 13px;
  }
}
</style>