<template>
  <aside v-if="!isMobile" class="sidebar">
    <nav class="sidebar-nav">
      <div class="user-profile">
        <img :src="user.profile_picture || '/default-avatar.png'" :alt="user.name" class="profile-pic" />
        <div class="user-info">
          <h3 class="user-name">{{ user.first_name }} {{ user.last_name }}</h3>
          <p class="user-email">{{ user.email }}</p>
        </div>
      </div>
      <ul class="nav-list">
        <li v-for="item in menuItems" :key="item.id">
          <router-link :to="item.to" custom v-slot="{ isActive, navigate }">
            <button @click="navigate" :class="['nav-item', { active: isActive }]">
              <i :class="item.icon"></i>
              <span>{{ item.label }}</span>
            </button>
          </router-link>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
export default {
  name: 'ProfileSidebar',
  props: {
    user: {
      type: Object,
      required: true,
    },
    menuItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768
    }
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped>
.sidebar {
  width: 280px;
  background: var(--background-white);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  z-index: 40;
  height: calc(100vh - 64px);
  position: sticky;
  top: 64px;
  left: 0;
}

.user-profile {
  padding: 1.5rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.profile-pic {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--primary-light);
}

.user-info {
  overflow: hidden;
}

.user-name {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-email {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin: 0.25rem 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-nav {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.nav-list {
  list-style: none;
  padding: 1rem;
  margin: 0;
}

.nav-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.875rem 1rem;
  margin: 0.25rem 0;
  border-radius: 0.5rem;
  border: none;
  background: transparent;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.95rem;
  font-weight: 500;
  text-align: left;
}

.nav-item:hover {
  background: var(--background-light);
  color: var(--primary-color);
}

.nav-item.active {
  background: #282772;
  color: white;
}

.nav-item i {
  margin-right: 1rem;
  width: 20px;
  text-align: center;
  font-size: 1.1rem;
}

.sidebar-nav::-webkit-scrollbar {
  width: 6px;
}

.sidebar-nav::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.sidebar-nav::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 3px;
}
</style>