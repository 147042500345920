<template>
  <div class="profile-container">
    <!-- Show sidebar only on desktop -->
    <ProfileSidebar v-if="!isMobile" :user="userProfile" :menuItems="menuItems" :isSidebarOpen="isSidebarOpen"
      @toggle-sidebar="toggleSidebar" />

    <main class="main-content">
      <div class="content-wrapper">
        <!-- Mobile Menu -->
        <div v-if="isMobile && isRootRoute" class="mobile-menu">
          <div class="user-profile-header">
            <img :src="userProfile.avatar || '/default-avatar.png'" :alt="userProfile.name" class="profile-pic" />
            <div class="user-info">
              <h3 class="user-name">{{ userProfile.name }}</h3>
              <p class="user-email">{{ userProfile.email }}</p>
            </div>
          </div>
          <div class="menu-list">
            <router-link v-for="item in menuItems" :key="item.id" :to="item.to" class="menu-item">
              <i :class="item.icon"></i>
              <span>{{ item.label }}</span>
              <i class="fas fa-chevron-right arrow-icon"></i>
            </router-link>
          </div>
        </div>

        <!-- Section Content -->
        <template v-else>
          <!-- Mobile Back Button -->
          <div v-if="isMobile && !isRootRoute" class="mobile-header">
            <button @click="goBack" class="back-button">
              <i class="fas fa-arrow-left"></i>
              <span>Back</span>
            </button>
            <h1 class="section-title">{{ currentSection }}</h1>
          </div>

          <router-view v-slot="{ Component }" :key="$route.fullPath">
            <component :is="Component" :user="userProfile" v-bind="getComponentProps" />
          </router-view>
        </template>

        <!-- Toast Messages -->
        <div v-if="toast.show" :class="['toast', `toast-${toast.type}`]">
          <i :class="['fas', toastIcon]"></i>
          {{ toast.message }}
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import api from '@/api/axios'
import ProfileSidebar from '@/components/account/AccountSidebar.vue'

export default {
  name: 'Account',
  components: {
    ProfileSidebar,
  },
  data() {
    return {
      isSidebarOpen: false,
      isMobile: window.innerWidth <= 768,
      userProfile: {
        name: '',
        email: '',
        avatar: '',
        joinDate: '',
        booksRead: 0,
        reviews: 0,
        bio: '',
        currently_reading: null,
        favorite_books: [],
      },
      settings: {
        emailNotifications: true,
        privacy: 'public',
      },
      menuItems: [
        {
          id: 'currently-reading',
          label: 'Currently Reading',
          icon: 'fas fa-book-reader',
          to: '/account/reading'
        },
        {
          id: 'favorites',
          label: 'Favorites',
          icon: 'fas fa-heart',
          to: '/account/favorites'
        },
        {
          id: 'account-profile',
          label: 'Account',
          icon: 'fas fa-user',
          to: '/account/profile'
        },
        {
          id: 'account-settings',
          label: 'Settings',
          icon: 'fas fa-cog',
          to: '/account/settings'
        },
        {
          id: 'account-orders',
          label: 'Orders',
          icon: 'fas fa-shopping-cart',
          to: '/account/orders'
        },
      ],
      toast: {
        show: false,
        type: 'success',
        message: '',
      },
    }
  },
  computed: {
    isRootRoute() {
      return this.$route.path === '/account'
    },
    currentSection() {
      const currentMenuItem = this.menuItems.find(item => item.to === this.$route.path)
      return currentMenuItem ? currentMenuItem.label : ''
    },
    toastIcon() {
      const icons = {
        success: 'fa-check-circle',
        error: 'fa-exclamation-circle',
        info: 'fa-info-circle',
      }
      return icons[this.toast.type]
    },
    getComponentProps() {
      const props = {
        user: this.userProfile
      }

      if (this.$route.path.startsWith('/account/settings')) {
        props.settings = this.settings
      }

      return props
    }
  },
  methods: {
    showToast(type, message, duration = 3000) {
      this.toast = {
        show: true,
        type,
        message,
      }
      setTimeout(() => {
        this.toast.show = false
      }, duration)
    },

    async fetchUserProfile() {
      try {
        const response = await api.get('/users/profile/')
        if (response.data.success) {
          this.userProfile = {
            ...response.data.data,
            name: `${response.data.data.first_name} ${response.data.data.last_name}`,
            avatar: response.data.data.profile_picture,
            joinDate: new Date(
              response.data.data.created_at
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
            }),
          }
        }
      } catch (error) {
        console.error('Error fetching user profile:', error)
        this.showToast('error', 'Failed to load profile data')
      }
    },

    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen
    },

    goBack() {
      this.$router.push('/account')
    },

    handleResize() {
      this.isMobile = window.innerWidth <= 768
    }
  },
  created() {
    this.fetchUserProfile()
    window.addEventListener('resize', this.handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style scoped>
.profile-container {
  display: flex;
  min-height: 100vh;
  background-color: var(--background-light);
}

.main-content {
  flex: 1;
  padding: 2rem 4rem;
  overflow-y: auto;
}

.mobile-menu {
  padding: 1rem 0;
}

.user-profile-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  background: var(--background-white);
  border-radius: 1rem;
  margin-bottom: 1.5rem;
}

.profile-pic {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--primary-light);
}

.user-info {
  flex: 1;
}

.user-name {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.user-email {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin: 0.25rem 0 0;
}

.menu-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  background: var(--background-white);
  border-radius: 0.75rem;
  color: var(--text-primary);
  text-decoration: none;
  font-weight: 500;
}

.menu-item i {
  width: 24px;
  margin-right: 1rem;
  color: #f27cf9;
}

.arrow-icon {
  margin-left: auto;
  color: var(--text-secondary);
}

.mobile-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  border: none;
  color: var(--text-secondary);
  font-size: 0.95rem;
  font-weight: 500;
  padding: 0;
  cursor: pointer;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.content-wrapper {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
  background: var(--background-white);
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  position: relative;
}

.loading-state,
.error-state {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 2rem;
  text-align: center;
  font-size: 1.1rem;
}

.loading-state {
  color: #4b5563;
}

.error-state {
  color: #dc2626;
}

.toast {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  animation: slideIn 0.3s ease;
  z-index: 1000;
}

.toast-success {
  background-color: #dcfce7;
  color: #16a34a;
}

.toast-error {
  background-color: #fee2e2;
  color: #dc2626;
}

.toast-info {
  background-color: #e0f2fe;
  color: #0284c7;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .main-content {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .profile-container {
    display: block;
  }

  .main-content {
    padding: 1rem;
  }

  .content-wrapper {
    padding: 1rem;
  }

  .toast {
    bottom: 1rem;
    right: 1rem;
    left: 1rem;
  }
}
</style>
