<template>
  <section class="how-it-works">
    <h2 class="section-title">HOW IT WORKS</h2>
    <div class="steps">
      <template v-for="(step, index) in steps" :key="index">
        <div class="step">
          <div class="step-icon">
            <i :class="step.icon"></i>
          </div>
          <h3>{{ step.title }}</h3>
        </div>
        <div v-if="index < steps.length - 1" class="step-line"></div>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HowItWorks',
  props: {
    steps: {
      type: Array,
      required: true,
      validator(value) {
        return value.every(step => 'icon' in step && 'title' in step)
      },
    },
  },
}
</script>

<style>
.how-it-works {
  margin: 4rem 0;
  padding: 3rem;
  text-align: center;
}

.section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.step-icon {
  width: 80px;
  height: 80px;
  background: #f27cf9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
}

.step-line {
  flex: 1;
  height: 2px;
  background: #e0e0e0;
  max-width: 100px;
}

.step h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

@media (max-width: 768px) {
  .how-it-works {
    margin: 2rem 0;
    padding: 1.5rem;
    overflow-x: hidden;
  }

  .section-title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .steps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin-top: 2rem;
    padding: 0 1rem;
  }

  .step {
    gap: 0.5rem;
  }

  .step-icon {
    width: 60px;
    height: 60px;
    font-size: 1.5rem;
  }

  .step h3 {
    font-size: 0.9rem;
  }

  .step-line {
    display: none;
  }
}

@media (max-width: 480px) {
  .steps {
    gap: 1rem;
    padding: 0 0.5rem;
  }

  .step-icon {
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }
}
</style>