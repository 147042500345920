// src/components/book/BreadcrumbNav.vue
<template>
  <div class="breadcrumb">
    <router-link to="/" class="crumb">Home</router-link>
    <span class="separator">/</span>
    <router-link to="/books" class="crumb">Books</router-link>
    <span class="separator">/</span>
    <span class="current">{{ bookTitle }}</span>
  </div>
</template>

<script>
export default {
  name: 'BreadcrumbNav',
  props: {
    bookTitle: {
      type: String,
      required: false,
      default: "cahnge"
    }
  }
}
</script>

<style scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
  padding: 0 1rem;
}

.crumb {
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
}

.separator {
  color: #ccc;
}

.current {
  color: #333;
  font-weight: 500;
}
</style>