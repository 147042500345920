<template>
  <div class="search-content">
    <!-- Loading State -->
    <div v-if="loading" class="search-loading">
      <div class="loading-spinner"></div>
      <p>Searching books...</p>
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="search-error">
      <p>{{ error }}</p>
      <button @click="$emit('retry')" class="retry-button">Try Again</button>
    </div>

    <!-- No Results -->
    <div v-else-if="searchQuery && books.length === 0" class="no-results">
      <p>No books found for "{{ searchQuery }}"</p>
      <p class="suggestion">Try different keywords or check the spelling</p>
    </div>

    <!-- Results Grid -->
    <div v-else-if="books.length > 0" class="search-results">
      <p class="results-count">Found {{ books.length }} books</p>
      <div class="results-grid">
        <BookCard
          v-for="book in books"
          :key="book.id"
          :book="book"
          @add-to-cart="$emit('add-to-cart', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import BookCard from '@/components/shared/BookCard.vue'

export default defineComponent({
  name: 'SearchResults',

  components: {
    BookCard,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    searchQuery: {
      type: String,
      default: '',
    },
    books: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['retry', 'add-to-cart'],

  methods: {
    // Add method to log state changes if needed
    logStateChanges() {
      console.log({
        books: this.books,
        loading: this.loading,
        error: this.error,
        searchQuery: this.searchQuery,
      })
    },
  },

  // Watch for changes if needed
  watch: {
    books: {
      handler(newBooks) {
        console.log('Books updated:', newBooks)
      },
      deep: true,
    },
  },
})
</script>

<style scoped>
.search-content {
  margin-top: 2rem;
}

.results-count {
  text-align: center;
  color: #666;
  margin-bottom: 1.5rem;
}

.results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
}

.search-loading,
.search-error,
.no-results {
  text-align: center;
  padding: 3rem;
  background: white;
  border-radius: 8px;
  margin-top: 2rem;
}

.loading-spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.retry-button {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.suggestion {
  color: #666;
  margin-top: 0.5rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .results-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .results-grid {
    grid-template-columns: 1fr;
  }
}
</style>
