<template>
  <div class="bg">
    <!-- Loading Overlay -->
    <Transition name="fade">
      <div v-if="loading" class="loading-overlay">
        <div class="loading-content">
          <div class="loading-spinner"></div>
          <div class="loading-text">
            <h3>Creating Your Account</h3>
            <p>{{ loadingMessage }}</p>
          </div>
        </div>
      </div>
    </Transition>

    <div class="content-wrapper" :class="{ 'blur-bg': loading }">
      <h1 class="brand">
        B<span class="brand-highlight">OO</span>KSVILLE
      </h1>

      <Transition name="fade">
        <div v-if="error" class="error-message" role="alert">
          <span class="error-icon">⚠</span>
          {{ error }}
        </div>
      </Transition>

      <SignupForm v-bind="formData" :loading="loading" @update:firstName="formData.firstName = $event"
        @update:lastName="formData.lastName = $event" @update:username="formData.username = $event"
        @update:email="formData.email = $event" @update:dateOfBirth="formData.dateOfBirth = $event"
        @update:password="formData.password = $event" @update:confirmPassword="formData.confirmPassword = $event"
        @update:profilePicture="formData.profile_picture = $event" @update:status="formData.status = $event"
        @update:hasAcceptedTerms="formData.hasAcceptedTerms = $event"
        @update:hasAcceptedRentalPolicy="formData.hasAcceptedRentalPolicy = $event" @submit="handleSignup"
        @switch-to-login="$router.push({ name: 'login' })" @error="error = $event" ref="signupForm" />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import SignupForm from '@/components/auth/SignupForm.vue'

const store = useStore()
const router = useRouter()
const signupForm = ref(null)

const formData = reactive({
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  dateOfBirth: '',
  password: '',
  confirmPassword: '',
  profile_picture: null,
  status: '',
  hasAcceptedTerms: false,
  hasAcceptedRentalPolicy: false
})

const loading = ref(false)
const error = ref('')
const loadingMessage = ref('Please wait while we set up your account...')

const loadingMessages = [
  'Creating your profile...',
  'Setting up your account...',
  'Almost there...',
  'Finalizing setup...'
]

let messageInterval

const startLoadingMessages = () => {
  let index = 0
  messageInterval = setInterval(() => {
    loadingMessage.value = loadingMessages[index]
    index = (index + 1) % loadingMessages.length
  }, 2000)
}

const stopLoadingMessages = () => {
  clearInterval(messageInterval)
}

const handleSignup = async () => {
  if (loading.value) return

  if (formData.password !== formData.confirmPassword) {
    error.value = 'Passwords do not match'
    return
  }

  // Validate terms acceptance
  if (!formData.hasAcceptedTerms || !formData.hasAcceptedRentalPolicy) {
    error.value = 'Please accept both Terms of Service and Rental Policy'
    return
  }

  loading.value = true
  error.value = ''
  startLoadingMessages()

  try {
    const signupData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      username: formData.username,
      email: formData.email,
      dateOfBirth: formData.dateOfBirth,
      password: formData.password,
      profile_picture: formData.profile_picture,
      status: formData.status || "",
      hasAcceptedTerms: formData.hasAcceptedTerms,
      hasAcceptedRentalPolicy: formData.hasAcceptedRentalPolicy,
      termsAcceptanceDate: new Date().toISOString()
    }

    const result = await store.dispatch('register', signupData)

    if (result.success) {
      loadingMessage.value = 'Success! Redirecting to your dashboard...'
      setTimeout(() => {
        router.push('/home')
      }, 1000)
    } else {
      error.value = result.error || 'Registration failed'
    }
  } catch (e) {
    console.error('Registration error:', e)
    error.value = e.response?.data?.message || 'An unexpected error occurred'
  } finally {
    stopLoadingMessages()
    loading.value = false
  }
}
</script>

<style scoped>
.bg {
  background-image: linear-gradient(to bottom,
      rgba(246, 248, 255, 0.72),
      rgba(241, 241, 253, 0.75)), url('@/assets/img/bg1.jpg');
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 2rem 1rem;
}

/* Add subtle animated shapes for depth */
.bg::before,
.bg::after {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  background: linear-gradient(45deg, rgba(242, 112, 249, 0.08), rgba(40, 39, 114, 0.08));
  border-radius: 50%;
  filter: blur(40px);
  z-index: 0;
  pointer-events: none;
}

.bg::before {
  top: -200px;
  right: -100px;
  animation: float-slow 15s infinite ease-in-out;
}

.bg::after {
  bottom: -200px;
  left: -100px;
  animation: float-slow 15s infinite ease-in-out reverse;
}

.content-wrapper {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 2.5rem 2rem;
  width: 95%;
  max-width: 500px;
  margin: 20px;
  box-shadow:
    0 10px 40px rgba(0, 0, 0, 0.08),
    0 0 0 1px rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand {
  text-align: center;
  color: #282772;
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 2.8rem;
  letter-spacing: -0.5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.brand-highlight {
  color: #f270f9;
  position: relative;
  display: inline-block;
  animation: float 3s ease-in-out infinite;
}

.error-message {
  background-color: rgba(255, 242, 242, 0.95);
  color: #d32f2f;
  padding: 14px 24px;
  border-radius: 12px;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: center;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid rgba(211, 47, 47, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.error-icon {
  font-size: 1.2em;
}

.auth-footer {
  margin-top: 1.5rem;
  text-align: center;
  width: 100%;
}

.auth-footer-text {
  color: #666;
  font-size: 0.95rem;
}

.text-link {
  background: none;
  border: none;
  color: #f270f9;
  font-weight: 600;
  padding: 4px 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 6px;
}

.text-link:hover {
  color: #282772;
  background: rgba(242, 112, 249, 0.08);
  transform: translateY(-1px);
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(40, 39, 114, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-content {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.loading-text h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.loading-text p {
  font-size: 1rem;
  opacity: 0.9;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #f270f9;
  animation: spin 1s linear infinite;
}

.blur-bg {
  filter: blur(4px);
  pointer-events: none;
  user-select: none;
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

@keyframes float-slow {

  0%,
  100% {
    transform: translate(0, 0) rotate(0deg);
  }

  50% {
    transform: translate(30px, 30px) rotate(5deg);
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .bg {
    padding: 1rem;
  }

  .content-wrapper {
    padding: 2rem 1.5rem;
    width: 100%;
    margin: 10px;
  }

  .brand {
    font-size: 2.2rem;
    margin-bottom: 20px;
  }

  .error-message {
    padding: 12px 16px;
    font-size: 0.9rem;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .content-wrapper {
    padding: 1.5rem 1rem;
  }

  .brand {
    font-size: 2rem;
  }
}

/* Extra small screens */
@media (max-width: 360px) {
  .bg {
    padding: 0.5rem;
  }

  .content-wrapper {
    padding: 1.25rem 0.875rem;
  }

  .brand {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  .error-message {
    padding: 10px 12px;
    font-size: 0.875rem;
  }

  .auth-footer {
    margin-top: 1rem;
  }
}

@media (max-width: 640px) {
  .loading-content {
    padding: 1rem;
  }

  .loading-text h3 {
    font-size: 1.25rem;
  }

  .loading-text p {
    font-size: 0.9rem;
  }

  .loading-spinner {
    width: 40px;
    height: 40px;
  }
}

@media (max-height: 800px) {
  .bg {
    padding: 1rem 1rem;
    min-height: auto;
    height: auto;
  }

  .content-wrapper {
    margin: 1rem 0;
  }
}

.text-link:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px rgba(242, 112, 249, 0.4);
}
</style>