<template>
  <div class="app">
    <Header />

    <main class="main-content">
      <div class="content-container">
        <!-- Left Section -->
        <div class="main-section">
          <!-- Stats Grid -->
          <div class="stats-grid">
            <StatsCard icon="fa-users" color="purple" :number="1" label="Active Friends" :growth="12" />
            <StatsCard icon="fa-book" color="pink" number="338" label="Total Books Read" :growth="8" />
            <StatsCard icon="fa-bookmark" color="blue" number="12" label="Common Genres" />
          </div>

          <!-- Suggested People Component -->
          <SuggestedPeople @view-profile="viewProfile" />

          <section class="section-container">
            <div class="section-header">
              <h2>Famous Reading Groups</h2>
              <span class="view-all">View All</span>
            </div>
            <div class="reading-groups">
              <ReadingGroupCard v-for="group in readingGroups" :key="group.id" :group="group" />
            </div>
          </section>
        </div>
        <!-- Right Section -->
        <div class="right-section">
          <!-- Search -->
          <SearchField v-model="searchQuery" />

          <!-- Friend Requests -->
          <div class="section-container">
            <div class="section-header clickable" @click="toggleFriendRequests">
              <h2>Friend Requests</h2>
              <div class="header-right">
                <i :class="[
                  'fas',
                  isRequestsVisible ? 'fa-chevron-up' : 'fa-chevron-down',
                ]"></i>
              </div>
            </div>
            <div v-show="isRequestsVisible" class="requests-list">
              <FriendRequest />
            </div>
          </div>

          <div class="section-container">
            <div class="friends-list">
              <Friends />
            </div>
          </div>

          <!-- Reading Challenge -->
          <ReadingChallenge :progress="readingProgress" />
        </div>
      </div>
    </main>

    <!-- Notifications -->
    <Transition name="fade">
      <div v-if="notification" :class="['notification', notification.type]">
        <i :class="['fas', notificationIcon]"></i>
        <span class="notification-message">{{ notification.message }}</span>
        <button class="notification-close" @click="clearNotification">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </Transition>
  </div>
</template>

<script>
import Header from '@/components/social/Header.vue'
import StatsCard from '@/components/social/StatsCard.vue'
import ReadingGroupCard from '@/components/social/ReadingGroupCard.vue'
import SuggestedPeople from '@/components/social/SuggestedPeople.vue'
import FriendRequest from '@/components/social/FriendRequest.vue'
import Friends from '@/components/social/Friends.vue'
import ReadingChallenge from '@/components/social/ReadingChallenge.vue'
import SearchField from '@/components/social/SearchField.vue'

export default {
  name: 'SocialPage',

  components: {
    Header,
    StatsCard,
    ReadingGroupCard,
    SuggestedPeople,
    FriendRequest,
    Friends,
    ReadingChallenge,
    SearchField,
  },

  data() {
    return {
      isRequestsVisible: false,
      searchQuery: '',
      notification: null,
      readingProgress: {
        current: 23,
        goal: 50,
      },
      readingGroups: [
        {
          id: 1,
          name: 'Classic Literature Club',
          members: 1250,
          icon: 'fa-book-reader',
          bgColor: '#FFE4E1',
          iconColor: '#FF69B4',
          joined: false,
          lastActivity: '2 hours ago',
        },
        {
          id: 2,
          name: 'Science Fiction Readers',
          members: 890,
          icon: 'fa-rocket',
          bgColor: '#E6E6FA',
          iconColor: '#6A5ACD',
          joined: true,
          lastActivity: '5 mins ago',
        },
      ],
    }
  },

  computed: {
    notificationIcon() {
      const icons = {
        success: 'fa-check-circle',
        error: 'fa-exclamation-circle',
        info: 'fa-info-circle',
      }
      return icons[this.notification?.type] || icons.info
    },
  },

  methods: {
    toggleFriendRequests() {
      this.isRequestsVisible = !this.isRequestsVisible
    },

    viewProfile(userId) {
      this.$router.push(`/profile/${userId}`)
    },
  },
}

</script>

<style>
:root {
  --primary-purple: #8a2be2;
  --primary-pink: #ff69b4;
  --primary-blue: #4169e1;
  --primary-light: rgba(138, 43, 226, 0.1);
  --bg-light: #f8f9fa;
  --white: #ffffff;
  --text-dark: #2d3748;
  --text-gray: #718096;
  --border-color: #e2e8f0;
  --success-green: #10b981;
  --hover-transition: all 0.3s ease;
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  background-color: var(--bg-light);
  color: var(--text-dark);
  line-height: 1.5;
}

.app {
  min-height: 100vh;
  background: var(--bg-light);
}

.main-content {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1.5rem;
}

.content-container {
  display: grid;
  grid-template-columns: 1fr 320px;
  gap: 2rem;
}

.main-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.right-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.section-container {
  background: var(--white);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-color);
}

.section-header {
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}

.section-header h2 {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-dark);
}

.view-all {
  color: var(--primary-purple);
  font-size: 0.875rem;
  cursor: pointer;
  transition: opacity 0.2s;
}

.view-all:hover {
  opacity: 0.8;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

/* Search Container */
.search-container {
  position: relative;
  margin-bottom: 1rem;
}

.search-container input {
  width: 100%;
  padding: 0.875rem 1rem 0.875rem 2.75rem;
  background: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  font-size: 0.875rem;
  transition: all 0.3s ease;
}

.search-container input:focus {
  outline: none;
  border-color: var(--primary-purple);
  box-shadow: 0 0 0 3px var(--primary-light);
}

.search-container i {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-gray);
  pointer-events: none;
}

/* Lists */
.requests-list,
.friends-list {
  max-height: 400px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--text-gray) var(--bg-light);
}

.requests-list::-webkit-scrollbar,
.friends-list::-webkit-scrollbar {
  width: 6px;
}

.requests-list::-webkit-scrollbar-track,
.friends-list::-webkit-scrollbar-track {
  background: var(--bg-light);
}

.requests-list::-webkit-scrollbar-thumb,
.friends-list::-webkit-scrollbar-thumb {
  background-color: var(--text-gray);
  border-radius: 3px;
}

/* Notification Styles */
.notification {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  padding: 1rem 1.5rem;
  background: var(--white);
  border-radius: 12px;
  box-shadow: var(--shadow-md);
  display: flex;
  align-items: center;
  gap: 0.75rem;
  z-index: 1000;
  min-width: 300px;
  max-width: 400px;
  animation: slideIn 0.3s ease-out;
}

.notification.success {
  border-left: 4px solid var(--success-green);
}

.notification.error {
  border-left: 4px solid #ef4444;
}

.notification.info {
  border-left: 4px solid var(--primary-blue);
}

.notification i {
  font-size: 1.25rem;
}

.notification.success i {
  color: var(--success-green);
}

.notification.error i {
  color: #ef4444;
}

.notification.info i {
  color: var(--primary-blue);
}

.notification-message {
  flex: 1;
  font-size: 0.875rem;
  color: var(--text-dark);
}

.notification-close {
  padding: 0.25rem;
  color: var(--text-gray);
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s ease;
}

.notification-close:hover {
  color: var(--text-dark);
}

/* Animations */
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Count Badge */
.count {
  background: var(--primary-light);
  color: var(--primary-purple);
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: 500;
}

/* Header Right */
.header-right {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Clickable Elements */
.clickable {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.clickable:hover {
  background-color: var(--bg-light);
}

/* Loading States */
.skeleton {
  background: linear-gradient(90deg,
      var(--bg-light) 25%,
      var(--border-color) 50%,
      var(--bg-light) 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  to {
    background-position: -200% 0;
  }
}

/* Responsive Design */
@media (max-width: 1200px) {
  .main-content {
    padding: 0 1rem;
  }

  .content-container {
    gap: 1.5rem;
  }

  .stats-grid {
    gap: 1rem;
  }
}

@media (max-width: 1024px) {
  .content-container {
    grid-template-columns: 1fr;
  }

  .right-section {
    order: -1;
  }

  .stats-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .notification {
    max-width: calc(100% - 2rem);
  }
}

@media (max-width: 768px) {
  .main-content {
    margin: 1rem auto;
  }

  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .section-header {
    padding: 1rem;
  }

  .notification {
    bottom: 1rem;
    right: 1rem;
    left: 1rem;
    min-width: auto;
  }
}

@media (max-width: 480px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }

  .section-header h2 {
    font-size: 1rem;
  }

  .view-all {
    font-size: 0.75rem;
  }

  .notification {
    padding: 0.75rem 1rem;
  }
}

/* Print Styles */
@media print {
  .app {
    background: var(--white);
  }

  .notification,
  .search-container,
  .view-all,
  .clickable {
    display: none;
  }

  .content-container {
    grid-template-columns: 1fr;
  }

  .section-container {
    break-inside: avoid;
    box-shadow: none;
    border: 1px solid var(--border-color);
  }
}

/* Utility Classes */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.no-scroll {
  overflow: hidden;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* High Contrast Mode */
@media (prefers-contrast: high) {
  :root {
    --primary-purple: #6b21a8;
    --primary-pink: #db2777;
    --text-gray: #4b5563;
    --border-color: #9ca3af;
  }

  .notification,
  .section-container {
    border-width: 2px;
  }
}

/* Reduced Motion */
@media (prefers-reduced-motion: reduce) {

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
</style>