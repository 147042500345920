import Login from '@/views/auth/Login.vue'
import Signup from '@/views/auth/Signup.vue'
import { guestGuard } from '../guards'

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
      title: 'Login',
      transition: 'fade',
    },
    beforeEnter: guestGuard,
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: {
      requiresGuest: true,
      title: 'Sign Up',
      transition: 'fade',
    },
    beforeEnter: guestGuard,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/components/auth/ForgotPassword.vue'),
    meta: {
      title: 'Forgot Password',
      requiresAuth: false
    }
  },
  {
    path: '/account/settings/password/reset',
    name: 'password-reset-confirm',
    component: () => import('@/components/account/forms/PasswordResetConfirm.vue'),
    meta: {
      title: 'Reset Password',
      requiresAuth: false 
    }
  },
]
