<template>
  <div class="profile-page">
    <!-- Loading State -->
    <div v-if="loading" class="loading-state">
      <i class="fas fa-spinner fa-spin"></i>
      Loading profile...
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="error-state">
      <p>{{ error }}</p>
      <button @click="fetchProfile" class="retry-btn">Retry</button>
    </div>

    <!-- Profile Content -->
    <template v-else-if="profile">
      <ProfileHeader :profile="profile" :isOwnProfile="isOwnProfile" @refresh="fetchProfile" />

      <ProfileTabs :isOwnProfile="isOwnProfile" :availableTabs="availableTabs" v-model="activeTab" />

      <!-- Tab Content -->
      <div class="tab-content">
        <transition name="fade" mode="out-in">
          <component :is="currentTabComponent" :profile="profile" :isOwnProfile="isOwnProfile"
            @refresh="fetchProfile" />
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import api from '@/api/axios'

import ProfileHeader from '@/components/profile/ProfileHeader.vue'
import ProfileTabs from '@/components/profile/ProfileTabs.vue'
import ProfileAbout from '@/components/profile/ProfileAbout.vue'
import ProfileBooks from '@/components/profile/ProfileBooks.vue'
import ProfileReviews from '@/components/profile/ProfileReviews.vue'

export default {
  name: 'Profile',
  components: {
    ProfileHeader,
    ProfileTabs,
    ProfileAbout,
    ProfileBooks,
    ProfileReviews,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const profile = ref(null)
    const loading = ref(true)
    const error = ref(null)
    const activeTab = ref('about')

    // Watch for route param changes
    watch(() => route.params.id, async (newId, oldId) => {
      if (newId && newId !== oldId) {
        await fetchProfile()
      }
    })

    // Computed properties
    const isOwnProfile = computed(() => {
      const userProfile = store.getters.getUserProfile
      return userProfile?.id === route.params.id
    })

    const availableTabs = computed(() => {
      const tabs = [{ id: 'about', label: 'About', icon: 'fas fa-user' }]

      if (
        profile.value?.currently_reading ||
        profile.value?.favorite_books?.length
      ) {
        tabs.push({ id: 'books', label: 'Books', icon: 'fas fa-book' })
      }

      tabs.push({ id: 'reviews', label: 'Reviews', icon: 'fas fa-star' })

      return tabs
    })

    const currentTabComponent = computed(() => {
      switch (activeTab.value) {
        case 'books':
          return 'ProfileBooks'
        case 'reviews':
          return 'ProfileReviews'
        default:
          return 'ProfileAbout'
      }
    })

    // Methods
    const fetchProfile = async () => {
      try {
        loading.value = true
        error.value = null
        profile.value = null // Reset profile before fetching new one

        const response = await api.get(`/users/${route.params.id}/`)

        if (response.data.success) {
          profile.value = response.data.data
          // If this is the user's own profile, update the store
          if (isOwnProfile.value) {
            store.commit('SET_USER_PROFILE', response.data.data)
          }
        } else {
          throw new Error('Failed to load profile')
        }
      } catch (err) {
        console.error('Error fetching profile:', err)
        error.value = 'Failed to load profile. Please try again.'
        if (err.response?.status === 404) {
          error.value = 'User not found'
        }
      } finally {
        loading.value = false
      }
    }

    // Initial setup
    onMounted(async () => {
      if (!store.getters.getUserProfile && store.getters.isAuthenticated) {
        await store.dispatch('fetchUserProfile')
      }
      await fetchProfile()
    })

    return {
      profile,
      loading,
      error,
      activeTab,
      isOwnProfile,
      availableTabs,
      currentTabComponent,
      fetchProfile,
    }
  },
}
</script>

<style scoped>
.profile-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.loading-state,
.error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  text-align: center;
}

.loading-state i {
  font-size: 2rem;
  color: #f27cf9;
  margin-bottom: 1rem;
}

.error-state {
  color: #dc3545;
}

.retry-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #f27cf9;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
}

.retry-btn:hover {
  background: #e065e6;
}

.tab-content {
  margin-top: 2rem;
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .profile-page {
    padding: 1rem;
  }
}
</style>