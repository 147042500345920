<template>
    <form @submit.prevent="handleSubmit" class="form-container">
        <!-- Progress Steps -->
        <div class="form-header">
            <h3>{{ currentStep === 1 ? 'Create your account' : 'Complete Your Profile' }}</h3>
            <div class="steps-indicator">
                <div v-for="step in 2" :key="step" class="step"
                    :class="{ 'active': currentStep >= step, 'completed': currentStep > step }">
                    {{ step }}
                </div>
            </div>
        </div>

        <!-- Form Content -->
        <div class="form-content">
            <!-- Step 1: Required Fields -->
            <div v-if="currentStep === 1" class="step-content">
                <div class="name-group">
                    <CustomInput :modelValue="firstName" @update:modelValue="$emit('update:firstName', $event)"
                        type="text" placeholder="First Name" :disabled="loading" required />
                    <CustomInput :modelValue="lastName" @update:modelValue="$emit('update:lastName', $event)"
                        type="text" placeholder="Last Name" :disabled="loading" required />
                </div>

                <CustomInput v-for="(field, index) in requiredFields" :key="index" :modelValue="props[field.prop]"
                    @update:modelValue="$emit(`update:${field.prop}`, $event)" :type="field.type"
                    :placeholder="field.placeholder" :disabled="loading" required />
            </div>

            <!-- Step 2: Optional Profile Fields -->
            <div v-if="currentStep === 2" class="step-content">
                <div class="profile-picture-upload">
                    <img :src="profilePreview || '/default-avatar.png'" alt="Profile Preview" class="preview-image" />
                    <input type="file" @change="handleImageUpload" accept="image/*" :disabled="loading"
                        id="profile-upload" class="hidden" />
                    <label for="profile-upload" class="upload-button" :class="{ disabled: loading }">
                        Upload Profile Picture
                    </label>
                </div>

                <textarea v-model="status" placeholder="Write your status..." class="status-input" :disabled="loading"
                    @input="$emit('update:status', $event.target.value)"></textarea>

                <!-- Terms and Rental Policy Checkboxes -->
                <div class="terms-checkboxes">
                    <div class="checkbox-group">
                        <input type="checkbox" id="terms" v-model="termsAccepted" :disabled="loading" />
                        <label for="terms">
                            I agree to the
                            <a href="/terms" target="_blank" class="policy-link">Terms and Services</a>
                        </label>
                    </div>

                    <div class="checkbox-group">
                        <input type="checkbox" id="rental" v-model="rentalPolicyAccepted" :disabled="loading" />
                        <label for="rental">
                            I agree to the
                            <a href="/rental-policy" target="_blank" class="policy-link">Rental Policy</a>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- Form Actions -->
        <div class="form-actions">
            <div class="button-group">
                <button v-if="currentStep > 1" type="button" class="button secondary" @click="previousStep"
                    :disabled="loading">
                    Back
                </button>

                <button v-if="currentStep < 2" type="button" class="button primary" @click="nextStep"
                    :disabled="loading || !isCurrentStepValid">
                    Next
                </button>

                <button v-if="currentStep === 2" type="submit" class="button primary"
                    :disabled="loading || !isCurrentStepValid">
                    <span v-if="loading" class="loading-spinner"></span>
                    {{ loading ? 'Creating Account...' : 'Create Account' }}
                </button>
            </div>

            <p class="login-text">
                Already have an account?
                <button type="button" @click="$emit('switch-to-login')" class="link-text" :disabled="loading">
                    Login
                </button>
            </p>
        </div>
    </form>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import CustomInput from '../shared/CustomInput.vue'

const props = defineProps({
    firstName: String,
    lastName: String,
    username: String,
    email: String,
    dateOfBirth: String,
    password: String,
    confirmPassword: String,
    status: String,
    loading: {
        type: Boolean,
        default: false
    },
    hasAcceptedTerms: Boolean,
    hasAcceptedRentalPolicy: Boolean
})

const requiredFields = [
    { prop: 'username', type: 'text', placeholder: 'Username' },
    { prop: 'email', type: 'email', placeholder: 'Email' },
    { prop: 'dateOfBirth', type: 'date', placeholder: 'Date of Birth' },
    { prop: 'password', type: 'password', placeholder: 'Password' },
    { prop: 'confirmPassword', type: 'password', placeholder: 'Confirm Password' }
]

const emit = defineEmits([
    'submit',
    'update:firstName',
    'update:lastName',
    'update:username',
    'update:email',
    'update:dateOfBirth',
    'update:password',
    'update:confirmPassword',
    'update:profilePicture',
    'update:status',
    'update:hasAcceptedTerms',
    'update:hasAcceptedRentalPolicy',
    'switch-to-login',
    'error'
])

const currentStep = ref(1)
const profilePreview = ref(null)
const status = ref('')
const termsAccepted = ref(false)
const rentalPolicyAccepted = ref(false)

// Watch for terms acceptance changes
watch(termsAccepted, (newValue) => {
    emit('update:hasAcceptedTerms', newValue)
})

watch(rentalPolicyAccepted, (newValue) => {
    emit('update:hasAcceptedRentalPolicy', newValue)
})

const isCurrentStepValid = computed(() => {
    if (currentStep.value === 1) {
        return props.firstName?.trim() &&
            props.lastName?.trim() &&
            props.username?.trim() &&
            props.email?.trim() &&
            props.dateOfBirth &&
            props.password?.trim() &&
            props.confirmPassword?.trim() &&
            props.password === props.confirmPassword
    }
    // Add validation for step 2
    return termsAccepted.value && rentalPolicyAccepted.value
})

const handleSubmit = () => {
    emit('submit', {
        firstName: props.firstName,
        lastName: props.lastName,
        username: props.username,
        email: props.email,
        dateOfBirth: props.dateOfBirth,
        password: props.password,
        status: status.value,
        profilePicture: profilePreview.value,
        hasAcceptedTerms: termsAccepted.value,
        hasAcceptedRentalPolicy: rentalPolicyAccepted.value
    })
}

const handleImageUpload = async (event) => {
    const file = event.target.files[0]
    if (file) {
        if (file.size > 5 * 1024 * 1024) {
            emit('error', 'Image size should be less than 5MB')
            return
        }

        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']
        if (!allowedTypes.includes(file.type)) {
            emit('error', 'Please upload JPG or PNG images only')
            return
        }

        try {
            const reader = new FileReader()
            reader.onload = (e) => {
                profilePreview.value = e.target.result
                emit('update:profilePicture', e.target.result)
            }
            reader.readAsDataURL(file)
        } catch (err) {
            console.error('Error reading file:', err)
            emit('error', 'Error processing image')
        }
    }
}

const nextStep = () => currentStep.value < 2 && currentStep.value++
const previousStep = () => currentStep.value > 1 && currentStep.value--
</script>

<style scoped>
.form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-header {
    text-align: center;
    margin-bottom: 0.5rem;
}

.form-header h3 {
    color: #282772;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.steps-indicator {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.step {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #e2e8f0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #64748b;
    font-weight: 600;
    transition: all 0.3s ease;
}

.step.active {
    background: #f270f9;
    color: white;
}

.step.completed {
    background: #d644df;
    color: white;
}

.form-content {
    flex: 1;
}

.step-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.name-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.profile-picture-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}

.preview-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #f270f9;
    background: #f8f8f8;
}

.upload-button {
    background: #f270f9;
    color: white;
    padding: 0.5rem 1.25rem;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
    border: none;
}

.upload-button:hover:not(.disabled) {
    background: #d644df;
    transform: translateY(-1px);
}

.status-input {
    width: 100%;
    min-height: 100px;
    padding: 0.75rem 1rem;
    border: 2px solid #e2e8f0;
    border-radius: 12px;
    resize: vertical;
    font-family: inherit;
    font-size: 0.95rem;
    transition: all 0.2s ease;
}

.status-input:focus {
    border-color: #f270f9;
    outline: none;
    box-shadow: 0 0 0 3px rgba(242, 112, 249, 0.1);
}

.form-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.button-group {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.button {
    padding: 0.75rem 2rem;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.2s ease;
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.button.primary {
    background: #f270f9;
    color: white;
}

.button.secondary {
    background: #e2e8f0;
    color: #64748b;
}

.button:hover:not(:disabled) {
    transform: translateY(-1px);
}

.button.primary:hover:not(:disabled) {
    background: #d644df;
}

.button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.login-text {
    text-align: center;
    font-size: 0.95rem;
    color: #64748b;
}

.link-text {
    background: none;
    border: none;
    color: #f270f9;
    cursor: pointer;
    font-weight: 600;
    padding: 0 4px;
    transition: all 0.2s ease;
}

.link-text:hover:not(:disabled) {
    color: #d644df;
}

.hidden {
    display: none;
}

.loading-spinner {
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Responsive Design */
@media (max-width: 640px) {
    .name-group {
        grid-template-columns: 1fr;
    }

    .button {
        padding: 0.75rem 1.5rem;
        min-width: 110px;
    }

    .form-header h3 {
        font-size: 1.3rem;
    }
}

@media (max-width: 480px) {
    .button-group {
        flex-direction: column;
        width: 100%;
    }

    .button {
        width: 100%;
    }

    .preview-image {
        width: 100px;
        height: 100px;
    }
}

/* Touch Device Optimizations */
@media (hover: none) {
    .button {
        padding: 0.875rem 2rem;
    }

    .upload-button {
        padding: 0.75rem 1.5rem;
    }
}

.terms-checkboxes {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 0.5rem;
}

.checkbox-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.checkbox-group input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.checkbox-group label {
    color: #64748b;
    font-size: 0.95rem;
}

.policy-link {
    color: #f270f9;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.2s ease;
}

.policy-link:hover {
    color: #d644df;
}

@media (max-width: 480px) {
    .checkbox-group label {
        font-size: 0.9rem;
    }
}
</style>