<template>
  <aside class="filters-sidebar">
    <div class="filter-section">
      <div v-if="loading" class="loading-state">
        <div class="loader"></div>
      </div>

      <template v-else>
        <h3>Genres</h3>
        <div class="filter-checkboxes">
          <label
            v-for="genre in genres"
            :key="genre.id"
            class="filter-checkbox"
          >
            <input
              type="checkbox"
              :value="genre.name"
              v-model="selectedFilters.selectedGenres"
              @change="handleFilterChange"
            />
            <span class="checkbox-label">{{ genre.name }}</span>
          </label>
        </div>

        <h3>Language</h3>
        <div class="filter-options">
          <label class="filter-radio">
            <input
              type="radio"
              v-model="selectedFilters.selectedLanguage"
              :value="null"
              name="language"
              @change="handleFilterChange"
            />
            <span class="radio-label">Any Language</span>
          </label>
          <label
            v-for="language in languages"
            :key="language"
            class="filter-radio"
          >
            <input
              type="radio"
              :value="language"
              v-model="selectedFilters.selectedLanguage"
              @change="handleFilterChange"
              name="language"
            />
            <span class="radio-label">{{ language }}</span>
          </label>
        </div>

        <div class="price-filter">
          <h3>Price Range</h3>
          <div class="price-inputs">
            <input
              type="number"
              v-model="selectedFilters.min_price"
              placeholder="Min"
              @change="handleFilterChange"
            />
            <input
              type="number"
              v-model="selectedFilters.max_price"
              placeholder="Max"
              @change="handleFilterChange"
            />
          </div>
        </div>

        <button @click="handleClearFilters" class="clear-filters-btn">
          Clear Filters
        </button>
      </template>
    </div>
  </aside>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import api from '@/api/axios'

export default {
  name: 'FiltersSidebar',
  emits: ['filter-change'],

  setup(props, { emit }) {
    const loading = ref(false)
    const genres = ref([])
    const languages = ref([
      'English',
      'Japanese',
      'Nepali',
      'French',
    ])

    const selectedFilters = reactive({
      selectedGenres: [],
      selectedLanguage: null,
      min_price: '',
      max_price: '',
    })

    
    const handleFilterChange = () => {
      emit('filter-change', {
        genres: selectedFilters.selectedGenres,
        language: selectedFilters.selectedLanguage,
        min_price: selectedFilters.min_price,
        max_price: selectedFilters.max_price,
      })
    }

    const handleClearFilters = () => {
      selectedFilters.selectedGenres = []
      selectedFilters.selectedLanguage = null
      selectedFilters.min_price = ''
      selectedFilters.max_price = ''

      handleFilterChange()
    }


    const fetchGenres = async () => {
      loading.value = true
      try {
        const response = await api.get('/books/genres/')
        if (response.data.status === 'success') {
          genres.value = response.data.results
        }
      } catch (error) {
        console.error('Error fetching genres:', error)
      } finally {
        loading.value = false
      }
    }

    onMounted(() => {
      fetchGenres()
    })

    return {
      loading,
      genres,
      languages,
      selectedFilters,
      handleFilterChange,
      handleClearFilters,
    }
  },
}
</script>

<style scoped>
.filters-sidebar {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  height: fit-content;
  position: sticky;
  top: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.loading-state {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #f27cf9;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

.filter-section h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #1a1a1a;
  margin: 1.5rem 0 1rem;
}

.filter-section h3:first-child {
  margin-top: 0;
}

.filter-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 0.5rem;
  margin-bottom: 1.5rem;
}

.filter-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.25rem 0;
}

.filter-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0;
}

.filter-radio input[type='radio'] {
  margin-right: 0.75rem;
  cursor: pointer;
}

.radio-label {
  flex: 1;
  font-size: 0.9rem;
  color: #666;
}

.filter-options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.filter-checkbox input[type='checkbox'] {
  margin-right: 0.75rem;
  cursor: pointer;
}

.checkbox-label {
  flex: 1;
  font-size: 0.9rem;
  color: #666;
}

.price-filter {
  margin: 1.5rem 0;
}

.price-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.price-inputs input {
  width: 85%;
  padding: 0.75rem;
  border: 1px solid #eee;
  border-radius: 8px;
  font-size: 0.9rem;
}

.clear-filters-btn {
  width: 100%;
  padding: 0.75rem;
  background: #f8f8f8;
  border: 1px solid #eee;
  border-radius: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.clear-filters-btn:hover {
  background: #f27cf9;
  color: white;
  border-color: #f27cf9;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .filters-sidebar {
    border-radius: 0;
    height: 100%;
  }
}
</style>
