// components/BillingHeader.vue
<template>
  <div class="header">
    <h1>Billing Information</h1>
    <div class="breadcrumb">
      <router-link to="/home">Home</router-link>
      <span>/</span>
      <span class="current">Billing Info</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BillingHeader',
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.breadcrumb {
  color: #666;
}

.breadcrumb a {
  color: #666;
  text-decoration: none;
}

.current {
  color: #7540ff;
}
</style>
