<template>
  <section class="new-section" ref="bookSection">
    <h2 class="section-title">BOOKSVILLE NEWEST BOOK</h2>
    <div :class="{ 'books-grid new-grid': !isMobile, 'books-list': isMobile }" ref="booksContainer">
      <component :is="isMobile ? 'BookCardWithCartListView' : 'BookCardWithCart'" 
        v-for="book in paginatedBooks"
        :key="book.id" 
        :book="book" 
        @add-to-cart="$emit('add-to-cart', book)" />
    </div>

    <div class="pagination">
      <button class="pagination-button" 
        :disabled="currentPage === 1" 
        @click="changePage(currentPage - 1)">
        Previous
      </button>

      <button class="pagination-button" 
        :disabled="currentPage === totalPages" 
        @click="changePage(currentPage + 1)">
        Next
      </button>
    </div>

    <div class="items-info" v-if="books.length">
      Showing {{ displayStartIndex }}-{{ displayEndIndex }} of {{ books.length }} books
    </div>
  </section>
</template>

<script>
import BookCardWithCart from '../shared/BookCardWithCart.vue'
import BookCardWithCartListView from '../shared/BookCardWithCartListView.vue'

export default {
  name: 'NewBookSection',
  components: {
    BookCardWithCart,
    BookCardWithCartListView,
  },
  props: {
    books: {
      type: Array,
      required: true,
    },
  },
  emits: ['add-to-cart'],

  data() {
    return {
      isMobile: false,
      windowWidth: window.innerWidth,
      currentPage: 1,
      itemsPerPage: 4,
      isInitialized: false
    }
  },

  computed: {
    totalPages() {
      return Math.ceil(this.books.length / this.itemsPerPage)
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage
    },
    endIndex() {
      return Math.min(this.startIndex + this.itemsPerPage, this.books.length)
    },
    displayStartIndex() {
      return this.books.length ? this.startIndex + 1 : 0
    },
    displayEndIndex() {
      return this.books.length ? this.endIndex : 0
    },
    paginatedBooks() {
      if (!this.books.length) return []
      return this.books.slice(this.startIndex, this.endIndex)
    }
  },

  created() {
    this.checkScreenSize()
    window.addEventListener('resize', this.debounce(this.checkScreenSize, 250))
  },

  async mounted() {
    // Wait for next tick to ensure DOM is ready
    await this.$nextTick()
    // Initialize with a slight delay to ensure container width is available
    setTimeout(() => {
      this.calculateItemsPerPage()
      this.isInitialized = true
    }, 0)
  },

  unmounted() {
    window.removeEventListener('resize', this.debounce(this.checkScreenSize, 250))
  },

  methods: {
    debounce(func, wait) {
      let timeout
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout)
          func(...args)
        }
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
      }
    },

    checkScreenSize() {
      this.windowWidth = window.innerWidth
      this.isMobile = this.windowWidth <= 768
      
      if (!this.isMobile) {
        this.calculateItemsPerPage()
      } else {
        this.itemsPerPage = 4
      }
    },

    calculateItemsPerPage() {
      if (this.$refs.booksContainer) {
        const containerWidth = this.$refs.booksContainer.offsetWidth
        const minBookWidth = 240 // from CSS grid-template-columns
        const gap = 30 // from CSS gap
        
        // Calculate how many items can fit in a row
        const itemsPerRow = Math.floor((containerWidth + gap) / (minBookWidth + gap))
        // Ensure at least 1 item per row and update only if different
        const newItemsPerPage = Math.max(1, itemsPerRow)
        
        if (this.itemsPerPage !== newItemsPerPage) {
          this.itemsPerPage = newItemsPerPage
          // Reset to page 1 if the current page would be invalid with new items per page
          if (this.currentPage > Math.ceil(this.books.length / this.itemsPerPage)) {
            this.currentPage = 1
          }
        }
      }
    },

    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page
        this.$el.scrollIntoView({ behavior: 'smooth' })
      }
    }
  },

  watch: {
    windowWidth() {
      if (!this.isMobile) {
        this.calculateItemsPerPage()
      }
    },
    books: {
      immediate: true,
      handler(newBooks) {
        if (newBooks.length && this.isInitialized) {
          this.calculateItemsPerPage()
        }
      }
    }
  }
}
</script>

<style scoped>
.new-section {
  margin-bottom: 4rem;
  padding: 3rem;
}

.section-title {
  font-size: 24px;
  color: #282772;
  margin-bottom: 30px;
  padding-bottom: 2rem;
  border-bottom: 2px solid #282772;
}

.books-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 30px;
  min-height: 300px;
}

.books-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.pagination-button {
  padding: 0.75rem 1.5rem;
  border: 1px solid #282772;
  background: white;
  color: #282772;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
  min-width: 100px;
  font-weight: 500;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-button:not(:disabled):hover {
  background: #282772;
  color: white;
}

.items-info {
  text-align: center;
  margin-top: 1rem;
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .new-section {
    padding: 2rem 1rem;
  }

  .section-title {
    font-size: 20px;
    margin-bottom: 20px;
    padding-bottom: 1.5rem;
  }

  .pagination {
    gap: 0.5rem;
  }

  .pagination-button {
    padding: 0.5rem 1rem;
    min-width: 80px;
  }
}
</style>