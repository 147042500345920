<template>
    <div class="about-container">
        <!-- Hero Section -->
        <section class="about-hero">
            <div class="hero-content">
                <h1>About B<span class="accent">oo</span>ksVille</h1>
                <p class="hero-subtitle">Building Communities Through Books</p>
            </div>
        </section>

        <!-- Our Story Section -->
        <section class="story-section">
            <div class="content-wrapper">
                <div class="text-content">
                    <h2>Our Story</h2>
                    <p>
                        Founded in 2024, BooksVille began with a simple vision: to create a
                        space where book lovers could find their next great read and connect
                        with fellow readers. What started as a small online bookstore has grown
                        into a vibrant community of readers, writers, and book enthusiasts.
                    </p>
                </div>
                <div class="image-content">
                    <img :src="storyImage" alt="BooksVille Story" class="story-image" />
                </div>
            </div>
        </section>

        <!-- Mission Cards -->
        <section class="mission-section">
            <h2>What We Stand For</h2>
            <div class="mission-cards">
                <div class="mission-card">
                    <i class="fa-solid fa-book-open"></i>
                    <h3>Quality Books</h3>
                    <p>Carefully curated collection of books in pristine condition</p>
                </div>
                <div class="mission-card">
                    <i class="fa-solid fa-users"></i>
                    <h3>Community First</h3>
                    <p>Building connections through shared love of reading</p>
                </div>
                <div class="mission-card">
                    <i class="fa-solid fa-truck-fast"></i>
                    <h3>Reliable Service</h3>
                    <p>Swift delivery and professional customer support</p>
                </div>
            </div>
        </section>

        <!-- Stats Section -->
        <section class="stats-section">
            <div class="stats-container">
                <div class="stat-item">
                    <div class="stat-number-container">
                        <span class="stat-number">99</span>
                        <span class="stat-symbol">%</span>
                    </div>
                    <span class="stat-label">Customer Satisfaction Rate</span>
                </div>
                <div class="stat-item">
                    <div class="stat-number-container">
                        <span class="stat-number">1000</span>
                        <span class="stat-symbol">+</span>
                    </div>
                    <span class="stat-label">Book Varieties Available</span>
                </div>
                <div class="stat-item">
                    <div class="stat-number-container">
                        <span class="stat-number">72</span>
                        <span class="stat-symbol">hr</span>
                    </div>
                    <span class="stat-label">Average Delivery Time</span>
                </div>
            </div>
        </section>

        <!-- Team Section -->
        <section class="team-section">
            <h2>Meet Our Team</h2>
            <div class="team-grid">
                <div v-for="(member, index) in teamMembers" :key="index" class="team-member">
                    <img :src="member.image" :alt="member.name" />
                    <h3>{{ member.name }}</h3>
                    <p>{{ member.role }}</p>
                </div>
            </div>
        </section>

        <!-- Contact Section -->
        <section class="contact-section">
            <div class="contact-content">
                <h2>Get in Touch</h2>
                <p>Have questions? We'd love to hear from you.</p>
                <div class="contact-info">
                    <div v-for="(info, index) in contactInfo" :key="index" class="contact-item">
                        <i :class="info.icon"></i>
                        <span>{{ info.text }}</span>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
    name: 'AboutUs',
    setup() {
        // Images and content as reactive references
        const storyImage = ref('https://images.unsplash.com/photo-1481627834876-b7833e8f5570?q=80&w=228&auto=format&fit=crop')

        const teamMembers = ref([
            {
                name: 'Ash Ketchum',
                role: 'Founder & CEO',
                image: 'https://booksville.0xss.us/media/book_covers/twocankeepasecret_i2Z8clt_u4xdUTo.jpg'
            },
            {
                name: 'Sukuna Jose',
                role: 'Co Founder',
                image: 'https://booksville.0xss.us/media/book_covers/twocankeepasecret_i2Z8clt_u4xdUTo.jpg'
            },
            {
                name: 'Aarati',
                role: 'Community Manager',
                image: 'https://booksville.0xss.us/media/book_covers/faultinourstars_8XqFIXB_7W4eVnA.jpg'
            }
        ])

        const contactInfo = ref([
            {
                icon: 'fa-solid fa-envelope',
                text: 'support@booksville.com'
            },
            {
                icon: 'fa-solid fa-phone',
                text: '+977 (555) 123-4567'
            },
            {
                icon: 'fa-solid fa-location-dot',
                text: '123 Book Street, Reading City, RC 12345'
            }
        ])

        return {
            storyImage,
            teamMembers,
            contactInfo
        }
    }
}
</script>

<style scoped>
.about-container {
    width: 100%;
    overflow-x: hidden;
}

.about-hero {
    height: 60vh;
    background-image: linear-gradient(rgba(40, 39, 114, 0.8), rgba(40, 39, 114, 0.8)), url('@/assets/img/bg1.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

.hero-content h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
}

.accent {
    color: #f27cf9;
}

.hero-subtitle {
    font-size: 1.5rem;
    opacity: 0.9;
}

.content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 2rem;
    display: flex;
    align-items: center;
    gap: 4rem;
}

.text-content {
    flex: 1;
}

.text-content h2 {
    color: #282772;
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.image-content {
    flex: 1;
}

.story-image {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.mission-section {
    background-color: #f8f9fa;
    padding: 4rem 2rem;
    text-align: center;
}

.mission-section h2 {
    color: #282772;
    font-size: 2rem;
    margin-bottom: 3rem;
}

.mission-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.mission-card {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.mission-card:hover {
    transform: translateY(-10px);
}

.mission-card i {
    font-size: 2.5rem;
    color: #f27cf9;
    margin-bottom: 1rem;
}

.mission-card h3 {
    color: #282772;
    margin-bottom: 1rem;
}

.stats-section {
    padding: 4rem 2rem;
    background: linear-gradient(135deg, #282772, #f27cf9);
    color: white;
}

.stats-container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    text-align: center;
}

.stat-number-container {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0.2rem;
}

.stat-number {
    font-size: 3rem;
    font-weight: bold;
}

.stat-symbol {
    font-size: 2rem;
    font-weight: bold;
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stat-number {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.stat-symbol {
    font-size: 2rem;
    font-weight: bold;
    margin-left: 0.2rem;
}

.stat-label {
    font-size: 1.2rem;
    opacity: 0.9;
}

.team-section {
    padding: 4rem 2rem;
    text-align: center;
}

.team-section h2 {
    color: #282772;
    font-size: 2rem;
    margin-bottom: 3rem;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.team-member {
    text-align: center;
}

.team-member img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-bottom: 1rem;
    object-fit: cover;
    border: 3px solid #f27cf9;
}

.team-member h3 {
    color: #282772;
    margin-bottom: 0.5rem;
}

.team-member p {
    color: #666;
}

.contact-section {
    background-color: #f8f9fa;
    padding: 4rem 2rem;
}

.contact-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.contact-content h2 {
    color: #282772;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.contact-info {
    margin-top: 2rem;
}

.contact-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.contact-item i {
    color: #f27cf9;
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .hero-content h1 {
        font-size: 3rem;
    }

    .content-wrapper {
        flex-direction: column;
        padding: 2rem;
    }

    .mission-cards {
        grid-template-columns: 1fr;
    }

    .team-grid {
        grid-template-columns: 1fr;
    }

    .stats-container {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 480px) {
    .hero-content h1 {
        font-size: 2.5rem;
    }

    .hero-subtitle {
        font-size: 1.2rem;
    }

    .content-wrapper {
        padding: 2rem 1rem;
    }
}
</style>