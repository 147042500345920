<!-- components/profile/ProfileTabs.vue -->
<template>
    <div class="profile-tabs">
        <nav class="tabs-nav" ref="tabsNav">
            <button v-for="tab in availableTabs" :key="tab.id" class="tab-button"
                :class="{ active: modelValue === tab.id }" @click="$emit('update:modelValue', tab.id)">
                <i :class="tab.icon"></i>
                <span>{{ tab.label }}</span>
                <div class="tab-indicator" :class="{ active: modelValue === tab.id }"></div>
            </button>

            <!-- Optional: Scroll buttons for overflow -->
            <button v-if="showScrollButtons" class="scroll-button left" :class="{ hidden: scrollPosition <= 0 }"
                @click="scroll('left')">
                <i class="fas fa-chevron-left"></i>
            </button>
            <button v-if="showScrollButtons" class="scroll-button right"
                :class="{ hidden: scrollPosition >= maxScroll }" @click="scroll('right')">
                <i class="fas fa-chevron-right"></i>
            </button>
        </nav>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
    name: 'ProfileTabs',
    props: {
        modelValue: {
            type: String,
            required: true
        },
        availableTabs: {
            type: Array,
            required: true,
            validator: (tabs) => {
                return tabs.every(tab =>
                    tab.id &&
                    tab.label &&
                    tab.icon
                )
            }
        },
        isOwnProfile: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],

    setup() {
        const tabsNav = ref(null)
        const showScrollButtons = ref(false)
        const scrollPosition = ref(0)
        const maxScroll = ref(0)

        // Handle scroll visibility
        const checkScroll = () => {
            if (!tabsNav.value) return
            const { scrollWidth, clientWidth } = tabsNav.value
            showScrollButtons.value = scrollWidth > clientWidth
            maxScroll.value = scrollWidth - clientWidth
        }

        // Scroll tabs
        const scroll = (direction) => {
            if (!tabsNav.value) return
            const scrollAmount = 200 // Adjust as needed
            const newPosition = direction === 'left'
                ? Math.max(0, scrollPosition.value - scrollAmount)
                : Math.min(maxScroll.value, scrollPosition.value + scrollAmount)

            tabsNav.value.scrollTo({
                left: newPosition,
                behavior: 'smooth'
            })
            scrollPosition.value = newPosition
        }

        // Track scroll position
        const handleScroll = () => {
            if (!tabsNav.value) return
            scrollPosition.value = tabsNav.value.scrollLeft
        }

        onMounted(() => {
            checkScroll()
            window.addEventListener('resize', checkScroll)
            tabsNav.value?.addEventListener('scroll', handleScroll)
        })

        onUnmounted(() => {
            window.removeEventListener('resize', checkScroll)
            tabsNav.value?.removeEventListener('scroll', handleScroll)
        })

        return {
            tabsNav,
            showScrollButtons,
            scrollPosition,
            maxScroll,
            scroll,
        }
    }
}
</script>

<style scoped>
.profile-tabs {
    position: relative;
    margin: 2rem 0;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.tabs-nav {
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
    padding: 0 1rem;
}

.tabs-nav::-webkit-scrollbar {
    display: none;
}

.tab-button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 1.5rem;
    background: transparent;
    border: none;
    color: #666;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
    border-bottom: 2px solid transparent;
}

.tab-button i {
    font-size: 1.1rem;
}

.tab-button:hover {
    color: #f27cf9;
}

.tab-button.active {
    color: #f27cf9;
}

.tab-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #f27cf9;
    transform: scaleX(0);
    transition: transform 0.3s ease;
    transform-origin: left;
}

.tab-indicator.active {
    transform: scaleX(1);
}

.scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: white;
    border: 1px solid #eee;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    z-index: 1;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.scroll-button:hover {
    background: #f8f9fa;
    color: #f27cf9;
}

.scroll-button.left {
    left: 0;
}

.scroll-button.right {
    right: 0;
}

.scroll-button.hidden {
    opacity: 0;
    pointer-events: none;
}

@media (max-width: 768px) {
    .profile-tabs {
        margin: 1.5rem 0;
    }

    .tab-button {
        padding: 0.75rem 1rem;
        font-size: 0.9rem;
    }

    .scroll-button {
        width: 28px;
        height: 28px;
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .profile-tabs {
        margin: 1rem 0;
    }

    .tabs-nav {
        padding: 0 0.5rem;
    }

    .tab-button {
        padding: 0.75rem;
    }

    .tab-button span {
        display: none;
        /* Only show icons on mobile */
    }

    .tab-button i {
        font-size: 1.2rem;
    }
}
</style>