<template>
  <div class="product-content">
    <div class="product-details">
      <div class="detail-row">
        <span class="detail-label">Book format:</span>
        <span class="detail-value"
          >{{ book.format }} | {{ book.pages }} pages</span
        >
      </div>
      <div class="detail-row">
        <span class="detail-label">Dimensions:</span>
        <span class="detail-value">{{ book.dimensions }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-label">Publication date:</span>
        <span class="detail-value">{{ formatDate(book.date_published) }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-label">Publication house:</span>
        <span class="detail-value">{{ book.publication_house }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-label">Imprint:</span>
        <span class="detail-value">{{ book.imprint }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-label">Publication City/Country:</span>
        <span class="detail-value">{{ book.publication_city }}</span>
      </div>
      <div class="detail-row">
        <span class="detail-label">Language:</span>
        <span class="detail-value">{{ book.language }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductTab',
  props: {
    book: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const formatDate = date => {
      if (!date) return 'N/A'
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    }

    return {
      formatDate,
    }
  },
}
</script>

<style scoped>
.product-content {
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 1rem;
}

.product-details {
  width: 100%;
}

.detail-row {
  display: flex;
  padding: 1rem 0;
  border-bottom: 1px solid #eee;
}

.detail-label {
  width: 200px;
  color: #2b2668;
  font-weight: 500;
  margin-right: 1rem;
}

.detail-value {
  flex: 1;
  color: #666;
}

@media (max-width: 768px) {
  .detail-row {
    padding: 0.75rem 0;
  }

  .detail-label {
    width: 150px;
  }
}

@media (max-width: 480px) {
  .product-content {
    padding: 0.5rem;
  }

  .detail-row {
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.75rem 0;
  }

  .detail-label {
    width: 100%;
    margin-bottom: 0.25rem;
  }
}
</style>
