<template>
  <header class="header">
    <div class="header-content">
      <div class="header-left">
        <div class="logo">
          <i class="fas fa-users"></i>
        </div>
        <div class="title-group">
          <h1>Social Space</h1>
          <p>Connect with fellow book lovers</p>
        </div>
      </div>
      <UserStats />
    </div>
  </header>
</template>

<script>
import UserStats from './UserStats.vue' // Add this import

export default {
  name: 'Header',
  components: {
    UserStats, // Register component
  }
}
</script>

<style scoped>
.header {
  background-color: var(--white);
  box-shadow: var(--shadow-sm);
  top: 0;
  z-index: 100;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logo {
  width: 48px;
  height: 48px;
  background: linear-gradient(
    135deg,
    var(--primary-light) 0%,
    rgba(255, 105, 180, 0.1) 100%
  );
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo i {
  font-size: 24px;
  background: linear-gradient(
    135deg,
    var(--primary-purple) 0%,
    var(--primary-pink) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-group h1 {
  font-size: 1.75rem;
  font-weight: 700;
  background: linear-gradient(
    135deg,
    var(--primary-purple) 0%,
    var(--primary-pink) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-group p {
  font-size: 0.875rem;
  color: var(--text-gray);
}
</style>
