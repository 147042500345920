<template>
  <div class="section-header">
    <h2>Friends</h2>
    <span class="count">{{ friends.length }}</span>
  </div>
  <div class="friends-container">
    <div v-if="loading" class="loading-state">
      <div class="spinner"></div>
    </div>

    <div v-else-if="!friends.length" class="empty-state">
      <div class="empty-content">
        <h3>No friends yet</h3>
        <p>Start connecting with other users!</p>
      </div>
    </div>

    <div v-else class="friends-list">
      <div v-for="friend in localFriends" :key="friend.id" class="friend-card"
        :class="{ 'has-open-card': selectedFriend?.id === friend.id }">
        <div class="friend-info" @click="toggleProfileCard(friend, $event)">
          <div class="avatar">
            <img :src="friend.user.profile_picture_url" :alt="friend.user.username" />
          </div>
          <div class="details">
            <h3>
              {{ friend.user.username }}
              <i v-if="friend.user.is_verified" class="fas fa-check-circle verified"></i>
            </h3>
            <p>{{ friend.user.status || 'No status' }}</p>
          </div>
        </div>

        <button class="unfriend-btn" @click.stop="handleUnfriend(friend.id, friend.user.id)" title="Unfriend">
          <i class="fas fa-user-minus"></i>
        </button>

        <UserProfileCard v-if="selectedFriend?.id === friend.id" :user="friend.user" :position="profileCardPosition"
          @close="closeProfileCard" @view-profile="goToProfile">
          <template #actions>
            <button class="unfriend-btn-large" @click="handleUnfriend(friend.id, friend.user.id)">
              Unfriend
            </button>
          </template>
        </UserProfileCard>
      </div>
    </div>

    <div v-if="selectedFriend" class="overlay" @click="closeProfileCard"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UserProfileCard from '@/components/social/card/UserProfileCard.vue'

export default {
  name: 'Friends',

  components: {
    UserProfileCard
  },

  data() {
    return {
      loading: true,
      selectedFriend: null,
      profileCardPosition: {
        top: '0px',
        left: '0px'
      },
      localFriends: [] // Add local friends array
    }
  },

  computed: {
    ...mapState('social', ['friends'])
  },

  watch: {
    // Watch for changes in Vuex friends state
    friends: {
      immediate: true,
      handler(newFriends) {
        this.localFriends = [...newFriends]
      }
    }
  },

  methods: {
    ...mapActions('social', ['fetchFriends', 'unfriend']),

    goToProfile(userId) {
      this.$router.push(`/profile/${userId}`)
    },

    toggleProfileCard(friend, event) {
      if (this.selectedFriend?.id === friend.id) {
        this.closeProfileCard()
        return
      }

      const viewportWidth = window.innerWidth
      const viewportHeight = window.innerHeight
      const cardWidth = 320
      const cardHeight = 400

      const left = (viewportWidth - cardWidth) / 2
      const top = (viewportHeight - cardHeight) / 2

      this.profileCardPosition = {
        left: `${Math.max(20, Math.min(left, viewportWidth - cardWidth - 20))}px`,
        top: `${Math.max(20, Math.min(top, viewportHeight - cardHeight - 20))}px`
      }

      this.selectedFriend = friend
    },

    closeProfileCard() {
      this.selectedFriend = null
    },

    async handleUnfriend(friendshipId, userId) {
      try {
        await this.unfriend({ friendshipId, userId })

        // Update local state immediately
        this.localFriends = this.localFriends.filter(friend => friend.id !== friendshipId)

        this.$toast.success('Friend removed successfully')
        this.closeProfileCard()
      } catch (error) {
        this.$toast.error('Failed to remove friend')
      }
    }
  },

  async created() {
    try {
      await this.fetchFriends()
    } catch (error) {
      this.$toast.error('Failed to load friends')
    } finally {
      this.loading = false
    }
  },

  beforeDestroy() {
    this.closeProfileCard()
  }
}
</script>

<style scoped>
.section-header {
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}

.count {
  background: var(--primary-light);
  color: var(--primary-purple);
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 500;
  min-width: 24px;
  text-align: center;
}

.friends-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;
}

.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.spinner {
  width: 32px;
  height: 32px;
  border: 4px solid #eef2ff;
  border-top-color: #6366f1;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.empty-state {
  text-align: center;
  padding: 48px 16px;
  color: #6b7280;
}

.empty-state h3 {
  font-size: 18px;
  margin-bottom: 8px;
  color: #1f2937;
}

.friends-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.friend-card {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  position: relative;
  pointer-events: none;
}

.friend-card>* {
  pointer-events: auto;
}

.friend-card:hover:not(.has-open-card),
.has-open-card {
  background: #f3f4f6;
  border-color: #6366f1;
}

.friend-info {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  cursor: pointer;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details h3 {
  font-size: 15px;
  font-weight: 500;
  color: #1f2937;
  display: flex;
  align-items: center;
  gap: 6px;
}

.details p {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
}

.verified {
  color: #6366f1;
  font-size: 14px;
}

.unfriend-btn {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fee2e2;
  color: #ef4444;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: 0;
}

.friend-card:hover .unfriend-btn {
  opacity: 1;
}

.unfriend-btn:hover {
  background: #ef4444;
  color: #ffffff;
}

.unfriend-btn-large {
  width: 100%;
  padding: 8px 16px;
  background: #fee2e2;
  color: #ef4444;
  border: 1px solid #ef4444;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 8px;
}

.unfriend-btn-large:hover {
  background: #ef4444;
  color: #ffffff;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 999;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 640px) {
  .friend-card {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .unfriend-btn {
    align-self: flex-end;
    opacity: 1;
  }
}
</style>