<template>
  <div class="author-details-container">
    <!-- Loading State -->
    <div v-if="loading" class="loading-state">
      <i class="fas fa-spinner fa-spin"></i>
      <span>Loading author details...</span>
    </div>

    <!-- Error State -->
    <div v-else-if="error" class="error-state">
      <i class="fas fa-exclamation-circle"></i>
      <p>{{ error }}</p>
      <router-link to="/home" class="back-link">
        <i class="fas fa-arrow-left"></i> Go Back Home
      </router-link>
    </div>

    <!-- Content -->
    <template v-else>
      <!-- Author Info Section -->
      <div class="author-info">
        <div class="author-header">
          <div class="author-image">
            <img :src="author.cover_image" :alt="author.name" />
          </div>
          <div class="author-title">
            <h1>{{ author.name }}</h1>
            <div class="author-meta">
              <span v-if="author.birth_date">
                <i class="fas fa-birthday-cake"></i>
                {{ formatDate(author.birth_date) }}
              </span>
              <span v-if="author.email">
                <i class="fas fa-envelope"></i>
                {{ author.email }}
              </span>
            </div>
          </div>
        </div>
        <div class="author-content">
          <p class="bio">{{ author.biography }}</p>
        </div>
      </div>

      <!-- Books Section -->
      <div class="books-section">
        <div class="section-header">
          <h2>Books by {{ author.name }}</h2>
          <div class="view-toggle">
            <button @click="toggleView('grid')" :class="{ active: viewMode === 'grid' }" title="Grid View">
              <i class="fas fa-th-large"></i>
            </button>
            <button @click="toggleView('list')" :class="{ active: viewMode === 'list' }" title="List View">
              <i class="fas fa-list"></i>
            </button>
          </div>
        </div>

        <!-- Books Loading State -->
        <div v-if="loadingBooks" class="loading-state">
          <i class="fas fa-spinner fa-spin"></i>
          <span>Loading books...</span>
        </div>

        <!-- Books Display -->
        <div v-else class="books-container" :class="viewMode">
          <template v-if="books.length">
            <component :is="viewMode === 'grid' ? 'BookCardWithCart' : 'BookCardWithCartListView'" v-for="book in books"
              :key="book.id" :book="book" />
          </template>
          <div v-else class="no-books">
            No books found for this author.
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import authorService from '@/api/author'
import BookCardWithCart from '@/components/shared/BookCardWithCart.vue'
import BookCardWithCartListView from '@/components/shared/BookCardWithCartListView.vue'

export default {
  name: 'AuthorDetails',

  components: {
    BookCardWithCart,
    BookCardWithCartListView
  },

  setup() {
    const route = useRoute()
    const author = ref(null)
    const books = ref([])
    const loading = ref(true)
    const loadingBooks = ref(true)
    const error = ref(null)
    const viewMode = ref(localStorage.getItem('preferredViewMode') || 'grid')

    const fetchAuthorDetails = async () => {
      try {
        loading.value = true
        error.value = null

        const response = await authorService.getAuthor(route.params.id)
        if (response.data.success) {
          author.value = response.data.data
        } else {
          throw new Error('Failed to fetch author details')
        }
      } catch (err) {
        error.value = err.response?.status === 404
          ? 'This author doesn\'t exist'
          : 'Failed to load author details'
      } finally {
        loading.value = false
      }
    }

    const fetchAuthorBooks = async () => {
      try {
        loadingBooks.value = true
        const response = await authorService.getAuthorBooks(route.params.id)
        if (response.data.success) {
          books.value = response.data.data
        }
      } catch (err) {
        console.error('Error fetching author books:', err)
      } finally {
        loadingBooks.value = false
      }
    }

    const toggleView = (mode) => {
      viewMode.value = mode
      localStorage.setItem('preferredViewMode', mode)
    }

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    }

    onMounted(async () => {
      await fetchAuthorDetails()
      if (!error.value) {
        await fetchAuthorBooks()
      }
    })

    return {
      author,
      books,
      loading,
      loadingBooks,
      error,
      viewMode,
      toggleView,
      formatDate
    }
  }
}
</script>

<style scoped>
.author-details-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.loading-state,
.error-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  gap: 1rem;
  text-align: center;
}

.author-info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 3rem;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: var(--shadow);
}


.author-header {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.author-image {
  width: 200px;
  height: 200px;
  flex-shrink: 0;
}

.author-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.author-content {
  flex: 1;
}

.author-content h1 {
  color: #282772;
  margin-bottom: 1rem;
}

.bio {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.author-meta {
  display: flex;
  gap: 2rem;
  color: #666;
}

.author-meta span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.view-toggle {
  display: flex;
  gap: 0.5rem;
}

.view-toggle button {
  background: white;
  border: 1px solid #ddd;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.view-toggle button.active {
  background: #f27cf9;
  color: white;
  border-color: #f27cf9;
}

.books-container {
  display: grid;
  gap: 2rem;
}

.books-container.grid {
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}

.books-container.list {
  grid-template-columns: 1fr;
}

.no-books {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.back-link {
  color: #f27cf9;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .author-details-container {
    margin: 1rem auto;
  }

  .author-info {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }

  .author-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
  }

  .author-image {
    width: 150px;
    height: 150px;
  }

  .author-meta {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .books-container.grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 1rem;
  }

  .section-header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .section-header h2 {
    font-size: 1.5rem;
  }

  .view-toggle {
    align-self: flex-end;
  }
}

@media (max-width: 480px) {
  .author-info {
    padding: 1rem;
  }

  .author-image {
    width: 120px;
    height: 120px;
  }

  .author-content h1 {
    font-size: 1.5rem;
  }

  .bio {
    font-size: 0.95rem;
  }

  .books-container.grid {
    grid-template-columns: 1fr;
  }

  .author-meta {
    font-size: 0.9rem;
  }

  .view-toggle {
    align-self: center;
  }
}
</style>