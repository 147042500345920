import api from './axios'

export const authorService = {
  // Get author details
  getAuthor(id) {
    return api.get(`/authors/${id}/`)
  },

  // Get author's books
  getAuthorBooks(id) {
    return api.get(`/authors/${id}/books/`)
  }
}

export default authorService