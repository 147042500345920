<!-- components/profile/ProfileReviews.vue -->
<template>
    <div class="reviews-section">
        <!-- Loading State -->
        <div v-if="loading" class="loading-state">
            <i class="fas fa-spinner fa-spin"></i>
            Loading reviews...
        </div>

        <!-- Error State -->
        <div v-else-if="error" class="error-state">
            <p>{{ error }}</p>
            <button @click="fetchReviews" class="retry-btn">
                <i class="fas fa-redo"></i>
                Retry
            </button>
        </div>

        <!-- Reviews Content -->
        <template v-else>
            <!-- Reviews Stats -->
            <div class="reviews-stats">
                <div class="stat-item">
                    <span class="stat-value">{{ totalReviews }}</span>
                    <span class="stat-label">Total Reviews</span>
                </div>
                <div class="stat-item">
                    <span class="stat-value">{{ averageRating.toFixed(1) }}</span>
                    <div class="rating-stars">
                        <i v-for="star in 5" :key="star" class="fa-star"
                            :class="star <= Math.round(averageRating) ? 'fas' : 'far'"></i>
                    </div>
                    <span class="stat-label">Average Rating</span>
                </div>
            </div>

            <!-- Reviews List -->
            <div v-if="reviews.length > 0" class="reviews-list">
                <div v-for="review in reviews" :key="review.id" class="review-card">
                    <div class="review-header">
                        <div class="book-info">
                            <h3>{{ review.book }}</h3>

                        </div>
                        <div class="review-meta">
                            <div class="rating-display">
                                <i v-for="star in 5" :key="star" class="fa-star"
                                    :class="star <= parseFloat(review.rating) ? 'fas' : 'far'"></i>
                                <span class="rating-value">{{ review.rating }}/5</span>
                            </div>
                            <span class="review-date" :title="formatDate(review.created_at, true)">
                                {{ formatDate(review.created_at) }}
                            </span>
                        </div>
                    </div>

                    <h4 class="review-title">{{ review.title }}</h4>
                    <p class="review-content">{{ review.content }}</p>

                    <div class="review-footer">
                        <span v-if="review.contains_spoiler" class="spoiler-tag">
                            <i class="fas fa-exclamation-triangle"></i>
                            Contains spoilers
                        </span>
                        <span v-if="review.updated_at !== review.created_at" class="edited-tag"
                            :title="'Edited on ' + formatDate(review.updated_at, true)">
                            Edited
                        </span>
                    </div>
                </div>

                <!-- Pagination -->
                <div v-if="totalPages > 1" class="pagination">
                    <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1" class="page-btn">
                        <i class="fas fa-chevron-left"></i>
                        Previous
                    </button>

                    <span class="page-info">
                        Page {{ currentPage }} of {{ totalPages }}
                    </span>

                    <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"
                        class="page-btn">
                        Next
                        <i class="fas fa-chevron-right"></i>
                    </button>
                </div>
            </div>

            <!-- Empty State -->
            <div v-else class="empty-state">
                <i class="fas fa-book-reader"></i>
                <h3>No Reviews Yet</h3>
                <p v-if="isOwnProfile">
                    Share your thoughts about the books you've read!
                    <router-link to="/catalog" class="browse-link">
                        Find Books to Review
                    </router-link>
                </p>
                <p v-else>
                    {{ profile.first_name }} hasn't written any reviews yet.
                </p>
            </div>
        </template>
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import api from '@/api/axios'

export default {
    name: 'ProfileReviews',

    props: {
        profile: {
            type: Object,
            required: true
        },
        isOwnProfile: {
            type: Boolean,
            default: false
        }
    },

    setup(props) {
        const reviews = ref([])
        const loading = ref(true)
        const error = ref(null)
        const currentPage = ref(1)
        const totalReviews = ref(0)
        const totalPages = ref(0)

        const averageRating = computed(() => {
            if (!reviews.value.length) return 0;
            const total = reviews.value.reduce((acc, review) => acc + parseFloat(review.rating || 0), 0);
            return total / reviews.value.length;
        });

        const fetchReviews = async (page = 1) => {
            try {
                loading.value = true;
                error.value = null;

                const response = await api.get(`/users/${props.profile.id}/reviews/`, {
                    params: { page }
                });

                if (response.data.status === 'success') {
                    reviews.value = response.data.results || [];
                    totalReviews.value = response.data.count;
                    currentPage.value = page;
                    totalPages.value = Math.ceil(response.data.count / 10);
                }
            } catch (err) {
                console.error('Error fetching reviews:', err);
                error.value = 'Failed to load reviews. Please try again.';
                reviews.value = [];
            } finally {
                loading.value = false;
            }
        };

        const changePage = (newPage) => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            fetchReviews(newPage)
        }

        const formatDate = (dateString, includeTime = false) => {
            if (!dateString) return ''

            const date = new Date(dateString)
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                ...(includeTime && {
                    hour: '2-digit',
                    minute: '2-digit'
                })
            }

            return date.toLocaleDateString('en-US', options)
        }

        onMounted(() => {
            fetchReviews(1)
        })

        return {
            reviews,
            loading,
            error,
            currentPage,
            totalReviews,
            totalPages,
            averageRating,
            changePage,
            fetchReviews,
            formatDate
        }
    }
}
</script>

<style scoped>
.reviews-section {
    padding: 1rem;
}

.reviews-stats {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.stat-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a1a1a;
}

.stat-label {
    color: #666;
    font-size: 0.9rem;
}

.rating-stars {
    display: flex;
    gap: 0.25rem;
}

.rating-stars i {
    color: #f27cf9;
}

.rating-stars i.far {
    color: #e0e0e0;
}

.reviews-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.review-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.review-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.book-info {
    display: flex;
    gap: 1rem;
    text-decoration: none;
    color: inherit;
}

.book-cover {
    width: 60px;
    height: 90px;
    object-fit: cover;
    border-radius: 4px;
}

.book-details h3 {
    margin: 0 0 0.25rem 0;
    font-size: 1.1rem;
    color: #1a1a1a;
}

.author {
    margin: 0;
    color: #666;
    font-size: 0.9rem;
}

.review-meta {
    text-align: right;
}

.rating-display {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 0.5rem;
}

.rating-value {
    color: #666;
    font-size: 0.9rem;
    margin-left: 0.25rem;
}

.review-date {
    color: #666;
    font-size: 0.85rem;
}

.review-title {
    margin: 0 0 0.75rem 0;
    color: #1a1a1a;
    font-size: 1.1rem;
}

.review-content {
    margin: 0;
    line-height: 1.6;
    color: #444;
    white-space: pre-line;
}

.review-footer {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.spoiler-tag,
.edited-tag {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem 0.75rem;
    border-radius: 12px;
    font-size: 0.85rem;
}

.spoiler-tag {
    background: #fff4e5;
    color: #8b6011;
}

.edited-tag {
    background: #f8f9fa;
    color: #666;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
}

.page-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    background: #f27cf9;
    color: white;
    cursor: pointer;
    transition: opacity 0.2s;
}

.page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.page-info {
    color: #666;
    font-size: 0.9rem;
}

/* Loading State */
.loading-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 3rem;
    color: #666;
}

.loading-state i {
    font-size: 2rem;
    color: #f27cf9;
}

/* Error State */
.error-state {
    text-align: center;
    padding: 3rem;
    color: #dc3545;
}

.retry-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem auto;
    padding: 0.5rem 1rem;
    background: #f27cf9;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

/* Empty State */
.empty-state {
    text-align: center;
    padding: 4rem 2rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.empty-state i {
    font-size: 3rem;
    color: #f27cf9;
    margin-bottom: 1rem;
}

.empty-state h3 {
    margin: 0 0 0.5rem 0;
    color: #1a1a1a;
}

.empty-state p {
    margin: 0;
    color: #666;
}

.browse-link {
    color: #f27cf9;
    text-decoration: none;
    font-weight: 500;
}

.browse-link:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .reviews-section {
        padding: 0.5rem;
    }

    .reviews-stats {
        padding: 1rem;
        gap: 1rem;
    }

    .review-header {
        flex-direction: column;
        gap: 1rem;
    }

    .review-meta {
        text-align: left;
    }

    .book-cover {
        width: 50px;
        height: 75px;
    }
}

@media (max-width: 480px) {
    .reviews-stats {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .pagination {
        flex-direction: column;
        gap: 0.5rem;
    }

    .page-btn {
        width: 100%;
        justify-content: center;
    }
}
</style>