<template>
    <div class="search-wrapper">
        <div class="search-container">
            <i class="fas fa-search"></i>
            <input type="text" :placeholder="placeholder" v-model="searchInput" @input="handleInput"
                @focus="showResults = true" />
            <!-- Loading Spinner -->
            <div v-if="isLoading" class="loading-spinner">
                <i class="fas fa-circle-notch fa-spin"></i>
            </div>
        </div>

        <!-- Search Results Box -->
        <div v-if="showResults && searchInput.length >= 2" class="search-results">
            <!-- Loading State -->
            <div v-if="isLoading" class="search-result-item loading-message">
                <i class="fas fa-circle-notch fa-spin"></i>
                Searching...
            </div>

            <!-- No Results State -->
            <div v-else-if="!isLoading && searchResults.length === 0" class="search-result-item no-results">
                <i class="fas fa-user-slash"></i>
                No users found
            </div>

            <!-- Results List -->
            <div v-else v-for="user in searchResults" :key="user.id" class="search-result-item"
                @click="handleUserSelect(user)">
                <img :src="getAssetUrl(user.profile_picture)" :alt="user.username" class="user-avatar">
                <div class="user-info">
                    <div class="username">{{ user.username }}</div>
                    <div class="full-name">{{ user.first_name }} {{ user.last_name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api, { getAssetUrl } from '@/api/axios'
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
    name: 'SearchField',
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Search People...'
        }
    },
    emits: ['update:modelValue'],

    setup() {
        const router = useRouter()
        const searchInput = ref('')
        const searchResults = ref([])
        const showResults = ref(false)
        const isLoading = ref(false)
        let debounceTimeout = null

        const searchUsers = async (query) => {
            isLoading.value = true
            try {
                const response = await api.get(`/users/search?query=${query}`)
                searchResults.value = response.data.results
            } catch (error) {
                console.error('Search error:', error)
                searchResults.value = []
            } finally {
                isLoading.value = false
            }
        }

        const handleInput = () => {
            // Clear previous timeout
            if (debounceTimeout) clearTimeout(debounceTimeout)

            // Only search if input length is >= 2
            if (searchInput.value.length >= 2) {
                isLoading.value = true // Show loading state immediately
                debounceTimeout = setTimeout(() => {
                    searchUsers(searchInput.value)
                }, 300) // Debounce for 300ms
            } else {
                searchResults.value = []
                isLoading.value = false
            }
        }

        const handleUserSelect = (user) => {
            router.push(`/profile/${user.id}`)
            searchInput.value = ''
            showResults.value = false
            searchResults.value = []
        }

        const handleClickOutside = (event) => {
            if (!event.target.closest('.search-wrapper')) {
                showResults.value = false
            }
        }

        onMounted(() => {
            document.addEventListener('click', handleClickOutside)
        })

        onUnmounted(() => {
            document.removeEventListener('click', handleClickOutside)
            if (debounceTimeout) clearTimeout(debounceTimeout)
        })

        return {
            searchInput,
            searchResults,
            showResults,
            isLoading,
            handleInput,
            handleUserSelect,
            getAssetUrl
        }
    }
}
</script>

<style scoped>
.search-wrapper {
    position: relative;
    width: 100%;
}

.search-container {
    position: relative;
    margin-bottom: 1rem;
}

.search-container input {
    width: 100%;
    padding: 0.875rem 1rem 0.875rem 2.75rem;
    background: var(--white);
    border: 1px solid var(--border-color);
    border-radius: 12px;
    font-size: 0.875rem;
    transition: all 0.3s ease;
}

.search-container input:focus {
    outline: none;
    border-color: var(--primary-purple);
    box-shadow: 0 0 0 3px var(--primary-light);
}

.search-container i {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-gray);
    pointer-events: none;
}

/* Loading Spinner in Input */
.loading-spinner {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-gray);
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--white);
    border: 1px solid var(--border-color);
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
}

.search-result-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.search-result-item:hover {
    background-color: var(--bg-light);
}

/* Loading and No Results States */
.loading-message,
.no-results {
    justify-content: center;
    color: var(--text-gray);
    cursor: default;
}

.loading-message:hover,
.no-results:hover {
    background-color: var(--white);
}

.loading-message i,
.no-results i {
    margin-right: 0.5rem;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1rem;
}

.user-info {
    flex: 1;
}

.username {
    font-weight: 600;
    color: var(--text-dark);
}

.full-name {
    font-size: 0.875rem;
    color: var(--text-gray);
}

/* Animation for loading spinner */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.fa-spin {
    animation: spin 1s linear infinite;
}
</style>