import TermsAndConditions from '@/views/legal/TermsAndConditions.vue'
import RentalPolicy from '@/views/legal/RentalPolicy.vue'

export default [
  {
    path: '/terms',
    name: 'terms',
    component: TermsAndConditions,
  },
  {
    path: '/rental-policy',
    name: 'rental-policy',
    component: RentalPolicy,
  },
]
