<template>
  <div class="friend-requests-container">
    <!-- Loading State -->
    <div v-if="loading" class="loading-state">
      <div class="spinner"></div>
    </div>

    <template v-else>
      <!-- Toggle Buttons -->
      <div class="request-toggles">
        <button :class="['toggle-btn', { active: showIncoming }]" @click="showIncoming = true">
          Incoming ({{ friendRequests.received.length }})
        </button>
        <button :class="['toggle-btn', { active: !showIncoming }]" @click="showIncoming = false">
          Outgoing ({{ friendRequests.sent.length }})
        </button>
      </div>

      <div class="requests-list">
        <!-- Incoming Requests -->
        <template v-if="showIncoming">
          <div v-for="request in friendRequests.received" :key="request.id" class="request-card"
            :class="{ 'has-open-card': selectedRequest?.id === request.id }">
            <div class="user-info" @click="toggleProfileCard(request, $event)">
              <div class="avatar">
                <img :src="request.from_user.profile_picture_url" :alt="request.from_user.username" />
              </div>
              <div class="details">
                <h3>
                  {{ request.from_user.username }}
                  <i v-if="request.from_user.is_verified" class="fas fa-check-circle verified"></i>
                </h3>
                <p>{{ request.from_user.status || 'No status' }}</p>
              </div>
            </div>

            <div class="actions">
              <button class="accept-btn" @click.stop="handleAccept(request.id, request.from_user.id)">
                Accept
              </button>
              <button class="decline-btn" @click.stop="handleDecline(request.id, request.from_user.id)">
                Decline
              </button>
            </div>

            <UserProfileCard v-if="selectedRequest?.id === request.id" :user="request.from_user"
              :position="profileCardPosition" @close="closeProfileCard" @view-profile="goToProfile">
              <template #actions>
                <div class="card-actions">
                  <button class="accept-btn w-full" @click="handleAccept(request.id, request.from_user.id)">
                    Accept Request
                  </button>
                  <button class="decline-btn w-full mt-2" @click="handleDecline(request.id, request.from_user.id)">
                    Decline Request
                  </button>
                </div>
              </template>
            </UserProfileCard>
          </div>

          <div v-if="!friendRequests.received.length" class="empty-state">
            No incoming friend requests
          </div>
        </template>

        <!-- Outgoing Requests -->
        <template v-else>
          <div v-for="request in friendRequests.sent" :key="request.id" class="request-card"
            :class="{ 'has-open-card': selectedRequest?.id === request.id }">
            <div class="user-info" @click="toggleProfileCard(request, $event)">
              <div class="avatar">
                <img :src="request.to_user.profile_picture_url" :alt="request.to_user.username" />
              </div>
              <div class="details">
                <h3>
                  {{ request.to_user.username }}
                  <i v-if="request.to_user.is_verified" class="fas fa-check-circle verified"></i>
                </h3>
                <p>{{ request.to_user.status || 'No status' }}</p>
              </div>
            </div>

            <button class="cancel-btn" @click.stop="handleCancel(request.id, request.to_user.id)">
              Cancel
            </button>

            <UserProfileCard v-if="selectedRequest?.id === request.id" :user="request.to_user"
              :position="profileCardPosition" @close="closeProfileCard" @view-profile="goToProfile">
              <template #actions>
                <button class="cancel-btn w-full" @click="handleCancel(request.id, request.to_user.id)">
                  Cancel Request
                </button>
              </template>
            </UserProfileCard>
          </div>

          <div v-if="!friendRequests.sent.length" class="empty-state">
            No outgoing friend requests
          </div>
        </template>
      </div>
    </template>

    <div v-if="selectedRequest" class="overlay" @click="closeProfileCard"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UserProfileCard from '@/components/social/card/UserProfileCard.vue'

export default {
  name: 'FriendRequests',

  components: {
    UserProfileCard
  },

  data() {
    return {
      showIncoming: true,
      loading: true,
      selectedRequest: null,
      profileCardPosition: {
        top: '0px',
        left: '0px'
      }
    }
  },

  computed: {
    ...mapState('social', ['friendRequests'])
  },

  methods: {
    ...mapActions('social', [
      'fetchFriendRequests',
      'acceptRequest',
      'rejectRequest',
      'cancelRequest'
    ]),

    goToProfile(userId) {
      this.$router.push(`/profile/${userId}`)
    },

    toggleProfileCard(request, event) {
      if (this.selectedRequest?.id === request.id) {
        this.closeProfileCard()
        return
      }

      // Get viewport dimensions
      const viewportWidth = window.innerWidth
      const viewportHeight = window.innerHeight

      // Card dimensions (adjust these based on your actual card size)
      const cardWidth = 320
      const cardHeight = 400

      // Calculate center position
      const left = (viewportWidth - cardWidth) / 2
      const top = (viewportHeight - cardHeight) / 2

      // Ensure the card stays within viewport bounds
      this.profileCardPosition = {
        left: `${Math.max(20, Math.min(left, viewportWidth - cardWidth - 20))}px`,
        top: `${Math.max(20, Math.min(top, viewportHeight - cardHeight - 20))}px`
      }

      this.selectedRequest = request
    },

    closeProfileCard() {
      this.selectedRequest = null
    },

    async handleAccept(requestId, userId) {
      try {
        await this.acceptRequest({ requestId, userId })
        this.$toast.success('Friend request accepted')
        this.closeProfileCard()
      } catch (error) {
        this.$toast.error('Failed to accept request')
      }
    },

    async handleDecline(requestId, userId) {
      try {
        await this.rejectRequest({ requestId, userId })
        this.$toast.success('Friend request declined')
        this.closeProfileCard()
      } catch (error) {
        this.$toast.error('Failed to decline request')
      }
    },

    async handleCancel(requestId, userId) {
      try {
        await this.cancelRequest({ requestId, userId })
        this.$toast.success('Friend request cancelled')
        this.closeProfileCard()
      } catch (error) {
        this.$toast.error('Failed to cancel request')
      }
    }
  },

  async created() {
    try {
      await this.fetchFriendRequests()
    } catch (error) {
      this.$toast.error('Failed to load friend requests')
    } finally {
      this.loading = false
    }
  },

  beforeDestroy() {
    this.closeProfileCard()
  }
}
</script>

<style scoped>
.friend-requests-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;
}

.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.spinner {
  width: 32px;
  height: 32px;
  border: 4px solid #eef2ff;
  border-top-color: #6366f1;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.request-toggles {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}

.toggle-btn {
  padding: 8px 16px;
  border: none;
  background: #f3f4f6;
  color: #6b7280;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.toggle-btn.active {
  background: #6366f1;
  color: #ffffff;
}

.toggle-btn:hover:not(.active) {
  background: #e5e7eb;
}

.requests-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.request-card {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  position: relative;
  pointer-events: none;
}

.request-card>* {
  pointer-events: auto;
}

.request-card:hover:not(.has-open-card),
.has-open-card {
  background: #f3f4f6;
  border-color: #6366f1;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  cursor: pointer;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details h3 {
  font-size: 15px;
  font-weight: 500;
  color: #1f2937;
  display: flex;
  align-items: center;
  gap: 6px;
}

.details p {
  font-size: 13px;
  color: #6b7280;
  margin-top: 2px;
}

.verified {
  color: #6366f1;
  font-size: 14px;
}

.actions {
  display: flex;
  gap: 8px;
}

.accept-btn,
.decline-btn,
.cancel-btn {
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.accept-btn {
  background: #6366f1;
  color: #ffffff;
  border: none;
}

.accept-btn:hover {
  background: #4f46e5;
}

.decline-btn {
  background: #ffffff;
  color: #6b7280;
  border: 1px solid #e5e7eb;
}

.decline-btn:hover,
.decline-btn.w-full:hover {
  background: #fee2e2;
  border-color: #ef4444;
  color: #ef4444;
}

.cancel-btn {
  background: #ffffff;
  color: #6b7280;
  border: 1px solid #e5e7eb;
}

.cancel-btn:hover,
.cancel-btn.w-full:hover {
  background: #fee2e2;
  border-color: #ef4444;
  color: #ef4444;
}

.empty-state {
  text-align: center;
  padding: 32px;
  color: #6b7280;
  background: #f9fafb;
  border-radius: 8px;
  border: 1px dashed #e5e7eb;
}

.card-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
}

.w-full {
  width: 100%;
}

.mt-2 {
  margin-top: 8px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 999;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 640px) {
  .request-toggles {
    position: sticky;
    top: 0;
    background: #ffffff;
    padding: 12px;
    z-index: 10;
    margin: -16px -16px 16px;
    border-bottom: 1px solid #e5e7eb;
  }

  .toggle-btn {
    flex: 1;
    text-align: center;
  }

  .request-card {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .actions {
    width: 100%;
    justify-content: flex-end;
  }

  .accept-btn,
  .decline-btn,
  .cancel-btn {
    flex: 1;
  }
}
</style>