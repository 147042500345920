<template>
  <div class="catalog-container">
    <!-- Mobile Filter Toggle -->
    <button
      v-show="isMobileView"
      class="mobile-filter-toggle"
      @click="toggleMobileFilters"
    >
      {{ showMobileFilters ? 'Hide Filters' : 'Show Filters' }}
    </button>

    <template v-if="!loading">
      <CatalogHeader
        :filters="activeFilters"
        :viewMode="viewMode"
        :setViewMode="setViewMode"
        :applyFilters="handleApplyFilters"
      />

      <div class="catalog-layout">
        <!-- Filter Sidebar with mobile overlay -->
        <div
          class="filters-sidebar-container"
          :class="{
            mobile: isMobileView,
            show: showMobileFilters,
          }"
        >
          <div class="filters-sidebar-content">
            <!-- Mobile Close Button -->
            <button
              v-if="isMobileView"
              class="mobile-close-btn"
              @click="toggleMobileFilters"
            >
              ×
            </button>

            <FiltersSidebar @filter-change="handleFilterChange" />
          </div>

          <!-- Mobile Overlay Background -->
          <div
            v-if="isMobileView"
            class="mobile-overlay"
            @click="toggleMobileFilters"
          ></div>
        </div>

        <main class="catalog-main">
          <SearchFields
            :filters="activeFilters"
            :handleSearch="handleFilterChange"
          />

          <BooksGrid
            :books="books"
            :loadingBooks="loadingBooks"
            :viewMode="viewMode"
          />

          <!-- Pagination -->
          <div v-if="totalItems > 0" class="pagination-container">
            <div class="pagination-controls">
              <button
                @click="goToPage(currentPage - 1)"
                :disabled="!previousPage"
                class="pagination-button"
              >
                Previous
              </button>

              <span class="pagination-info">
                Page {{ currentPage }} | Total Books: {{ totalItems }}
              </span>

              <button
                @click="goToPage(currentPage + 1)"
                :disabled="!nextPage"
                class="pagination-button"
              >
                Next
              </button>
            </div>
          </div>
        </main>
      </div>
    </template>

    <div v-else class="loading-state">
      <div class="loader"></div>
      <p>Loading catalog...</p>
    </div>
  </div>
</template>

<script>
// Vue core imports
import { ref, onMounted, onUnmounted } from 'vue'

// API imports
import api from '@/api/axios'

// Component imports
import CatalogHeader from '@/components/catalog/CatalogHeader.vue'
import FiltersSidebar from '@/components/catalog/FiltersSidebar.vue'
import SearchFields from '@/components/catalog/SearchFields.vue'
import BooksGrid from '@/components/catalog/BooksGrid.vue'

export default {
  name: 'Catalog',
  components: {
    CatalogHeader,
    FiltersSidebar,
    SearchFields,
    BooksGrid,
  },

  setup() {
    const books = ref([])
    const loading = ref(false)
    const loadingBooks = ref(false)
    const currentPage = ref(1)
    const totalItems = ref(0)
    const nextPage = ref(null)
    const previousPage = ref(null)
    const viewMode = ref(localStorage.getItem('preferredViewMode') || 'grid')
    const showMobileFilters = ref(false)
    const isMobileView = ref(window.innerWidth <= 768)
    const searchTimeout = ref(null)

    // Active filters state
    const activeFilters = ref({
      title: '',
      author: '',
      search: '',
      ordering: '',
      genres: [],
      languages: [],
      formats: [],
      min_price: '',
      max_price: '',
    })

    const handleApplyFilters = () => {
      currentPage.value = 1 // Reset to first page when filters change
      fetchBooks()
    }

    const fetchBooks = async () => {
      loadingBooks.value = true
      try {
        const params = new URLSearchParams()

        // Add current page
        params.append('page', currentPage.value)

        // Add search filters
        if (activeFilters.value.title?.trim()) {
          params.append('title', activeFilters.value.title.trim())
        }
        if (activeFilters.value.author?.trim()) {
          params.append('author', activeFilters.value.author.trim())
        }

        // Add genre filters - making sure we have valid genre names
        if (Array.isArray(activeFilters.value.genres)) {
          activeFilters.value.genres.forEach(genreName => {
            if (genreName && typeof genreName === 'string') {
              params.append('genre', genreName)
            }
          })
        }

        // Add language filters
        if (activeFilters.value.language) {
          params.append('language', activeFilters.value.language)
        }

        // Add format filters
        if (Array.isArray(activeFilters.value.formats)) {
          activeFilters.value.formats.forEach(format => {
            if (format) params.append('format', format)
          })
        }

        // Add price filters
        if (activeFilters.value.min_price) {
          params.append('min_price', activeFilters.value.min_price)
        }
        if (activeFilters.value.max_price) {
          params.append('max_price', activeFilters.value.max_price)
        }

        // Add sorting
        if (activeFilters.value.ordering) {
          params.append('ordering', activeFilters.value.ordering)
        }

        const response = await api.get(`/books/?${params.toString()}`)

        if (response.data.status === 'success') {
          books.value = response.data.results['book(s)']
          totalItems.value = response.data.count
          nextPage.value = response.data.next
          previousPage.value = response.data.previous
        }
      } catch (error) {
        console.error('Error fetching books:', error)
      } finally {
        loadingBooks.value = false
      }
    }

    const handleFilterChange = filters => {
      activeFilters.value = {
        ...activeFilters.value,
        ...filters,
      }
      currentPage.value = 1
      fetchBooks()
    }

    const handleSearch = searchTerm => {
      if (searchTimeout.value) clearTimeout(searchTimeout.value)

      searchTimeout.value = setTimeout(() => {
        activeFilters.value.search = searchTerm
        handleApplyFilters()
      }, 300)
    }

    const setViewMode = mode => {
      viewMode.value = mode
      localStorage.setItem('preferredViewMode', mode)
    }

    const goToPage = page => {
      currentPage.value = page
      fetchBooks()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const toggleMobileFilters = () => {
      showMobileFilters.value = !showMobileFilters.value
      document.body.style.overflow = showMobileFilters.value ? 'hidden' : ''
    }

    const checkMobileView = () => {
      isMobileView.value = window.innerWidth <= 768
      if (!isMobileView.value && showMobileFilters.value) {
        showMobileFilters.value = false
        document.body.style.overflow = ''
      }
    }

    // Lifecycle hooks
    onMounted(() => {
      window.addEventListener('resize', checkMobileView)
      fetchBooks()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', checkMobileView)
      if (searchTimeout.value) clearTimeout(searchTimeout.value)
      document.body.style.overflow = ''
    })

    return {
      books,
      loading,
      loadingBooks,
      viewMode,
      activeFilters,
      currentPage,
      totalItems,
      nextPage,
      previousPage,
      showMobileFilters,
      isMobileView,
      setViewMode,
      handleFilterChange,
      handleSearch,
      handleApplyFilters,
      goToPage,
      toggleMobileFilters,
    }
  },
}
</script>

<style>
:root {
  --main-bg-color: #f6f7fb;
  --primary-color: #ff8fe5;
  --text-dark: #333;
  --text-light: #666;
  --border-color: #eee;
  --shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  --sidebar-width: 280px;
}

/* Base Styles */
.catalog-container {
  max-width: 1440px;
  margin: 0rem auto;
  padding: 20px;
  min-height: 100vh;
  background-color: white;
  overflow: hidden;
  /* Prevent horizontal scroll */
}

.catalog-layout {
  display: flex;
  /* Changed to flex from grid */
  gap: 2rem;
  margin-bottom: 9rem;
  position: relative;
  width: 100%;
}

/* Sidebar Styles */
.filters-sidebar-container {
  flex-shrink: 0;
  /* Prevent sidebar from shrinking */
  width: var(--sidebar-width);
  position: sticky;
  top: 20px;
  height: fit-content;
}

.filters-sidebar-content {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: var(--shadow);
  width: 100%;
}

/* Main Content Area */
.catalog-main {
  flex: 1;
  min-width: 0;
  /* Important to prevent overflow */
  padding-left: 1rem;
}

/* Books Grid */
.books-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 2rem;
  width: 100%;
}

/* Mobile Filter Toggle */
.mobile-filter-toggle {
  display: none;
  width: 100%;
  padding: 0.75rem;
  margin: 1rem 0;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}

/* Loading States */
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

/* Mobile Styles */
.filters-sidebar-container.mobile {
  display: none;
}

/* Responsive Breakpoints */
@media (max-width: 1400px) {
  .catalog-container {
    max-width: 1200px;
  }

  .books-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 1200px) {
  :root {
    --sidebar-width: 250px;
  }

  .catalog-layout {
    gap: 1.5rem;
  }

  .books-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 1.5rem;
  }
}

@media (max-width: 992px) {
  :root {
    --sidebar-width: 220px;
  }

  .catalog-container {
    padding: 15px;
  }

  .books-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 1.25rem;
  }
}

@media (max-width: 768px) {
  .mobile-filter-toggle {
    display: block;
  }

  .catalog-container {
    padding: 10px;
  }

  .catalog-layout {
    flex-direction: column;
  }

  .filters-sidebar-container {
    display: none;
  }

  .filters-sidebar-container.mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
  }

  .filters-sidebar-container.mobile.show {
    display: block;
  }

  .filters-sidebar-container.mobile .filters-sidebar-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 85%;
    max-width: 320px;
    height: 100%;
    border-radius: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .filters-sidebar-container.mobile.show .filters-sidebar-content {
    transform: translateX(0);
  }

  .catalog-main {
    padding-left: 0;
    width: 100%;
  }

  .books-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.pagination-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pagination-button {
  padding: 0.5rem 1rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.2s;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-info {
  font-size: 0.9rem;
  color: var(--text-light);
}

@media (max-width: 768px) {
  .pagination-controls {
    flex-direction: row;
    gap: 0.5rem;
  }

  .pagination-button {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .catalog-container {
    padding: 8px;
  }

  .books-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 0.75rem;
  }
}

@media (max-width: 360px) {
  .catalog-container {
    padding: 5px;
  }

  .books-grid {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 0.5rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
