<template>
  <div class="hero-section">
    <div class="floating-elements">
      <div class="book book1">📚</div>
      <div class="book book2">📖</div>
      <div class="book book3">📕</div>
      <div class="sparkle sparkle1">✨</div>
      <div class="sparkle sparkle2">✨</div>
      <div class="sparkle sparkle3">✨</div>
    </div>

    <div class="hero-content">
      <div class="hero-text">
        <h1 class="animate-in">
          WELCOME TO <br />B<span class="accent">OO</span>KSVILLE
        </h1>
        <p class="animate-in-delay-1">
          A house to millions of digital and non-digital books from around the
          world
        </p>

        <div class="search-form animate-in-delay-2">
          <input :value="searchQuery" @input="$emit('update:searchQuery', $event.target.value)" type="text"
            placeholder="Write Title Here" class="search-input" />
          <div class="search-filters">
            <input :value="searchAuthor" @input="$emit('update:searchAuthor', $event.target.value)" type="text"
              placeholder="The Author" class="search-input" />
            <button @click="handleSearch" class="search-submit">
              <span>Search</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'HeroSection',
  props: {
    searchQuery: String,
    searchAuthor: String,
    searchYear: String,
    years: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'search',
    'update:searchQuery',
    'update:searchAuthor',
    'update:searchYear',
  ],
  setup(props) {
    const router = useRouter()

    const handleSearch = () => {
      const queryParams = {}
      if (props.searchQuery?.trim()) {
        queryParams.title = props.searchQuery.trim()
      }
      if (props.searchAuthor?.trim()) {
        queryParams.author = props.searchAuthor.trim()
      }
      if (Object.keys(queryParams).length > 0) {
        router.push({
          path: '/search',
          query: queryParams,
        })
      }
    }

    return {
      handleSearch,
    }
  },
}
</script>

<style scoped>
.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  background-image: url('@/assets/img/bg3.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  margin-bottom: 4rem;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.hero-content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 max(20px, 4vw);
  position: relative;
  z-index: 2;
}

.hero-text {
  width: 100%;
  max-width: 700px;
  text-align: center;
  padding: clamp(20px, 4vw, 40px);
  position: relative;
}

.hero-text h1 {
  font-size: clamp(32px, 5vw, 58px);
  color: #282772;
  line-height: 1.2;
  margin-bottom: clamp(20px, 3vw, 30px);
  font-weight: 800;
  letter-spacing: 1px;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.8);
  text-shadow:
    2px 2px 0 #fff,
    -2px -2px 0 #fff,
    2px -2px 0 #fff,
    -2px 2px 0 #fff,
    0 0 8px rgba(255, 255, 255, 0.5);
}


.accent {
  color: #f27cf9;
  position: relative;
  display: inline-block;
  text-shadow:
    2px 2px 0 #fff,
    -2px -2px 0 #fff,
    2px -2px 0 #fff,
    -2px 2px 0 #fff,
    0 0 8px rgba(242, 124, 249, 0.5);
}

.hero-text p {
  font-size: clamp(16px, 2vw, 20px);
  color: #333;
  margin-bottom: clamp(30px, 4vw, 40px);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 25px;
  border-radius: 30px;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.search-form {
  display: flex;
  flex-direction: column;
  gap: clamp(15px, 2vw, 20px);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.search-input {
  width: 100%;
  padding: clamp(12px, 1.5vw, 16px) clamp(20px, 2vw, 25px);
  border: 2px solid #e0e0e0;
  border-radius: 30px;
  font-size: clamp(14px, 1.2vw, 16px);
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.9);
}

.search-input:focus {
  outline: none;
  border-color: #f27cf9;
  box-shadow: 0 0 0 3px rgba(242, 124, 249, 0.2);
  background: white;
}

.search-filters {
  display: flex;
  gap: clamp(15px, 2vw, 20px);
  width: 100%;
}

.search-submit {
  background-color: #f27cf9;
  color: white;
  border: none;
  padding: clamp(12px, 1.5vw, 16px) clamp(25px, 2.5vw, 40px);
  border-radius: 30px;
  cursor: pointer;
  font-size: clamp(14px, 1.2vw, 16px);
  transition: all 0.3s ease;
  font-weight: 600;
  min-width: 100px;
  position: relative;
  overflow: hidden;
}

.search-submit:hover {
  background-color: #e065e7;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(242, 124, 249, 0.3);
}

/* Floating Elements Animations */
.floating-elements {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.book,
.sparkle {
  position: absolute;
  font-size: 2rem;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.book1 {
  top: 20%;
  left: 10%;
  animation: float 6s infinite;
}

.book2 {
  top: 30%;
  right: 15%;
  animation: float 8s infinite 1s;
}

.book3 {
  bottom: 25%;
  left: 20%;
  animation: float 7s infinite 2s;
}

.sparkle1 {
  top: 25%;
  right: 25%;
  animation: sparkle 4s infinite;
}

.sparkle2 {
  bottom: 30%;
  right: 10%;
  animation: sparkle 4s infinite 1s;
}

.sparkle3 {
  top: 40%;
  left: 15%;
  animation: sparkle 4s infinite 2s;
}

/* Entrance Animations */
.animate-in {
  animation: fadeInUp 0.8s ease-out forwards;
}

.animate-in-delay-1 {
  animation: fadeInUp 0.8s ease-out 0.2s forwards;
  opacity: 0;
}

.animate-in-delay-2 {
  animation: fadeInUp 0.8s ease-out 0.4s forwards;
  opacity: 0;
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0) rotate(0);
  }

  50% {
    transform: translateY(-20px) rotate(5deg);
  }
}

@keyframes sparkle {

  0%,
  100% {
    opacity: 0;
    transform: scale(0.8);
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */
@media (max-width: 900px) {
  .floating-elements {
    display: none;
  }
}

@media (max-width: 600px) {
  .search-filters {
    flex-direction: column;
  }

  .search-submit {
    width: 100%;
  }

  .hero-text {
    padding: 15px;
  }
}

/* Landscape Mode */
@media (max-height: 600px) and (orientation: landscape) {
  .hero-section {
    min-height: 120vh;
  }

  .floating-elements {
    display: none;
  }
}
</style>