<template>
  <div class="about-section">
    <div class="info-grid">
      <div class="info-card">
        <h3 class="card-title">
          <i class="fas fa-user-circle"></i>
          Basic Information
        </h3>
        <div class="info-content">
          <div class="info-item">
            <span class="label">Username</span>
            <span class="value">@{{ profile.username }}</span>
          </div>
          <div class="info-item">
            <span class="label">Member Since</span>
            <span class="value">{{ formatDate(profile.created_at) }}</span>
          </div>
          <div class="info-item">
            <span class="label">Date of Birth</span>
            <span class="value">{{ formatDate(profile.date_of_birth) }}</span>
          </div>
        </div>
      </div>

      <div class="info-card">
        <h3 class="card-title">
          <i class="fas fa-book-reader"></i>
          Reading Stats
        </h3>
        <div class="info-content">
          <div class="info-item">
            <span class="label">Favorite Books</span>
            <span class="value">{{ profile.favorite_books?.length || 0 }}</span>
          </div>
          <div class="info-item">
            <span class="label">Favorite Genres</span>
            <span class="value">{{ profile.favorite_genres?.length || 0 }}</span>
          </div>
          <div class="info-item">
            <span class="label">Wishlist Items</span>
            <span class="value">{{ profile.wishlist_items?.length || 0 }}</span>
          </div>
        </div>
      </div>

      <div class="info-card" v-if="profile.favorite_genres?.length">
        <h3 class="card-title">
          <i class="fas fa-bookmark"></i>
          Favorite Genres
        </h3>
        <div class="genres-grid">
          <div v-for="genre in profile.favorite_genres" :key="genre.id" class="genre-tag">
            {{ genre.name }}
          </div>
        </div>
      </div>

      <!-- Mutual Interests Section -->
      <div class="info-card mutual-interests" v-if="hasMutualInterests">
        <h3 class="card-title">
          <i class="fas fa-users"></i>
          Mutual Interests
        </h3>
        <div class="info-content">
          <!-- Mutual Genres -->
          <div v-if="profile.mutual_favorite_genres?.length" class="mutual-section">
            <h4 class="mutual-subtitle">
              <i class="fas fa-bookmark"></i>
              Mutual Genres
            </h4>
            <div class="genres-grid">
              <div v-for="genre in profile.mutual_favorite_genres" :key="genre.id" class="genre-tag mutual">
                {{ genre.name }}
              </div>
            </div>
          </div>

          <!-- Mutual Books -->
          <div v-if="profile.mutual_favorite_books?.length" class="mutual-section">
            <h4 class="mutual-subtitle">
              <i class="fas fa-book"></i>
              Mutual Books
            </h4>
            <div class="mutual-books-grid">
              <div v-for="book in profile.mutual_favorite_books" :key="book.id" class="book-card mutual">
                <img v-if="book.cover_image" :src="getAssetUrl(book.cover_image)" :alt="book.title" class="book-cover"
                  @error="$event.target.style.display = 'none'" />
                <div class="book-title mutual">{{ book.title }}</div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { getAssetUrl } from '@/api/axios'


export default {
  name: 'ProfileAbout',
  props: {
    profile: {
      type: Object,
      required: true,
    },
    isOwnProfile: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const hasMutualInterests = computed(() => {
      return (props.profile.mutual_favorite_genres?.length > 0 ||
        props.profile.mutual_favorite_books?.length > 0)
    })

    const formatDate = (dateString, includeTime = false) => {
      if (!dateString) return 'Not set'

      const date = new Date(dateString)
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        ...(includeTime && {
          hour: '2-digit',
          minute: '2-digit',
        }),
      }

      return date.toLocaleDateString('en-US', options)
    }

    return {
      formatDate,
      hasMutualInterests,
      getAssetUrl
    }
  },
}
</script>

<style scoped>
.about-section {
  padding: 1rem;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.info-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.card-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0 0 1rem 0;
  font-size: 1.2rem;
  color: #1a1a1a;
}

.card-title i {
  color: #f27cf9;
}

.info-content {
  color: #444;
}

.info-item {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid #eee;
}

.info-item:last-child {
  border-bottom: none;
}

.label {
  color: #666;
  font-size: 0.9rem;
}

.value {
  font-weight: 500;
}

.genres-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.genre-tag {
  padding: 0.5rem 1rem;
  background: #f8f9fa;
  color: #f27cf9;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.genre-tag:hover {
  transform: translateY(-2px);
}

.genre-tag.mutual {
  background: #f8e3fc;
  color: #9333ea;
  border: 1px solid #e9d5ff;
}

/* Mutual Interests Styles */
.mutual-interests {
  grid-column: 1 / -1;
}

.mutual-section {
  margin-bottom: 2rem;
}

.mutual-section:last-child {
  margin-bottom: 0;
}

.mutual-subtitle {
  font-size: 1rem;
  color: #666;
  margin: 1rem 0 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.mutual-books-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.book-card {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 0.75rem;
  text-align: center;
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
}

.book-card.mutual {
  background: #f8e3fc;
  border: 1px solid #e9d5ff;
}

.book-card:hover {
  transform: translateY(-4px);
}

.book-cover {
  width: 80px;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.book-title {
  font-weight: 500;
  color: #1a1a1a;
  font-size: 0.9rem;
  /* For text overflow */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.book-title.mutual {
  color: #9333ea;
}

/* Media Queries */
@media (max-width: 768px) {
  .about-section {
    padding: 0.5rem;
  }

  .info-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .info-card {
    padding: 1rem;
  }

  .card-title {
    font-size: 1.1rem;
  }

  .mutual-books-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .book-cover {
    width: 70px;
    height: 105px;
  }
}

@media (max-width: 480px) {
  .info-item {
    flex-direction: column;
    gap: 0.25rem;
  }

  .value {
    text-align: right;
  }

  .mutual-books-grid {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    gap: 0.75rem;
  }

  .book-cover {
    width: 60px;
    height: 90px;
  }

  .book-title {
    font-size: 0.8rem;
  }
}
</style>