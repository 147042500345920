<template>
  <div class="cart-header">
    <h2>Your Shopping Cart</h2>
    <router-link to="/home" class="home-link">Home / Checkout</router-link>
  </div>
</template>

<style scoped>
.cart-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.home-link {
  color: #666;
  text-decoration: none;
}

@media (min-width: 768px) {
  .cart-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
