<template>
    <div class="verify-payment">
        <div v-if="loading" class="loading-state">
            <div class="spinner"></div>
            <p>Verifying your payment...</p>
        </div>

        <div v-else-if="error" class="error-state">
            <h2>Payment Verification Failed</h2>
            <p>{{ error }}</p>
            <button @click="retryVerification" class="retry-button">
                Retry Verification
            </button>
        </div>

        <div v-else-if="success" class="success-state">
            <h2>Payment Successful!</h2>
            <p>Your order has been confirmed.</p>
            <router-link to="/account/orders" class="view-orders-button">
                View Order History
            </router-link>
        </div>
    </div>
</template>

<script>
import api from '@/api/axios'
import { useToast } from "vue-toastification"

export default {
    name: 'VerifyPayment',

    setup() {
        const toast = useToast()
        return { toast }
    },

    data() {
        return {
            loading: true,
            error: null,
            success: false
        }
    },

    methods: {
        async verifyPayment() {
            try {
                const queryParams = new URLSearchParams(window.location.search)
                const response = await api.get(`/payments/verify-khalti/?${queryParams.toString()}`)
                console.log('Verification response:', response.data)

                // The issue might be here - check if the success condition matches the API response
                if (response.data?.status === "Completed") {  // Changed from success to status
                    this.success = true
                    this.toast.success('Payment verified successfully')
                } else {
                    throw new Error(response.data?.message || 'Payment verification failed')
                }
            } catch (err) {
                console.error('Verification error:', err)
                this.error = err.message
                this.toast.error('Payment verification failed')
            } finally {
                this.loading = false
            }
        },

        retryVerification() {
            this.loading = true
            this.error = null
            this.verifyPayment()
        }
    },

    created() {
        this.verifyPayment()
    }
}
</script>

<style scoped>
.verify-payment {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.loading-state {
    text-align: center;
    padding: 32px;
}

.spinner {
    width: 32px;
    height: 32px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    margin: 0 auto 16px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.error-state {
    background-color: #fff5f5;
    padding: 16px;
    border-radius: 4px;
}

.error-state h2 {
    color: #dc3545;
    font-weight: 600;
    margin-bottom: 8px;
}

.error-state p {
    color: #dc3545;
}

.success-state {
    background-color: #f0fff4;
    padding: 16px;
    border-radius: 4px;
}

.success-state h2 {
    color: #28a745;
    font-weight: 600;
    margin-bottom: 8px;
}

.success-state p {
    color: #28a745;
}

.retry-button,
.view-orders-button {
    display: inline-block;
    margin-top: 16px;
    padding: 8px 16px;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
}

.retry-button {
    background-color: #dc3545;
    color: white;
    border: none;
}

.view-orders-button {
    background-color: #28a745;
    color: white;
}
</style>