<template>
  <div class="search-input-wrapper">
    <input
      ref="searchInput"
      type="text"
      :value="modelValue"
      @input="handleInput"
      :placeholder="
        searchType === 'title'
          ? 'Search for books...'
          : 'Search by author name...'
      "
      class="search-input"
    />
    <button class="clear-button" @click="handleClear" v-if="modelValue">
      ×
    </button>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    searchType: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'search', 'clear'],

  methods: {
    handleInput(e) {
      this.$emit('update:modelValue', e.target.value)
      this.$emit('search')
    },
    handleClear() {
      this.$emit('clear')
    },
  },
}
</script>

<style scoped>
.search-input-wrapper {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.search-input {
  width: 100%;
  padding: 1rem 1.5rem;
  border: 2px solid #eee;
  border-radius: 8px;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.search-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.clear-button {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
}

.clear-button:hover {
  color: #333;
}

@media (max-width: 768px) {
  .search-input {
    font-size: 1rem;
    padding: 0.75rem 1rem;
  }
}
</style>
