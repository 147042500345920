<template>
    <div class="profile-card" :style="position">
        <div class="profile-header">
            <img :src="user.profile_picture_url" :alt="user.username" class="profile-image" />
            <div class="profile-info">
                <h4>
                    {{ user.first_name }} {{ user.last_name }}
                    <i v-if="user.is_verified" class="fas fa-check-circle verified"></i>
                </h4>
                <span>@{{ user.username }}</span>
                <p v-if="user.status" class="status">{{ user.status }}</p>
            </div>
            <button class="close-btn" @click.stop="$emit('close')">
                <i class="fas fa-times"></i>
            </button>
        </div>

        <div class="profile-body">
            <div v-if="user.bio" class="bio-section">
                <h5>Bio</h5>
                <p>{{ user.bio }}</p>
            </div>

            <div class="stats-section">
                <h5>Mutual Interests</h5>
                <div class="stats-grid">
                    <div class="stat">
                        <span>{{ user.mutual_favorite_books?.length || 0 }}</span>
                        <label>Mutual Books</label>
                    </div>
                    <div class="stat">
                        <span>{{ user.mutual_favorite_genres?.length || 0 }}</span>
                        <label>Mutual Genres</label>
                    </div>
                </div>
            </div>

            <div class="card-actions">
                <slot name="actions"></slot>
                <button class="view-profile-btn" @click="$emit('view-profile', user.id)">
                    View Full Profile
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserProfileCard',

    props: {
        user: {
            type: Object,
            required: true
        },
        position: {
            type: Object,
            default: () => ({
                top: '0px',
                left: '0px'
            })
        }
    }
}
</script>

<style scoped>
.profile-card {
    position: fixed;
    width: 320px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    overflow: hidden;
}

.profile-header {
    padding: 20px;
    background: #f8fafc;
    border-bottom: 1px solid #e5e7eb;
    position: relative;
}

.profile-image {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ffffff;
    margin-bottom: 12px;
}

.profile-info h4 {
    font-size: 16px;
    font-weight: 600;
    color: #1f2937;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 4px;
}

.profile-info span {
    font-size: 14px;
    color: #6b7280;
    display: block;
    margin-bottom: 8px;
}

.status {
    font-size: 14px;
    color: #4b5563;
    padding: 8px 12px;
    background: #f3f4f6;
    border-radius: 6px;
    margin-top: 8px;
}

.close-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    color: #6b7280;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.close-btn:hover {
    background: rgba(0, 0, 0, 0.2);
    color: #1f2937;
}

.profile-body {
    padding: 20px;
}

.bio-section {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e5e7eb;
}

.bio-section h5,
.stats-section h5 {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #6b7280;
    margin-bottom: 8px;
}

.bio-section p {
    font-size: 14px;
    color: #4b5563;
    line-height: 1.5;
}

.stats-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 24px;
}

.stat {
    text-align: center;
    padding: 12px;
    background: #f3f4f6;
    border-radius: 8px;
    transition: background-color 0.2s ease;
}

.stat:hover {
    background: #e5e7eb;
}

.stat span {
    display: block;
    font-size: 24px;
    font-weight: 600;
    color: #6366f1;
    margin-bottom: 4px;
}

.stat label {
    font-size: 12px;
    color: #6b7280;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.card-actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.view-profile-btn {
    width: 100%;
    padding: 8px 16px;
    background: #6366f1;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.view-profile-btn:hover {
    background: #4f46e5;
}

@media (max-width: 640px) {
    .profile-card {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        border-radius: 0;
    }

    .profile-header {
        position: sticky;
        top: 0;
        background: #ffffff;
        z-index: 1;
    }

    .profile-body {
        height: calc(100% - 180px);
        overflow-y: auto;
    }
}
</style>