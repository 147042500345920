<template>
  <div class="description-content">
    <p class="main-description">{{ book.description }}</p>
    <div class="author-signature">
      <span>{{ book.author.name }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DescriptionTab',
  props: {
    book: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style scoped>
.description-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
}

.main-description {
  color: #666;
  line-height: 1.8;
  margin-bottom: 2rem;
}

.author-signature {
  text-align: right;
  color: #333;
  font-weight: 500;
  padding: 1rem 0;
}

@media (max-width: 768px) {
  .description-content {
    padding: 0.75rem;
  }
}

@media (max-width: 480px) {
  .description-content {
    padding: 0.5rem;
  }

  .main-description {
    margin-bottom: 1.5rem;
    font-size: 0.95rem;
    line-height: 1.6;
  }
}
</style>
