<template>
    <div class="book-meta">
        <div class="meta-group">
            <div class="meta-item">
                <span class="label">Author</span>
                <router-link v-if="bookData.author?.id" :to="`/authors/${bookData.author.id}`"
                    class="value author-link">
                    {{ bookData.author.name }}
                </router-link>
                <span v-else class="value">{{ bookData.author?.name }}</span>
            </div>
            <div class="meta-item">
                <span class="label">Year</span>
                <span class="value">{{ bookData.date_published }}</span>
            </div>
            <div class="meta-item">
                <span class="label">Format</span>
                <span class="value">{{ bookData.format }}</span>
            </div>
        </div>

        <div class="rating-reviews">
            <div class="rating">
                <span class="rating-value">{{ displayRating }}</span>
                <div class="stars">
                    <i v-for="n in 5" :key="n" class="fas fa-star"
                        :class="{ filled: n <= Math.floor(bookData.rating) }"></i>
                </div>
            </div>
            <span class="review-count">{{ bookData.total_ratings }} {{ reviewText }}</span>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed, watch, onMounted } from 'vue'
import api from '@/api/axios'

export default defineComponent({
    name: 'BookMetaInfo',
    props: {
        book: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const bookData = ref({ ...props.book })
        const isUpdating = ref(false)

        const displayRating = computed(() => {
            return Number(bookData.value.rating || 0).toFixed(1)
        })

        const reviewText = computed(() => {
            return bookData.value.total_ratings === 1 ? 'Review' : 'Reviews'
        })

        const refreshBookData = async () => {
            isUpdating.value = true
            try {
                const response = await api.get(`/books/${props.book.id}/`)
                if (response.data.success) {
                    bookData.value = response.data.book
                }
            } catch (error) {
                console.error('Failed to refresh book data:', error)
            } finally {
                isUpdating.value = false
            }
        }

        watch(
            () => props.book,
            (newBook) => {
                if (!isUpdating.value && newBook) {
                    bookData.value = { ...newBook }
                }
            },
            { deep: true, immediate: true }
        )

        onMounted(() => {
            if (props.book) {
                bookData.value = { ...props.book }
            }
        })

        return {
            bookData,
            displayRating,
            reviewText,
            refreshBookData
        }
    }
})
</script>

<style scoped>
.book-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.meta-group {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 3rem;
}

.meta-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.label {
    color: #666;
    font-size: 0.8rem;
}

.value {
    color: #333;
    font-size: 0.9rem;
    font-weight: 500;
}

.author-link {
    text-decoration: none;
    color: #282772;
    transition: color 0.2s ease;
    cursor: pointer;
}

.author-link:hover {
    color: #f27cf9;
    text-decoration: underline;
}

.rating-reviews {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
}

.rating {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.rating-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
}

.stars {
    display: flex;
    gap: 0.25rem;
    color: #ddd;
}

.stars .filled {
    color: #ffd700;
}

.review-count {
    color: #666;
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .meta-group {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;
    }

    .book-meta {
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
    }

    .rating-reviews {
        align-items: flex-start;
    }
}
</style>