import Cart from '@/views/shop/Cart.vue'
import CheckOut from '@/views/shop/CheckOut.vue'
import SelectPayment from '@/views/shop/SelectPayment.vue'
import VerifyPayment from '@/views/shop/VerifyPayment.vue'
import { cartGuard, orderGuard } from '../guards'

export default [
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
    meta: {
      requiresAuth: true,
      title: 'Shopping Cart',
      transition: 'slide-fade',
    },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckOut,
    meta: {
      requiresAuth: true,
      title: 'Checkout',
      transition: 'slide-fade',
    },
    beforeEnter: cartGuard,
  },
  {
    path: '/payment/:orderId',
    name: 'select-payment',
    component: SelectPayment,
    meta: {
      requiresAuth: true,
      title: 'Select Payment Method',
    },
    beforeEnter: orderGuard,
  },
  {
    path: '/payments/verify-khalti',
    name: 'verify-payment',
    component: VerifyPayment,
    meta: {
      requiresAuth: true,
      title: 'Verify Payment',
    },
  },
]
