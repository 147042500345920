<template>
  <div class="order-summary">
    <div class="summary-item">
      <span>Subtotal:</span>
      <span>{{ formatPrice(subtotal) }}</span>
    </div>
    <div class="summary-item">
      <span>Shipping:</span>
      <span>{{ formatPrice(shipping) }}</span>
    </div>
    <div class="summary-total">
      <span>Total:</span>
      <span>{{ formatPrice(total) }}</span>
    </div>
    <button class="checkout-button" :disabled="processing" @click="$emit('checkout')">
      {{ processing ? 'Processing...' : 'Proceed to Checkout' }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    subtotal: {
      type: Number,
      default: 0
    },
    shipping: {
      type: Number,
      default: 100
    },
    total: {
      type: Number,
      default: 0
    },
    processing: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    formatPrice(amount) {
      const numAmount = Number(amount);
      if (isNaN(numAmount)) return 'Rs 0.00';

      const formattedNumber = new Intl.NumberFormat('en-IN', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        useGrouping: true
      }).format(numAmount);

      return `Rs ${formattedNumber}`;
    }
  }
}
</script>

<style scoped>
.order-summary {
  width: 100%;
  max-width: 400px;
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.summary-total {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
  font-weight: bold;
}

.checkout-button {
  width: 100%;
  padding: 1rem;
  background: #ff69b4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1.5rem;
  font-weight: bold;
  font-size: 1.1rem;
  transition: background-color 0.2s;
}

.checkout-button:hover {
  background: #ff1493;
}

.checkout-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>