<template>
  <div class="search-fields">
    <input v-model="searchFilters.title" @input="emitSearch" type="text" placeholder="Search by title..."
      class="search-input" />
    <input v-model="searchFilters.author" @input="emitSearch" type="text" placeholder="Search by author..."
      class="search-input" />
  </div>
</template>

<script>
import { reactive, watch } from 'vue'

export default {
  name: "SearchFields",
  props: {
    filters: {
      type: Object,
      required: true,
    },
    handleSearch: {
      type: Function,
      required: true,
    },
  },

  setup(props) {
    const searchFilters = reactive({
      title: props.filters.title || '',
      author: props.filters.author || ''
    })

    // Watch for external filter changes
    watch(() => props.filters, (newFilters) => {
      searchFilters.title = newFilters.title || ''
      searchFilters.author = newFilters.author || ''
    }, { deep: true })

    const emitSearch = () => {
      props.handleSearch({
        ...props.filters,
        title: searchFilters.title,
        author: searchFilters.author
      })
    }

    return {
      searchFilters,
      emitSearch
    }
  }
}
</script>

<style scoped>
.search-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 2rem;
}

.search-input {
  padding: 0.75rem 1rem;
  border: 1px solid var(--border-color);
  background: #f8f8f8;
  border-radius: 8px;
  font-size: 0.9rem;
  width: 90%;
}

@media (max-width: 768px) {
  .search-fields {
    grid-template-columns: 1fr;
  }

  .search-input {
    width: 100%;
  }
}
</style>