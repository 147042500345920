<template>
  <div class="search-header">
    <h1>Search Books</h1>
    <div class="search-tabs">
      <button
        :class="['tab-btn', { active: searchType === 'title' }]"
        @click="$emit('update:searchType', 'title')"
      >
        Search by Title
      </button>
      <button
        :class="['tab-btn', { active: searchType === 'author' }]"
        @click="$emit('update:searchType', 'author')"
      >
        Search by Author
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchHeader',
  props: {
    searchType: {
      type: String,
      required: true,
    },
  },
  emits: ['update:searchType'],
}
</script>

<style scoped>
.search-header {
  margin-bottom: 2rem;
  text-align: center;
}

.search-header h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
}

.search-tabs {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.tab-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.tab-btn.active {
  background: var(--primary-color);
  color: white;
}

@media (max-width: 480px) {
  .search-tabs {
    flex-direction: column;
  }

  .tab-btn {
    width: 100%;
  }
}
</style>
