<template>
  <div class="ai-banner">
    <div class="animation-overlay">
      <i class="fas fa-robot floating-icon"></i>
    </div>

    <div class="banner-content">
      <div class="coming-soon">
        <i class="fas fa-sparkles pulse-animation"></i>
        <span>Coming Soon</span>
        <i class="fas fa-sparkles pulse-animation"></i>
      </div>

      <h2>Meet Your Personal Reading Assistant</h2>
      <p>Discover books that truly resonate with you through the power of AI</p>

      <div class="feature-highlights">
        <div class="highlight-item">
          <i class="fas fa-brain"></i>
          <span>Smart Recommendations</span>
        </div>
        <div class="highlight-item">
          <i class="fas fa-book-open"></i>
          <span>Personalized Experience</span>
        </div>
        <div class="highlight-item">
          <i class="fas fa-magic"></i>
          <span>Tailored To You</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ai-banner {
  background: linear-gradient(135deg, #555696 0%, #4a2988 50%, #2f278a 100%);
  padding: 4rem 1.6rem;
  position: relative;
  overflow: hidden;
  min-height: 320px;
  margin-bottom: 3.2rem;
}

.animation-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.1;
}

.floating-icon {
  font-size: 9.6rem; 
  color: white;
  animation: float 6s ease-in-out infinite;
}

.banner-content {
  position: relative;
  z-index: 1;
  max-width: 960px; 
  margin: 0 auto;
  text-align: center;
  color: white;
}

.coming-soon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  margin-bottom: 1.2rem;
  color: #c084fc;
  font-weight: 500;
}

h2 {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 1.2rem; 
  color: #fff;
  background: linear-gradient(to right, #fff, #c084fc);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

p {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 2.4rem;
  font-weight: 400;
}

.feature-highlights {
  display: flex;
  justify-content: center;
  gap: 2.4rem;
  margin-top: 1.6rem;
}

.highlight-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

.highlight-item i {
  font-size: 1.2rem;
  color: #c084fc;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-16px);
  }
}

@media (max-width: 768px) {
  .ai-banner {
    padding: 4rem 1.6rem;
    min-height: 240px;
  }

  .floating-icon {
    font-size: 7.2rem;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.9rem;
  }

  p {
    font-size: 0.75rem;
    margin-bottom: 1.8rem;
  }

  .feature-highlights {
    flex-direction: column;
    gap: 1.2rem;
  }

  .highlight-item i {
    font-size: 0.9rem;
  }

  .coming-soon {
    gap: 0.3rem;
    margin-bottom: 0.9rem;
  }
}
</style>