import store from '../store/auth'

export function guestGuard(to, from, next) {
  if (store.getters.isAuthenticated) {
    next('/home')
  } else {
    next()
  }
}

export function cartGuard(to, from, next) {
  const cart = store.getters.getCart
  if (!cart?.length) {
    next('/cart')
  } else {
    next()
  }
}

export function orderGuard(to, from, next) {
  if (!to.params.orderId) {
    next('/cart')
  } else {
    next()
  }
}
