<template>
  <div class="shopping-cart">
    <cart-header />

    <div v-if="loading" class="loading-state">
      <span class="loading-spinner"></span>
      Loading cart...
    </div>

    <div v-else-if="error" class="error-state">
      {{ error }}
      <button @click="fetchCartItems" class="retry-button">Retry</button>
    </div>

    <div v-else-if="cartItems.length === 0" class="empty-state">
      <p>Your cart is empty</p>
      <router-link to="/books" class="continue-shopping">Continue Shopping</router-link>
    </div>

    <div v-else>
      <cart-items :items="cartItems" @remove-item="handleRemoveItem" @selection-change="updateItemSelection"
        @type-change="handleTypeChange" @rental-period-change="handleRentalPeriodChange"
        @quantity-change="handleQuantityChange" />

      <div class="cart-footer-container">
        <shipping-address-selector v-model:shipping="selectedShipping" />

        <order-summary :subtotal="subtotal" :shipping="shipping" :total="total" :processing="processingCheckout"
          @checkout="checkout" />
      </div>
    </div>
  </div>
</template>

<script>
// External imports
import { useToast } from 'vue-toastification'

// API imports
import api from '@/api/axios'

// Component imports
import CartHeader from '@/components/cart/CartHeader.vue'
import CartItems from '@/components/cart/CartItems.vue'
import ShippingAddressSelector from '@/components/cart/ShippingAddressSelector.vue'
import OrderSummary from '@/components/cart/OrderSummary.vue'


export default {
  name: 'ShoppingCart',

  components: {
    CartHeader,
    CartItems,
    ShippingAddressSelector,
    OrderSummary,
  },

  setup() {
    const toast = useToast()
    return { toast }
  },

  data() {
    return {
      cartItems: [],
      selectedShipping: '',
      shipping: 100.0,
      loading: false,
      processingCheckout: false,
      error: null,
    }
  },

  computed: {
    subtotal() {
      return this.cartItems.reduce((sum, item) => {
        if (!item.selected) return sum;
        const itemPrice = parseFloat(item.book.price);
        const itemQuantity = parseInt(item.quantity);
        return sum + (itemPrice * itemQuantity);
      }, 0);
    },

    total() {
      return this.subtotal + (this.hasSelectedItems ? this.shipping : 0);
    },

    hasSelectedItems() {
      return this.cartItems.some(item => item.selected)
    },
  },

  methods: {
    async fetchCartItems() {
      this.loading = true
      this.error = null

      try {
        const response = await api.get('/cart/')
        if (response.data?.data) {
          this.cartItems = response.data.data.map(item => ({
            ...item,
            selected: false, // Add selected property
          }))
        } else {
          throw new Error('Invalid response format')
        }
      } catch (err) {
        this.error = 'Failed to load cart items. Please try again.'
        console.error('Error fetching cart items:', err)
        this.cartItems = []
      } finally {
        this.loading = false
      }
    },

    async updateCartItem(itemId, updateData) {
      try {
        const response = await api.put(`/cart/${itemId}/edit`, updateData)

        if (!response.data?.success) {
          throw new Error('Failed to update cart item')
        }
      } catch (err) {
        this.$toast?.error('Failed to update cart item. Please try again.')
        console.error('Error updating cart item:', err)
      }
    },

    async handleQuantityChange({ itemId, quantity, purchase_type }) {
      try {
        await this.updateCartItem(itemId, {
          quantity: quantity,
          purchase_type: purchase_type,
          rental_period_id: purchase_type === 'rent' ? 1 : null
        });

        const item = this.cartItems.find(item => item.id === itemId);
        if (item) {
          item.quantity = quantity;
        }
      } catch (err) {
        this.toast.error('Failed to update quantity. Please try again.');  // Updated this
        console.error('Error updating quantity:', err);
        await this.fetchCartItems();
      }
    },

    async handleRemoveItem(itemId) {
      try {
        const response = await api.delete(`/cart/${itemId}/remove`)

        if (response.data?.success) {
          this.cartItems = this.cartItems.filter(item => item.id !== itemId)
          this.toast.success('Item removed from cart')  // Updated this
        } else {
          throw new Error(response.data?.message || 'Failed to remove item')
        }
      } catch (err) {
        this.toast.error('Failed to remove item from cart. Please try again.')  // Updated this
        console.error('Error removing item:', err)
      }
    },

    async handleRentalPeriodChange({ itemId, rental_period_id }) {
      const item = this.cartItems.find(item => item.id === itemId)
      if (!item) return

      try {
        await this.updateCartItem(itemId, {
          purchase_type: 'rent',
          rental_period_id,
          quantity: 1,
        })
      } catch (err) {
        this.toast.error('Failed to update rental period. Please try again.')  // Updated this
        console.error('Error updating rental period:', err)
      }
    },

    async incrementQuantity(item) {
      if (item.quantity >= 99) return

      const updateData = {
        quantity: item.quantity + 1,
        purchase_type: item.type,
        rental_period_id:
          item.type === 'rent'
            ? parseInt(item.rentalPeriod) === 15
              ? 1
              : parseInt(item.rentalPeriod) === 30
                ? 2
                : 3
            : null,
      }

      await this.updateCartItem(item.id, updateData)
    },

    async decrementQuantity(item) {
      if (item.quantity <= 1) return

      const updateData = {
        quantity: item.quantity - 1,
        purchase_type: item.type,
        rental_period_id:
          item.type === 'rent'
            ? parseInt(item.rentalPeriod) === 15
              ? 1
              : parseInt(item.rentalPeriod) === 30
                ? 2
                : 3
            : null,
      }

      await this.updateCartItem(item.id, updateData)
    },

    updateItemSelection({ itemId, selected }) {
      const item = this.cartItems.find(item => item.id === itemId)
      if (item) {
        item.selected = selected
      }
    },

    async checkout() {
      if (!this.hasSelectedItems) {
        this.toast.error('Please select at least one item to checkout')
        return
      }

      if (!this.selectedShipping) {
        this.toast.error('Please select a shipping address')
        return
      }

      this.processingCheckout = true

      try {
        const selectedItemIds = this.cartItems
          .filter(item => item.selected)
          .map(item => item.id)

        const response = await api.post('/order/create-order/', {
          item_ids: selectedItemIds,
          shipping_address_id: this.selectedShipping
        })

        if (response.data?.success) {
          const orderId = response.data.order_id
          if (!orderId) {
            throw new Error('No order ID received')
          }
          await this.$router.push(`/payment/${orderId}`)
        } else {
          throw new Error(response.data?.message || 'Checkout failed')
        }
      } catch (err) {
        let errorMessage = 'Failed to complete checkout. Please try again.'

        if (err.response?.data?.message) {
          errorMessage = err.response.data.message
        } else if (err.response?.data?.error) {
          errorMessage = err.response.data.error
        }

        this.toast.error(errorMessage)  // Using this.toast instead of this.$toast
        console.error('Error during checkout:', err)
      } finally {
        this.processingCheckout = false
      }
    },

    async handleTypeChange({ itemId, purchase_type, rental_period_id }) {
      await this.updateCartItem(itemId, {
        purchase_type,
        rental_period_id,
        quantity: 1, // Reset quantity to 1 for rentals
      })
    },
  },

  watch: {
    selectedShipping(addressId) {
      this.shipping = 100.0
    },
  },

  async created() {
    await this.fetchCartItems()
  },
}
</script>

<style scoped>
.shopping-cart {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  font-family: system-ui, -apple-system, sans-serif;
}

.cart-footer-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 768px) {
  .shopping-cart {
    padding: 2rem;
  }

  .cart-footer-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.loading-state,
.error-state,
.empty-state {
  text-align: center;
  padding: 2rem;
  margin: 2rem 0;
  background: #f8f9fa;
  border-radius: 8px;
}

.loading-spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

.retry-button,
.continue-shopping {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #f27cf9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.2s;
}

.retry-button:hover,
.continue-shopping:hover {
  background: #f27cf9;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Toast notifications styling */
.toast-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
}

.toast {
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  color: white;
  animation: slideIn 0.3s ease-out;
}

.toast-success {
  background: #2ecc71;
}

.toast-error {
  background: #e74c3c;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
