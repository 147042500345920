<template>
  <div class="book-gallery">
    <div class="main-image">
      <img :src="imageUrl || '/placeholder.jpg'" :alt="bookTitle" />
      <div v-if="isBestSeller" class="best-seller-badge">Best Seller</div>
    </div>
    <div class="image-dots">
      <span
        v-for="n in 5"
        :key="n"
        class="dot"
        :class="{ active: n === 1 }"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookGallery',
  props: {
    imageUrl: String,
    bookTitle: String,
    isBestSeller: Boolean,
  },
}
</script>

<style scoped>
.book-gallery {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background: white;
  aspect-ratio: 3/4;
  width: 100%;
  max-width: 400px;
}

.main-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.best-seller-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #f27cf9;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.75rem;
}

.image-dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  width: 100%;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ddd;
  cursor: pointer;
}

.dot.active {
  background: #f27cf9;
}

@media (max-width: 1200px) {
  .book-gallery {
    padding: 1rem 0;
  }

  .main-image {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }
}

@media (max-width: 992px) {
  .main-image {
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .book-gallery {
    padding: 0.5rem 0;
  }

  .main-image {
    max-width: 280px;
  }
}

@media (max-width: 480px) {
  .main-image {
    max-width: 240px;
  }

  .best-seller-badge {
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
  }
}
</style>
