import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/auth'
import routes from './routes'
import { handleRouteTransitions } from './utils'

// Initialize auth state
const initializeAuth = async () => {
  const token =
    localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
  if (token && !store.getters.isAuthenticated) {
    store.commit('SET_AUTH_TOKEN', {
      token,
      remember: !!localStorage.getItem('accessToken'),
    })
    try {
      await store.dispatch('fetchUserProfile')
    } catch (error) {
      console.error('Error initializing auth:', error)
    }
  }
}

// Create router instance
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition
    if (to.hash) return { el: to.hash, behavior: 'smooth' }
    return { top: 0, behavior: 'smooth' }
  },
})

// Global navigation guard
router.beforeEach(async (to, from, next) => {
  if (window.$loadingBar) {
    window.$loadingBar.start()
  }

  document.title = `Booksville - ${to.meta.title || 'Welcome'}`

  await initializeAuth()

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isAuthenticated = store.getters.isAuthenticated

  if (requiresAuth && !isAuthenticated) {
    next(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
    return
  }

  const storedToken =
    localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
  const currentToken = store.getters.getToken

  if (storedToken !== currentToken) {
    await store.dispatch('logout')
    next('/login')
    return
  }

  if (isAuthenticated && !store.getters.getUserProfile) {
    try {
      await store.dispatch('fetchUserProfile')
    } catch (error) {
      console.error('Error fetching user profile:', error)
    }
  }

  if ((to.name === 'login' || to.name === 'signup') && isAuthenticated) {
    next('/home')
    return
  }

  if (to.meta.backRoute) {
    const validPreviousRoute =
      from.name && !['login', 'signup'].includes(from.name)
    to.meta.previousRoute = validPreviousRoute ? from.path : to.meta.backRoute
  }

  if (
    window.innerWidth <= 768 &&
    to.matched.some(record => record.meta.backRoute)
  ) {
    sessionStorage.setItem('lastAccountSection', to.path)
  }

  handleRouteTransitions(to, from)
  next()
})

router.afterEach(to => {
  if (to.path && !to.path.startsWith('/account')) {
    sessionStorage.removeItem('lastAccountSection')
  }

  if (window.$loadingBar) {
    window.$loadingBar.finish()
  }
})

router.onError(error => {
  console.error('Router error:', error)
  if (window.$loadingBar) {
    window.$loadingBar.error()
  }
})

export default router
