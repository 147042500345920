<template>
    <div class="review-form">
        <!-- Loading State -->
        <div v-if="isLoading" class="loading-state">
            <p>Loading...</p>
        </div>

        <!-- Error State -->
        <div v-else-if="errorMessage" class="error-state">
            <p>{{ errorMessage }}</p>
            <button @click="errorMessage = ''" class="retry-button">Dismiss</button>
        </div>

        <!-- Review Form (New or Edit) -->
        <template v-else-if="!hasReview || isEditing">
            <h2 class="form-title">{{ isEditing ? 'Edit Review' : 'Rate this Book' }}</h2>

            <div class="review-rating">
                <span class="rating-label">How would you rate this book?</span>
                <div class="rating-stars">
                    <i v-for="n in 5" :key="n" :class="[
                        'fa-star',
                        (n <= formData.rating || n <= hoverRating) ? 'fas' : 'far',
                        { 'hovered': n <= hoverRating }
                    ]" @click="setRating(n)" @mouseenter="handleHover(n)" @mouseleave="handleHoverLeave()"></i>
                </div>
                <span class="rating-text">{{ getRatingText }}</span>
            </div>

            <div class="form-group">
                <label>Review Title</label>
                <input type="text" v-model="formData.title" placeholder="Give your review a title"
                    :class="{ 'has-content': formData.title }" />
            </div>

            <div class="form-group">
                <label>Your Review</label>
                <textarea v-model="formData.content" placeholder="What did you think about this book?"
                    :class="{ 'has-content': formData.content }"></textarea>
            </div>

            <div class="spoiler-checkbox">
                <input type="checkbox" id="spoiler" v-model="formData.contains_spoiler" />
                <label for="spoiler">This review contains spoilers</label>
            </div>

            <div class="form-actions">
                <button v-if="isEditing" @click="cancelEdit" class="cancel-button">
                    Cancel
                </button>
                <button @click="isEditing ? updateReview() : submitReview()" class="submit-review"
                    :disabled="!isFormValid || isSubmitting">
                    {{ getSubmitButtonText }}
                </button>
            </div>
        </template>

        <!-- Already Reviewed State -->
        <div v-else class="already-reviewed">
            <h2 class="form-title">Your Review</h2>
            <div v-if="existingReview" class="existing-review">
                <div class="rating-stars">
                    <i v-for="n in 5" :key="n"
                        :class="['fa-star', n <= parseFloat(existingReview.rating) ? 'fas' : 'far']"></i>
                </div>
                <h3>{{ existingReview.title }}</h3>
                <p>{{ existingReview.content }}</p>
                <div v-if="existingReview.contains_spoiler" class="spoiler-tag">
                    Contains spoilers
                </div>
                <div class="review-actions">
                    <button @click="startEdit" class="edit-button">
                        Edit Review
                    </button>
                    <button @click="confirmDelete" class="delete-button">
                        Delete Review
                    </button>
                </div>
            </div>
        </div>

        <!-- Delete Confirmation Modal -->
        <div v-if="showDeleteModal" class="modal-overlay">
            <div class="modal-content">
                <h3>Delete Review</h3>
                <p>Are you sure you want to delete your review? This action cannot be undone.</p>
                <div class="modal-actions">
                    <button @click="showDeleteModal = false" class="cancel-button">
                        Cancel
                    </button>
                    <button @click="deleteReview" class="delete-button">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, defineComponent } from 'vue'
import api from '@/api/axios'

export default defineComponent({
    name: 'ReviewForm',
    props: {
        bookId: {
            type: [String, Number],
            required: true
        }
    },
    emits: ['submit-success', 'review-updated', 'review-deleted'],
    setup(props, { emit }) {
        const isLoading = ref(true)
        const hasReview = ref(false)
        const existingReview = ref(null)
        const isEditing = ref(false)
        const showDeleteModal = ref(false)
        const isSubmitting = ref(false)
        const errorMessage = ref('')
        const hoverRating = ref(0)

        const formData = ref({
            rating: 0,
            title: '',
            content: '',
            contains_spoiler: false
        })

        const getRatingText = computed(() => {
            const rating = hoverRating.value || formData.value.rating
            switch (rating) {
                case 1: return 'Poor'
                case 2: return 'Fair'
                case 3: return 'Good'
                case 4: return 'Very Good'
                case 5: return 'Excellent'
                default: return 'Select your rating'
            }
        })

        const isFormValid = computed(() => {
            return formData.value.rating > 0 &&
                formData.value.title.trim() !== '' &&
                formData.value.content.trim() !== ''
        })

        const getSubmitButtonText = computed(() => {
            if (isSubmitting.value) return 'Submitting...'
            return isEditing.value ? 'Update Review' : 'Submit Review'
        })

        const checkExistingReview = async () => {
            try {
                const response = await api.get(`/books/${props.bookId}/has-reviewed/`)
                hasReview.value = response.data.has_review
                existingReview.value = response.data.review
            } catch (error) {
                errorMessage.value = error.response?.data?.message ||
                    'Failed to check review status. Please try again.'
            } finally {
                isLoading.value = false
            }
        }

        const startEdit = () => {
            formData.value = {
                rating: parseFloat(existingReview.value.rating),
                title: existingReview.value.title,
                content: existingReview.value.content,
                contains_spoiler: existingReview.value.contains_spoiler
            }
            isEditing.value = true
        }

        const cancelEdit = () => {
            isEditing.value = false
            formData.value = {
                rating: 0,
                title: '',
                content: '',
                contains_spoiler: false
            }
        }

        const updateReview = async () => {
            if (!isFormValid.value || isSubmitting.value) return

            isSubmitting.value = true
            errorMessage.value = ''

            try {
                const response = await api.put(`/reviews/${existingReview.value.id}/`, {
                    rating: String(formData.value.rating),
                    title: formData.value.title,
                    content: formData.value.content,
                    contains_spoiler: formData.value.contains_spoiler
                })

                if (response.data.success) {
                    await checkExistingReview()
                    isEditing.value = false
                    emit('review-updated')
                }
            } catch (error) {
                errorMessage.value = error.response?.data?.message ||
                    'Failed to update review. Please try again.'
            } finally {
                isSubmitting.value = false
            }
        }

        const confirmDelete = () => {
            showDeleteModal.value = true
        }

        const deleteReview = async () => {
            if (isSubmitting.value) return

            isSubmitting.value = true
            errorMessage.value = ''

            try {
                await api.delete(`/reviews/${existingReview.value.id}/`)
                showDeleteModal.value = false
                hasReview.value = false
                existingReview.value = null
                formData.value = {
                    rating: 0,
                    title: '',
                    content: '',
                    contains_spoiler: false
                }
                emit('review-deleted')
            } catch (error) {
                errorMessage.value = error.response?.data?.message ||
                    'Failed to delete review. Please try again.'
                showDeleteModal.value = false
            } finally {
                isSubmitting.value = false
            }
        }


        const setRating = (value) => {
            formData.value.rating = value
        }

        const handleHover = (value) => {
            hoverRating.value = value
        }

        const handleHoverLeave = () => {
            hoverRating.value = 0
        }

        const submitReview = async () => {
            if (!isFormValid.value || isSubmitting.value) return

            isSubmitting.value = true
            errorMessage.value = ''

            try {
                const response = await api.post(`/books/${props.bookId}/review/`, {
                    rating: String(formData.value.rating),
                    title: formData.value.title,
                    content: formData.value.content,
                    contains_spoiler: formData.value.contains_spoiler
                })

                if (response.data.success) {
                    hasReview.value = true
                    await checkExistingReview()
                    emit('submit-success')
                }
            } catch (error) {
                errorMessage.value = error.response?.data?.message ||
                    'Failed to submit review. Please try again.'
            } finally {
                isSubmitting.value = false
            }
        }

        // Initialize
        checkExistingReview()

        return {
            isLoading,
            hasReview,
            existingReview,
            isEditing,
            confirmDelete,
            showDeleteModal,
            formData,
            hoverRating,
            isSubmitting,
            errorMessage,
            getRatingText,
            isFormValid,
            getSubmitButtonText,
            startEdit,
            cancelEdit,
            updateReview,
            deleteReview,
            setRating,
            handleHover,
            handleHoverLeave,
            submitReview
        }
    }
})
</script>

<style lang="css" scoped>
.review-form {
    width: 100%;
    max-width: 600px;
    margin: 1rem auto;
    padding: 2rem 1.5rem;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.06);
    position: relative;
}

.loading-state,
.error-state {
    text-align: center;
    padding: 2rem 0;
}

.error-state {
    color: #dc3545;
}

.existing-review {
    margin-top: 1.5rem;
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 12px;
}

.review-message {
    color: #666;
    text-align: center;
    margin-bottom: 1rem;
}

.existing-review h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 1rem 0 0.5rem;
    color: #333;
}

.existing-review p {
    color: #666;
    line-height: 1.5;
    margin-bottom: 1rem;
}

.form-title {
    font-size: clamp(1.25rem, 4vw, 1.8rem);
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 1.5rem;
    text-align: center;
}

.review-rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
}

.rating-label {
    font-size: clamp(0.9rem, 3vw, 1.1rem);
    color: #333;
    margin-bottom: 0.75rem;
    font-weight: 500;
    text-align: center;
}

.rating-stars {
    display: flex;
    gap: clamp(0.4rem, 2vw, 0.8rem);
    margin-bottom: 0.5rem;
    justify-content: center;
}

.rating-stars i {
    cursor: pointer;
    font-size: clamp(1.5rem, 5vw, 2rem);
    transition: all 0.3s ease;
}

.rating-stars i.fas {
    color: #f27cf9;
}

.rating-stars i.far {
    color: #e0e0e0;
}

.rating-stars i:hover {
    transform: scale(1.15);
}

.rating-text {
    font-size: clamp(0.8rem, 2.5vw, 0.9rem);
    color: #666;
    margin-top: 0.5rem;
    font-weight: 500;
}

.form-group {
    margin-bottom: 1.25rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
    font-weight: 500;
    font-size: clamp(0.85rem, 2.5vw, 0.95rem);
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: clamp(0.75rem, 2vw, 1rem);
    border: 2px solid #eee;
    border-radius: 12px;
    font-size: clamp(0.85rem, 2.5vw, 0.95rem);
    transition: all 0.3s ease;
    background: #f8f9fa;
}

.form-group textarea {
    min-height: 120px;
    resize: vertical;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group input.has-content,
.form-group textarea.has-content {
    border-color: #f27cf9;
    background: #fff;
    outline: none;
}

.spoiler-checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    padding: 0.5rem 0;
}

.spoiler-checkbox input[type="checkbox"] {
    width: 1.2rem;
    height: 1.2rem;
    accent-color: #f27cf9;
}

.spoiler-checkbox label {
    font-size: clamp(0.85rem, 2.5vw, 0.95rem);
    color: #333;
    cursor: pointer;
}

.spoiler-tag {
    display: inline-block;
    background: #ff9800;
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-size: 0.8rem;
    margin-bottom: 1rem;
}

.form-actions,
.review-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.submit-review,
.edit-button,
.delete-button,
.cancel-button,
.retry-button {
    padding: clamp(0.75rem, 2vw, 1rem);
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: clamp(0.9rem, 2.5vw, 1rem);
    font-weight: 600;
    transition: all 0.3s ease;
    transform: translateY(0);
    flex: 1;
}

.submit-review {
    background: #f27cf9;
    color: white;
}

.submit-review:not(:disabled):hover {
    background: #e065e6;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(242, 124, 249, 0.3);
}

.submit-review:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.edit-button {
    background: #4CAF50;
    color: white;
}

.edit-button:hover {
    background: #43a047;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(76, 175, 80, 0.3);
}

.delete-button {
    background: #f44336;
    color: white;
}

.delete-button:hover {
    background: #e53935;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(244, 67, 54, 0.3);
}

.cancel-button {
    background: #9e9e9e;
    color: white;
}

.cancel-button:hover {
    background: #757575;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(158, 158, 158, 0.3);
}

.retry-button {
    background: #2196F3;
    color: white;
}

.retry-button:hover {
    background: #1976D2;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(33, 150, 243, 0.3);
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    max-width: 400px;
    width: 90%;
    text-align: center;
    position: relative;
    z-index: 100000;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.modal-content h3 {
    margin-bottom: 1rem;
    color: #333;
    font-size: 1.25rem;
}

.modal-content p {
    margin-bottom: 1.5rem;
    color: #666;
}

.modal-actions {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

@media (max-width: 480px) {
    .review-form {
        margin: 0.5rem;
        padding: 1.25rem 1rem;
    }

    .rating-stars {
        padding: 0.5rem 0;
    }

    .form-actions,
    .review-actions,
    .modal-actions {
        flex-direction: column;
    }
}

@media (max-width: 320px) {
    .review-form {
        padding: 1rem 0.75rem;
    }

    .rating-stars i {
        font-size: 1.5rem;
    }
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.modal-content {
    animation: modalFadeIn 0.2s ease-out;
}

</style>