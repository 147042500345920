<template>
  <section class="authors-section">
    <h2 class="section-title">FAVORITE AUTHORS</h2>
    <div class="authors-grid">
      <router-link v-for="author in authors" :key="author.id" :to="`/authors/${author.id}`" class="author-card">
        <img :src="author.cover_image" :alt="author.name" />
        <div class="author-info">
          <h3>{{ author.name }}</h3>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AuthorsSection',
  props: {
    authors: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.authors-section {
  margin: 3rem 0;
  padding: 4rem 3rem 4rem 3rem;
  background-color: #f8f9fa;
}

.authors-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.author-card {
  text-decoration: none;
  background: #282772;
  border-radius: 12px;
  padding: 1.5rem;
  text-align: center;
  color: white;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.author-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.author-card img {
  width: 160px;
  height: 160px;
  border-radius: 0%;
  margin-bottom: 1rem;
  object-fit: cover;
}

.author-info {
  margin-top: 1rem;
}

.author-info h3 {
  margin: 0;
  font-size: 1.2rem;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .authors-section {
    padding: 2rem 1rem;
  }

  .authors-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 1rem;
  }

  .author-card {
    min-height: 250px;
    padding: 1rem;
  }

  .author-card img {
    width: 140px;
    height: 140px;
  }

  .author-info h3 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .authors-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .author-card img {
    width: 120px;
    height: 120px;
  }
}
</style>
