<template>
  <div class="payment-selection">
    <h1>Select Payment Method</h1>

    <div v-if="loading" class="loading">
      <i class="fas fa-spinner fa-spin"></i>
      Loading...
    </div>

    <div v-else-if="error" class="error">
      <i class="fas fa-exclamation-circle"></i>
      {{ error }}
    </div>

    <div v-else class="payment-container">
      <div class="order-summary">
        <h2>Order Summary</h2>
        <p>Order ID: {{ orderId }}</p>
        <p>Total Amount: Rs {{ order?.total_amount }}</p>

        <div class="items-summary">
          <h3>Items</h3>
          <div v-for="item in order?.items" :key="item.book.id" class="item">
            <span>{{ item.book.title }}</span>
            <span>Rs {{ item.price }} x {{ item.quantity }}</span>
          </div>
        </div>

        <div class="shipping-info">
          <h3>Shipping To</h3>
          <p>{{ order?.shipping_address.full_name }}</p>
          <p>{{ order?.shipping_address.address_name }}</p>
        </div>
      </div>

      <div class="payment-methods">
        <h2>Payment Methods</h2>
        <div class="methods-grid">
          <div v-for="method in paymentMethods" :key="method.id" class="method-card"
            :class="{ 'selected': selectedMethod === method.id }" @click="selectMethod(method.id)">
            <h3>{{ method.name }}</h3>
            <p>{{ method.description }}</p>
          </div>
        </div>
      </div>

      <button @click="proceedToPayment" class="proceed-button" :disabled="!selectedMethod || processingPayment">
        {{ processingPayment ? 'Processing...' : 'Proceed to Payment' }}
      </button>
    </div>
  </div>
</template>

<script>
import api from '@/api/axios'
import { useToast } from "vue-toastification"

export default {
  name: 'SelectPayment',

  setup() {
    const toast = useToast()
    return { toast }
  },

  data() {
    return {
      orderId: null,
      order: null,
      loading: false,
      error: null,
      selectedMethod: null,
      processingPayment: false,
      paymentMethods: [
        {
          id: 'khalti',
          name: 'Khalti',
          description: 'Pay using your Khalti wallet'
        }
      ]
    }
  },

  methods: {
    async fetchOrder() {
      try {
        this.loading = true
        const response = await api.get(`/order/${this.orderId}/`)
        if (response.data.success) {
          this.order = response.data.order
        }
      } catch (err) {
        this.error = 'Failed to load order details'
        this.toast.error(this.error)
      } finally {
        this.loading = false
      }
    },

    selectMethod(methodId) {
      this.selectedMethod = methodId
    },

    async proceedToPayment() {
      if (!this.selectedMethod) return

      this.processingPayment = true
      try {
        const response = await api.post('/payments/create-payment/', {
          order: this.orderId,
          amount: this.order.total_amount.toString(),
          payment_method: "KHALTI"
        })

        if (response.data?.payment_url) {
          window.location.href = response.data.payment_url
        } else {
          throw new Error('Invalid payment URL')
        }
      } catch (err) {
        this.toast.error(err.message || 'Failed to initiate payment')
      } finally {
        this.processingPayment = false
      }
    }
  },

  created() {
    this.orderId = this.$route.params.orderId
    if (!this.orderId) {
      this.toast.error('Invalid order ID')
      this.$router.push('/cart')
    } else {
      this.fetchOrder()
    }
  }
}
</script>

<style scoped>
.payment-selection {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.loading,
.error {
  text-align: center;
  padding: 40px;
  color: #6c757d;
}

.loading i,
.error i {
  font-size: 24px;
  margin-right: 8px;
}

.error {
  color: #dc3545;
}

.payment-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.order-summary {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

.order-summary h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}

.items-summary {
  margin: 16px 0;
  padding-top: 16px;
  border-top: 1px solid #dee2e6;
}

.items-summary h3,
.shipping-info h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  color: #495057;
}

.shipping-info {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #dee2e6;
}

.shipping-info p {
  color: #495057;
  margin: 4px 0;
}

.methods-grid {
  display: grid;
  gap: 16px;
}

.method-card {
  border: 1px solid #dee2e6;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.method-card:hover {
  border-color: #0d6efd;
  background-color: #f8f9fa;
}

.method-card.selected {
  border-color: #0d6efd;
  background-color: #f1f8ff;
}

.method-card h3 {
  font-weight: 500;
  margin-bottom: 4px;
}

.method-card p {
  color: #6c757d;
  font-size: 14px;
}

.proceed-button {
  width: 100%;
  background-color: #0d6efd;
  color: white;
  padding: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.proceed-button:hover:not(:disabled) {
  background-color: #0b5ed7;
}

.proceed-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

@media (max-width: 640px) {
  .payment-selection {
    padding: 16px;
  }

  h1 {
    font-size: 20px;
  }

  .order-summary,
  .method-card {
    padding: 16px;
  }
}
</style>