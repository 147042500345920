<template>
  <div class="reading-challenge">
    <div class="challenge-header">
      <h2>2024 Reading Challenge</h2>
      <div class="stats">
        <span class="current">{{ progress.current }}</span>
        <span class="separator">/</span>
        <span class="goal">{{ progress.goal }}</span>
      </div>
    </div>

    <div class="progress-container">
      <div class="progress-bar"></div>
      <span class="percentage">{{ progressPercentage }}%</span>
    </div>

    <div class="challenge-stats">
      <div class="stat">
        <i class="fas fa-clock"></i>
        <div class="stat-info">
          <span class="label">Time Left</span>
          <span class="value">{{ daysLeft }} days</span>
        </div>
      </div>
      <div class="stat">
        <i class="fas fa-book"></i>
        <div class="stat-info">
          <span class="label">Books to Go</span>
          <span class="value">{{ booksToGo }}</span>
        </div>
      </div>
    </div>

    <button class="update-btn" @click="$emit('update-progress')">
      <i class="fas fa-plus"></i>
      Update Progress
    </button>
  </div>
</template>

<script>
export default {
  name: 'ReadingChallenge',
  props: {
    progress: {
      type: Object,
      required: true,
      default: () => ({
        current: 0,
        goal: 50,
      }),
    },
  },
  computed: {
    progressPercentage() {
      return Math.round((this.progress.current / this.progress.goal) * 100)
    },
    booksToGo() {
      return Math.max(0, this.progress.goal - this.progress.current)
    },
    daysLeft() {
      const now = new Date()
      const endOfYear = new Date(now.getFullYear(), 11, 31)
      const diffTime = Math.abs(endOfYear - now)
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    },
  },
}
</script>

<style scoped>
.reading-challenge {
  padding: 1.5rem;
  background: linear-gradient(
    135deg,
    rgba(138, 43, 226, 0.05) 0%,
    rgba(255, 105, 180, 0.05) 100%
  );
  border-radius: 16px;
}

.challenge-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

.challenge-header h2 {
  font-size: 1.125rem;
  font-weight: 600;
  background: linear-gradient(
    135deg,
    var(--primary-purple),
    var(--primary-pink)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stats {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
}

.current {
  color: var(--primary-purple);
  font-size: 1.25rem;
}

.separator {
  color: var(--text-gray);
  font-size: 1.25rem;
}

.goal {
  color: var(--text-gray);
  font-size: 1.25rem;
}

.progress-container {
  margin-bottom: 1.5rem;
}

.progress-bar {
  height: 8px;
  background-color: var(--bg-light);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.progress {
  height: 100%;
  background: linear-gradient(
    to right,
    var(--primary-purple),
    var(--primary-pink)
  );
  transition: width 0.3s ease;
}

.percentage {
  font-size: 0.875rem;
  color: var(--text-gray);
  display: block;
  text-align: right;
}

.challenge-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.stat {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  background-color: var(--white);
  border-radius: 12px;
  transition: transform 0.2s ease;
}

.stat:hover {
  transform: translateY(-2px);
}

.stat i {
  font-size: 1.25rem;
  color: var(--primary-purple);
}

.stat-info {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 0.75rem;
  color: var(--text-gray);
}

.value {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-dark);
}

.update-btn {
  width: 100%;
  padding: 0.875rem;
  border-radius: 8px;
  background-color: var(--primary-purple);
  color: var(--white);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.update-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(138, 43, 226, 0.15);
}

.update-btn:active {
  transform: translateY(0);
}
</style>
