<template>
  <div class="stat-card">
    <div :class="['stat-icon', color]">
      <i :class="['fas', icon]"></i>
    </div>
    <div class="stat-info">
      <div class="number">{{ number }}</div>
      <div class="label">{{ label }}</div>
      <div v-if="growth" :class="['growth', growthType]">
        <i :class="['fas', growthIcon]"></i>
        {{ growth }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatsCard',
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'purple',
    },
    number: {
      type: [Number, String],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    growth: {
      type: Number,
      default: null,
    },
  },
  computed: {
    growthType() {
      return this.growth > 0 ? 'positive' : 'negative'
    },
    growthIcon() {
      return this.growth > 0 ? 'fa-arrow-up' : 'fa-arrow-down'
    },
  },
}
</script>

<style scoped>
.stat-card {
  background-color: var(--white);
  border-radius: 16px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.25rem;
  box-shadow: var(--shadow-sm);
  transition: all 0.3s ease;
  border: 1px solid var(--border-color);
}

.stat-card:hover {
  transform: translateY(-4px);
  box-shadow: var(--shadow-md);
  border-color: transparent;
}

.stat-icon {
  width: 56px;
  height: 56px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.stat-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

.stat-card:hover .stat-icon::before {
  opacity: 0.3;
}

.stat-icon.purple {
  background: linear-gradient(135deg, var(--primary-purple) 0%, #b44dff 100%);
}

.stat-icon.pink {
  background: linear-gradient(135deg, var(--primary-pink) 0%, #ff99cc 100%);
}

.stat-icon.blue {
  background: linear-gradient(135deg, var(--primary-blue) 0%, #739fff 100%);
}

.stat-icon i {
  font-size: 24px;
  color: var(--white);
  position: relative;
  z-index: 1;
}

.stat-info .number {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--text-dark);
  line-height: 1.2;
}

.stat-info .label {
  font-size: 0.875rem;
  color: var(--text-gray);
  margin-top: 0.25rem;
}

.growth {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  width: fit-content;
}

.growth.positive {
  color: var(--success-green);
  background-color: rgba(16, 185, 129, 0.1);
}

.growth.negative {
  color: #ef4444;
  background-color: rgba(239, 68, 68, 0.1);
}
</style>
