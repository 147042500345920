<template>
  <div class="shipping-section">
    <h3 class="shipping-title">Shipping Address</h3>
    
    <div class="shipping-content">
      <div v-if="loading" class="loading-state">
        <span class="loading-spinner"></span>
        Loading addresses...
      </div>
      
      <div v-else-if="error" class="error-state">
        {{ error }}
        <button @click="fetchAddresses" class="retry-button">Retry</button>
      </div>
      
      <div v-else class="select-wrapper">
        <select
          :value="shipping"
          @input="$emit('update:shipping', $event.target.value)"
          :disabled="loading"
          class="select-address"
        >
          <option value="">Select shipping address</option>
          <option 
            v-for="address in addresses" 
            :key="address.id"
            :value="address.id"
          >
            {{ formatAddress(address) }}
          </option>
        </select>
        
        <button @click="goToShippingManagement" class="new-address-button">
          <i class="fas fa-plus"></i>
          New Shipping Address
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/axios'
import { useRouter } from 'vue-router'

export default {
  props: {
    shipping: String,
  },
  
  setup() {
    const router = useRouter()
    
    const goToShippingManagement = () => {
      router.push({ name: 'account-shipping' })
    }
    
    return {
      goToShippingManagement
    }
  },
  
  data() {
    return {
      addresses: [],
      loading: false,
      error: null
    }
  },

  methods: {
    formatAddress(address) {
      return `${address.address_name} - ${address.full_name}, ${address.address_line1}, ${address.city}`
    },

    async fetchAddresses() {
      this.loading = true
      this.error = null

      try {
        const response = await api.get('/shipping/addresses/')
        
        if (response.data.success) {
          this.addresses = response.data.data
          
          if (!this.shipping) {
            const defaultAddress = this.addresses.find(addr => addr.is_default)
            if (defaultAddress) {
              this.$emit('update:shipping', defaultAddress.id)
            }
          }
        } else {
          this.error = 'Failed to load addresses'
        }
      } catch (err) {
        console.error('Error fetching addresses:', err)
        this.error = 'Unable to load shipping addresses. Please try again.'
      } finally {
        this.loading = false
      }
    }
  },

  created() {
    this.fetchAddresses()
  }
}
</script>

<style scoped>
.shipping-section {
  width: 100%;
  max-width: 400px;
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.shipping-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
}

.shipping-content {
  position: relative;
}

.select-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.select-address {
  width: 100%;
  padding: 0.875rem 1rem;
  font-size: 1rem;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  appearance: none;
  cursor: pointer;
  transition: border-color 0.2s, box-shadow 0.2s;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1rem;
}

.select-address:focus {
  outline: none;
  border-color: #f27cf9;
  box-shadow: 0 0 0 3px rgba(242, 124, 249, 0.1);
}

.select-address:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
  color: #6c757d;
}

.new-address-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background-color: #f27cf9;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.2s;
}

.new-address-button:hover {
  background-color: #e455f6;
}

.loading-state {
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  color: #6c757d;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.loading-spinner {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #f27cf9;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-state {
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #ffcdd2;
  border-radius: 6px;
  color: #dc3545;
}

.retry-button {
  display: block;
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
  background-color: #f27cf9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
}

.retry-button:hover {
  background-color: #e455f6;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 480px) {
  .shipping-section {
    max-width: none;
    padding: 1rem;
  }
  
  .select-address {
    padding: 0.75rem;
    font-size: 0.875rem;
  }
  
  .new-address-button {
    padding: 0.625rem;
    font-size: 0.8125rem;
  }
}

</style>