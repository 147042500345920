<template>
  <div class="stats-container">
    <span class="count animated-text">{{ currentText }}</span>
  </div>
</template>

<script>
export default {
  name: 'UserStats',
  data() {
    return {
      texts: ['📚 365', '⭐ 4.8', '✨ 42k', '📖 99%'],
      currentIndex: 0,
      currentText: '📚 365'
    }
  },
  mounted() {
    setInterval(this.changeText, 2000)
  },
  methods: {
    changeText() {
      this.currentIndex = (this.currentIndex + 1) % this.texts.length
      this.currentText = this.texts[this.currentIndex]
    }
  },
  beforeUnmount() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
.stats-container {
  background: linear-gradient(135deg,
      rgba(138, 43, 226, 0.1) 0%,
      rgba(255, 105, 180, 0.1) 100%);
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  text-align: center;
  transition: transform 0.2s ease;
}

.stats-container:hover {
  transform: translateY(-2px);
}

.count {
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  background: linear-gradient(135deg,
      var(--primary-purple) 0%,
      var(--primary-pink) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
}

.animated-text {
  animation: slideIn 0.5s ease-out;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .count {
    font-size: 1.25rem;
  }
}
</style>