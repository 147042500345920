<template>
  <div class="profile-header">
    <div class="header-main">
      <!-- Profile Image Section -->
      <div class="profile-image-wrapper">
        <img v-if="profile.profile_picture_url" :src="profile.profile_picture_url" :alt="profile.username"
          class="profile-image" @error="handleImageError" />
        <div v-else class="profile-placeholder">
          {{ getInitials }}
        </div>
      </div>

      <div class="profile-info">
        <!-- Name Section -->
        <div class="name-section">
          <h1>{{ profile.first_name }} {{ profile.last_name }}</h1>
          <div class="username-wrapper">
            <span class="username">@{{ profile.username }}</span>
            <span v-if="profile.is_verified" class="verified-badge" title="Verified User">
              <i class="fas fa-check-circle"></i>
            </span>
          </div>
        </div>

        <!-- Social Links -->
        <div class="social-links" v-if="profile.github || profile.linkedin || profile.instagram">
          <a v-if="profile.github" :href="profile.github" target="_blank" rel="noopener noreferrer" class="social-link">
            <i class="fab fa-github"></i>
          </a>
          <a v-if="profile.linkedin" :href="profile.linkedin" target="_blank" rel="noopener noreferrer"
            class="social-link">
            <i class="fab fa-linkedin"></i>
          </a>
          <a v-if="profile.instagram" :href="profile.instagram" target="_blank" rel="noopener noreferrer"
            class="social-link">
            <i class="fab fa-instagram"></i>
          </a>
        </div>

        <!-- Bio Section -->
        <div class="bio-section">
          <div class="section-header" v-if="isOwnProfile">
            <button class="control-btn edit-btn" @click="startEditBio">
              <i class="fas fa-edit"></i>
              {{ isEditingBio ? 'Cancel' : 'Edit' }}
            </button>
          </div>

          <template v-if="isEditingBio">
            <textarea v-model="bioEdit" class="edit-textarea" :maxlength="bioMaxLength"
              placeholder="Tell others about yourself..."></textarea>
            <div class="edit-actions">
              <span class="char-count">{{ bioEdit.length }}/{{ bioMaxLength }}</span>
              <button class="control-btn save-btn" @click="saveBio" :disabled="isSaving">
                {{ isSaving ? 'Saving...' : 'Save' }}
              </button>
            </div>
          </template>
          <template v-else>
            <p class="bio-text">{{ profile.bio || 'No bio added yet.' }}</p>
            <span class="bio-date" v-if="profile.bio_updated_at">
              Updated {{ formatDate(profile.bio_updated_at) }}
            </span>
          </template>
        </div>

        <!-- Friend Actions -->
        <div class="profile-actions" v-if="!isOwnProfile">
          <template v-if="friendshipStatus.status === 'received'">
            <div class="action-buttons-wrapper">
              <button class="action-btn accept-btn" @click="handleAcceptRequest" :disabled="loading">
                <i class="fas fa-user-check"></i>
                <span v-if="loading" class="loading-spinner"></span>
                <span v-else>Accept</span>
              </button>
              <button class="action-btn reject-btn" @click="handleRejectRequest" :disabled="loading">
                <i class="fas fa-user-times"></i>
                <span v-if="loading" class="loading-spinner"></span>
                <span v-else>Reject</span>
              </button>
            </div>
          </template>
          <button v-else class="action-btn friend-btn" :class="friendButtonClasses" @click="handleFriendAction"
            :disabled="loading">
            <i :class="friendButtonIcon"></i>
            <span v-if="loading" class="loading-spinner"></span>
            <span v-else>
              <template v-if="friendshipStatus.status === 'friends'">
                Unfriend
              </template>
              <template v-else-if="friendshipStatus.status === 'pending'">
                Cancel Request
              </template>
              <template v-else> Add Friend </template>
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="profile-stats">
      <!-- Stats Row -->
      <div class="stats-row">
        <div class="stat-item">
          <i class="fas fa-book"></i>
          <div class="stat-content">
            <span class="stat-value">{{
              profile.favorite_books?.length || 0
            }}</span>
            <span class="stat-label">Favorite Books</span>
          </div>
        </div>

        <div class="stat-item">
          <i class="fas fa-bookmark"></i>
          <div class="stat-content">
            <span class="stat-value">{{
              profile.favorite_genres?.length || 0
            }}</span>
            <span class="stat-label">Genres</span>
          </div>
        </div>
      </div>

      <!-- Status Section -->
      <div class="status-section" v-if="profile.status || isOwnProfile">
        <div class="status-header">
          <span class="status-label">Current Status</span>
          <button v-if="isOwnProfile" class="control-btn edit-btn" @click="startEditStatus">
            <i class="fas fa-edit"></i>
            {{ isEditingStatus ? 'Cancel' : 'Edit' }}
          </button>
        </div>

        <template v-if="isEditingStatus">
          <input v-model="statusEdit" class="edit-input" :maxlength="statusMaxLength"
            placeholder="What's on your mind?" />
          <div class="edit-actions">
            <span class="char-count">{{ statusEdit.length }}/{{ statusMaxLength }}</span>
            <button class="control-btn save-btn" @click="saveStatus" :disabled="isSaving">
              {{ isSaving ? 'Saving...' : 'Save' }}
            </button>
          </div>
        </template>
        <template v-else>
          <p class="status-text">{{ profile.status || 'No status set.' }}</p>
          <span class="status-date" v-if="profile.status_updated_at">
            Updated {{ formatDate(profile.status_updated_at) }}
          </span>
        </template>
      </div>

      <!-- Currently Reading -->
      <div v-if="profile.currently_reading" class="current-book">
        <span class="currently-reading-label">Currently Reading</span>
        <router-link :to="`/books/${profile.currently_reading.id}`" class="book-link">
          <div class="book-cover-wrapper">
            <img :src="profile.currently_reading.cover_image" :alt="profile.currently_reading.title"
              class="book-cover" />
          </div>
          <div class="book-info">
            <h3>{{ profile.currently_reading.title }}</h3>
            <p>{{ profile.currently_reading.author.name }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

export default {
  name: 'ProfileHeader',

  props: {
    profile: {
      type: Object,
      required: true,
    },
    isOwnProfile: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['refresh'],

  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const toast = useToast()

    // Reactive state
    const loading = ref(false)
    const friendshipStatus = ref({ status: 'none' })
    const isEditingBio = ref(false)
    const isEditingStatus = ref(false)
    const isSaving = ref(false)
    const bioEdit = ref(props.profile.bio || '')
    const statusEdit = ref(props.profile.status || '')

    // Constants
    const bioMaxLength = 500
    const statusMaxLength = 150

    // Computed Properties
    const getInitials = computed(() => {
      const firstName = props.profile.first_name?.charAt(0) || ''
      const lastName = props.profile.last_name?.charAt(0) || ''
      return (firstName + lastName).toUpperCase()
    })

    const friendButtonClasses = computed(() => ({
      'is-friend': friendshipStatus.value.status === 'friends',
      'is-pending': friendshipStatus.value.status === 'pending',
      'is-received': friendshipStatus.value.status === 'received'
    }))

    const friendButtonIcon = computed(() => {
      const status = friendshipStatus.value.status
      switch (status) {
        case 'friends':
          return 'fas fa-user-check'
        case 'pending':
          return 'fas fa-user-clock'
        case 'received':
          return 'fas fa-user-plus'
        default:
          return 'fas fa-user-plus'
      }
    })

    const friendButtonText = computed(() => {
      const status = friendshipStatus.value.status
      switch (status) {
        case 'friends':
          return 'Unfriend'
        case 'pending':
          return 'Cancel Request'
        case 'received':
          return 'Accept Request'
        default:
          return 'Add Friend'
      }
    })

    // Methods
    const checkFriendshipStatus = async () => {
      if (!props.profile?.id || props.isOwnProfile) {
        friendshipStatus.value = { status: 'none' }
        return
      }

      try {
        const response = await store.dispatch('social/checkFriendshipStatus', props.profile.id)

        // Map API status to our component status
        const mappedStatus = {
          status: response.status === 'request_sent' ? 'pending' :
            response.status === 'request_received' ? 'received' :
              response.status,
          friendshipId: response.data?.id,
          requestId: response.data?.id
        }

        friendshipStatus.value = mappedStatus
      } catch (error) {
        console.error('Error checking friendship status:', error)
        friendshipStatus.value = { status: 'none' }
        toast.error('Unable to check friendship status')
      }
    }

    // Watchers
    watch(
      [() => route.params.id, () => props.profile],
      async ([newId, newProfile], [oldId, oldProfile]) => {
        if (!newProfile || (oldProfile && newProfile.id === oldProfile.id)) return

        // Reset states
        bioEdit.value = newProfile.bio || ''
        statusEdit.value = newProfile.status || ''
        isEditingBio.value = false
        isEditingStatus.value = false

        // Check friendship status if not own profile
        if (!props.isOwnProfile && newProfile.id) {
          await checkFriendshipStatus()
        } else {
          friendshipStatus.value = { status: 'none' }
        }
      },
      { immediate: true }
    )

    // Edit Methods
    const startEditBio = () => {
      bioEdit.value = isEditingBio.value ? props.profile.bio || '' : bioEdit.value
      isEditingBio.value = !isEditingBio.value
    }

    const startEditStatus = () => {
      statusEdit.value = isEditingStatus.value ? props.profile.status || '' : statusEdit.value
      isEditingStatus.value = !isEditingStatus.value
    }

    const saveBio = async () => {
      if (isSaving.value || !bioEdit.value.trim()) return

      try {
        isSaving.value = true
        const { data } = await store.dispatch('user/updateBio', bioEdit.value)

        if (data.success) {
          isEditingBio.value = false
          emit('refresh')
          toast.success('Bio updated successfully')
        }
      } catch (error) {
        console.error('Failed to update bio:', error)
        toast.error('Failed to update bio')
      } finally {
        isSaving.value = false
      }
    }

    const saveStatus = async () => {
      if (isSaving.value || !statusEdit.value.trim()) return

      try {
        isSaving.value = true
        const { data } = await store.dispatch('user/updateStatus', statusEdit.value)

        if (data.success) {
          isEditingStatus.value = false
          emit('refresh')
          toast.success('Status updated successfully')
        }
      } catch (error) {
        console.error('Failed to update status:', error)
        toast.error('Failed to update status')
      } finally {
        isSaving.value = false
      }
    }

    // Friend Methods
    const handleFriendAction = async () => {
      if (loading.value) return
      loading.value = true

      try {
        const status = friendshipStatus.value.status
        switch (status) {
          case 'friends':
            await store.dispatch('social/unfriend', {
              friendshipId: friendshipStatus.value.friendshipId,
              userId: props.profile.id
            })
            toast.success('Friend removed')
            break
          case 'pending':
            await store.dispatch('social/cancelRequest', {
              requestId: friendshipStatus.value.requestId,
              userId: props.profile.id
            })
            toast.success('Friend request cancelled')
            break
          default:
            await store.dispatch('social/sendFriendRequest', props.profile.id)
            toast.success('Friend request sent')
        }

        await checkFriendshipStatus()
      } catch (error) {
        console.error('Friend action error:', error)
        toast.error('Failed to process friend request')
      } finally {
        loading.value = false
      }
    }

    const handleAcceptRequest = async () => {
      if (loading.value) return
      loading.value = true

      try {
        await store.dispatch('social/acceptRequest', {
          requestId: friendshipStatus.value.requestId,
          userId: props.profile.id
        })
        toast.success('Friend request accepted')
        await checkFriendshipStatus()
      } catch (error) {
        console.error('Error accepting friend request:', error)
        toast.error('Failed to accept friend request')
      } finally {
        loading.value = false
      }
    }

    const handleRejectRequest = async () => {
      if (loading.value) return
      loading.value = true

      try {
        await store.dispatch('social/rejectRequest', {
          requestId: friendshipStatus.value.requestId,
          userId: props.profile.id
        })
        toast.success('Friend request rejected')
        await checkFriendshipStatus()
      } catch (error) {
        console.error('Error rejecting friend request:', error)
        toast.error('Failed to reject friend request')
      } finally {
        loading.value = false
      }
    }

    const formatDate = dateString => {
      if (!dateString) return ''
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    }

    const handleImageError = e => {
      e.target.style.display = 'none'
      const placeholder = e.target.parentElement.querySelector('.profile-placeholder')
      if (placeholder) {
        placeholder.style.display = 'flex'
      }
    }

    // Lifecycle hooks
    onMounted(async () => {
      if (props.profile?.id && !props.isOwnProfile) {
        await checkFriendshipStatus()
      }
    })

    return {
      loading,
      friendshipStatus,
      getInitials,
      friendButtonClasses,
      friendButtonIcon,
      friendButtonText,
      formatDate,
      handleImageError,
      handleFriendAction,
      handleAcceptRequest,
      handleRejectRequest,
      isEditingBio,
      isEditingStatus,
      isSaving,
      bioEdit,
      statusEdit,
      bioMaxLength,
      statusMaxLength,
      startEditBio,
      startEditStatus,
      saveBio,
      saveStatus,
    }
  },
}
</script>

<style scoped>
/* Base Styles */
.profile-header {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
}

.header-main {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

/* Profile Image Styles */
.profile-image-wrapper {
  flex-shrink: 0;
  position: relative;
}

.profile-image {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #f27cf9;
}

.profile-placeholder {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: #f27cf9;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: 600;
}

/* Profile Info Styles */
.profile-info {
  flex: 1;
  min-width: 0;
}

.name-section {
  margin-bottom: 1rem;
}

.name-section h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 0.5rem 0;
  word-break: break-word;
}

.username-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: nowrap;
}

.username {
  color: #666;
  font-size: 1.1rem;
}

.verified-badge {
  color: #f27cf9;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

/* Social Links Styles */
.social-links {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  flex-wrap: wrap;
}

.social-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  text-decoration: none;
  color: #666;
  background: #f8f9fa;
  transition: all 0.2s ease;
  font-size: 0.9rem;
}

.social-link:hover {
  background: #f27cf9;
  color: white;
}

.social-link i {
  font-size: 1.1rem;
}

/* Bio Section Styles */
.bio-section {
  margin: 1.5rem 0;
  position: relative;
}

.section-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.bio-text {
  line-height: 1.6;
  margin: 0;
  color: #444;
  white-space: pre-line;
  word-break: break-word;
}

.bio-date,
.status-date {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: #666;
}

/* Button Styles */
.control-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.edit-btn {
  background: #f8f9fa;
  color: #666;
}

.edit-btn:hover {
  background: #f27cf9;
  color: white;
}

.edit-btn i {
  font-size: 1rem;
}

.save-btn {
  background: #f27cf9;
  color: white;
}

.save-btn:hover {
  background: #e065e6;
}

.save-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Stats Section Styles */
.profile-stats {
  border-top: 1px solid #eee;
  padding-top: 2rem;
}

.stats-row {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.stat-item {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 12px;
  transition: transform 0.2s ease;
}

.stat-item:hover {
  transform: translateY(-2px);
}

.stat-item i {
  font-size: 1.5rem;
  color: #f27cf9;
}

.stat-content {
  display: flex;
  flex-direction: column;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
}

.stat-label {
  color: #666;
  font-size: 0.9rem;
}

/* Status Section Styles */
.status-section {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 12px;
  margin-bottom: 1.5rem;
}

.status-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.status-label {
  font-weight: 500;
  color: #666;
}

.status-text {
  margin: 0;
  color: #1a1a1a;
  line-height: 1.5;
}

/* Friend Actions Styles */
.action-buttons-wrapper {
  display: flex;
  gap: 1rem;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  min-width: 150px;
  justify-content: center;
  position: relative;
}

.friend-btn {
  background: #f27cf9;
  color: white;
}

.friend-btn:hover {
  background: #e065e6;
}

.friend-btn.is-friend {
  background: #e0e0e0;
  color: #666;
}

.friend-btn.is-friend:hover {
  background: #d32f2f;
  color: white;
}

/* Edit Components Styles */
.edit-textarea,
.edit-input {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #eee;
  border-radius: 8px;
  margin-bottom: 1rem;
  font-family: inherit;
  resize: vertical;
}

.edit-textarea {
  min-height: 120px;
}

.edit-textarea:focus,
.edit-input:focus {
  outline: none;
  border-color: #f27cf9;
}

.edit-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.char-count {
  color: #666;
  font-size: 0.9rem;
}

/* Current Book Styles */
.book-link {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 12px;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease;
  margin-top: 0.5rem;
}

.book-cover-wrapper {
  flex-shrink: 0;
}

.book-cover {
  width: 80px;
  height: 120px;
  object-fit: cover;
  border-radius: 4px;
}

.book-info {
  min-width: 0;
}

.book-info h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
  color: #1a1a1a;
  word-break: break-word;
}

/* Loading Spinner */
.loading-spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .profile-header {
    padding: 1rem;
  }

  .header-main {
    flex-direction: column;
    align-items: center;
  }

  .profile-info {
    width: 100%;
  }

  .name-section {
    text-align: center;
  }

  .name-section h1 {
    font-size: 1.75rem;
  }

  .username-wrapper {
    justify-content: center;
  }

  .social-links {
    justify-content: center;
  }

  .bio-section {
    text-align: center;
  }

  .section-header {
    justify-content: center;
  }

  .stats-row {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
  }

  .stat-item {
    min-width: calc(50% - 0.5rem);
  }

  .action-buttons-wrapper {
    flex-direction: column;
  }

  .action-btn {
    width: 100%;
  }

  .edit-actions {
    flex-direction: column;
  }

  .control-btn {
    width: 100%;
  }
}

/* Small Mobile Styles */
@media (max-width: 480px) {

  .profile-image,
  .profile-placeholder {
    width: 120px;
    height: 120px;
    font-size: 2rem;
  }

  .name-section h1 {
    font-size: 1.5rem;
  }

  .stats-row {
    flex-direction: row;
    gap: 0.5rem;
  }

  .stat-item {
    padding: 0.75rem;
  }

  .stat-value {
    font-size: 1.25rem;
  }

  .stat-label {
    font-size: 0.8rem;
  }

  .book-link {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .book-info {
    text-align: center;
  }

  .social-link {
    width: 100%;
    justify-content: center;
  }
}
</style>
