import BookDetails from '@/views/books/BookDetails.vue'
import Catalog from '@/views/books/Catalog.vue'
import AuthorDetails from '@/views/books/AuthorDetails.vue'
import SearchPage from '@/views/books/SearchPage.vue'

export default [
  {
    path: '/books/:id',
    name: 'details',
    component: BookDetails,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Book Details',
      transition: 'slide-fade',
    },
  },
  {
    path: '/books',
    name: 'books',
    component: Catalog,
    meta: {
      requiresAuth: true,
      title: 'Book Shop',
      transition: 'slide-fade',
    },
  },
  {
    path: '/authors/:id',
    name: 'author-details',
    component: AuthorDetails,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Author Details',
      transition: 'slide-fade',
    },
  },
  {
    path: '/search',
    name: 'search',
    component: SearchPage,
    props: route => ({ query: route.query.q }),
    meta: {
      requiresAuth: true,
      title: 'Search Books',
      transition: 'slide-fade',
    },
  },
]
