<template>
  <div class="group-card">
    <div class="group-info">
      <div class="group-icon" :style="{ backgroundColor: group.bgColor }">
        <i :class="['fas', group.icon]"></i>
      </div>
      <div class="group-details">
        <h3>{{ group.name }}</h3>
        <p>{{ group.members }} members</p>
      </div>
    </div>
    <button
      :class="['join-button', { joined: group.joined }]"
      @click="$emit('toggle-join', group.id)"
    >
      {{ group.joined ? 'Joined' : 'Join' }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ReadingGroupCard',
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.group-card {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  transition: background-color 0.2s ease;
}

.group-card:hover {
  background-color: rgba(138, 43, 226, 0.02);
}

.group-info {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.group-icon {
  width: 56px;
  height: 56px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-icon i {
  font-size: 24px;
  color: var(--primary-purple);
}

.group-details h3 {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-dark);
  margin-bottom: 0.25rem;
}

.group-details p {
  font-size: 0.875rem;
  color: var(--text-gray);
}

.join-button {
  padding: 0.625rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;
  min-width: 100px;
}

.join-button:not(.joined) {
  background-color: var(--primary-light);
  color: var(--primary-purple);
  border: 1px solid transparent;
}

.join-button:not(.joined):hover {
  background-color: var(--primary-purple);
  color: var(--white);
}

.join-button.joined {
  background-color: var(--primary-purple);
  color: var(--white);
  border: 1px solid var(--primary-purple);
}

.join-button.joined:hover {
  background-color: var(--white);
  color: var(--primary-purple);
}
</style>
