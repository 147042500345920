// src/router/routes/user-routes.js
import Account from '@/views/user/Account.vue'
import OrderDetail from '@/views/user/OrderDetail.vue'
import Profile from '@/views/user/Profile.vue'

export default [
  {
    path: '/account',
    component: Account,
    meta: {
      requiresAuth: true,
      title: 'My Account',
      transition: 'slide-fade',
    },
    children: [
      {
        path: '',
        name: 'account-menu',
        meta: {
          title: 'Account Menu',
        },
      },
      {
        path: 'reading',
        name: 'currently-reading',
        component: () =>
          import('@/components/account/sections/CurrentlyReading.vue'),
        meta: {
          title: 'Currently Reading',
          backRoute: '/account',
        },
      },
      {
        path: 'favorites',
        name: 'favorites',
        component: () => import('@/components/account/sections/Favorites.vue'),
        meta: {
          title: 'Favorites',
          backRoute: '/account',
        },
      },
      {
        path: 'profile',
        name: 'account-profile',
        component: () =>
          import('@/components/account/sections/AccountInfo.vue'),
        meta: {
          title: 'Account Info',
          backRoute: '/account',
        },
      },
      {
        path: 'settings',
        component: () => import('@/components/account/sections/Settings.vue'),
        children: [
          {
            path: '',
            name: 'account-settings',
            component: () =>
              import(
                '@/components/account/sections/settingsection/SettingsMenu.vue'
              ),
            meta: {
              title: 'Settings',
              backRoute: '/account',
            },
          },
          {
            path: 'account',
            name: 'account-settings-edit',
            component: () =>
              import(
                '@/components/account/sections/settingsection/AccountSettings.vue'
              ),
            meta: {
              title: 'Account Settings',
              backRoute: '/account/settings',
            },
          },
          {
            path: 'password',
            name: 'account-password',
            component: () =>
              import('@/components/account/forms/PasswordResetForm.vue'),
            meta: {
              title: 'Change Password',
              backRoute: '/account/settings',
            },
          },
          {
            path: 'privacy',
            name: 'account-privacy',
            component: () =>
              import(
                '@/components/account/sections/settingsection/PrivacySettings.vue'
              ),
            meta: {
              title: 'Privacy Settings',
              backRoute: '/account/settings',
            },
          },
          {
            path: 'notifications',
            name: 'account-notifications',
            component: () =>
              import(
                '@/components/account/sections/settingsection/NotificationSettings.vue'
              ),
            meta: {
              title: 'Notification Settings',
              backRoute: '/account/settings',
            },
          },
          {
            path: 'shipping',
            name: 'account-shipping',
            component: () =>
              import(
                '@/components/account/sections/settingsection/ShippingAddress.vue'
              ),
            meta: {
              title: 'Shipping Addresses',
              backRoute: '/account/settings',
            },
          },
        ],
      },
      {
        path: 'orders',
        name: 'account-orders',
        component: () => import('@/components/account/sections/OrdersList.vue'),
        meta: {
          title: 'Orders',
          backRoute: '/account',
        },
      },
    ],
    
  },
  {
    path: '/profile/:id',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      title: 'User Profile',
    },
  },
  {
    path: '/orders/:id',
    name: 'order-details',
    component: OrderDetail,
    meta: {
      requiresAuth: true,
      title: 'Order Details',
      transition: 'slide-fade',
      backRoute: '/account/orders',
    },
  },
]
