// components/OrderSummary.vue
<template>
  <div class="order-summary">
    <h2>Your Order</h2>

    <div class="order-items">
      <div v-for="(item, index) in orderItems" :key="index" class="order-item">
        <div class="item-info">
          <span class="item-name">{{ item.name }}</span>
          <span class="item-format">{{ item.format }}</span>
        </div>
        <div class="item-details">
          <span class="quantity">{{ item.quantity }}×</span>
          <span class="price">${{ item.price.toFixed(2) }}</span>
        </div>
      </div>
    </div>

    <div class="order-totals">
      <div class="total-row">
        <span>Subtotal:</span>
        <span>${{ totals.subtotal.toFixed(2) }}</span>
      </div>
      <div class="total-row">
        <span>Shipping:</span>
        <span>${{ totals.shipping.toFixed(2) }}</span>
      </div>
      <div class="total-row">
        <span>Coupon:</span>
        <span>${{ totals.coupon.toFixed(2) }}</span>
      </div>
      <div class="total-row">
        <span>Discount:</span>
        <span>${{ totals.discount.toFixed(2) }}</span>
      </div>
    </div>

    <div class="total-payment">
      <span>Total Payment</span>
      <span>${{ totals.total.toFixed(2) }}</span>
    </div>

    <div class="payment-methods">
      <h3>Payment Method</h3>
      <div
        v-for="option in paymentOptions"
        :key="option.value"
        class="payment-option"
      >
        <input
          type="radio"
          :id="option.value"
          :value="option.value"
          v-model="selectedPayment"
          @change="$emit('payment-method-changed', option.value)"
        />
        <label :for="option.value">{{ option.label }}</label>
      </div>
    </div>

    <button class="place-order-btn" @click="$emit('place-order')">
      Place Order
    </button>
  </div>
</template>

<script>
export default {
  name: 'OrderSummary',

  props: {
    orderItems: {
      type: Array,
      required: true,
    },
    totals: {
      type: Object,
      required: true,
    },
    paymentMethod: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      paymentOptions: [
        { value: 'bank', label: 'Direct bank transfer' },
        { value: 'check', label: 'Check payments' },
        { value: 'cash', label: 'Cash on delivery' },
        { value: 'paypal', label: 'PayPal' },
      ],
      selectedPayment: this.paymentMethod,
    }
  },

  watch: {
    paymentMethod(newValue) {
      this.selectedPayment = newValue
    },
  },
}
</script>

<style scoped>
.order-summary {
  width: 400px;
  background: #f8f9fa;
  padding: 30px;
  border-radius: 8px;
}

.order-items {
  margin: 20px 0;
}

.order-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e2e8f0;
}

.item-info {
  display: flex;
  flex-direction: column;
}

.item-name {
  font-weight: 500;
}

.item-format {
  color: #7540ff;
  font-size: 14px;
}

.item-details {
  text-align: right;
}

.quantity {
  color: #666;
  margin-right: 8px;
}

.order-totals {
  margin: 20px 0;
}

.total-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.total-payment {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  margin: 20px 0;
  padding-top: 20px;
  border-top: 1px solid #e2e8f0;
}

.payment-methods {
  margin: 20px 0;
}

.payment-option {
  margin: 10px 0;
}

.place-order-btn {
  width: 100%;
  padding: 15px;
  background: #ff69b4;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.place-order-btn:hover {
  background: #ff1493;
}

@media (max-width: 768px) {
  .order-summary {
    width: 100%;
  }
}
</style>
