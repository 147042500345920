<template>
  <div class="options-section">
    <div class="top-row">
      <div class="price">Rs {{ formatPrice(book.price) }}</div>
      <div class="action-icons">
        <button
          @click="toggleFavorite"
          class="icon-btn"
          :class="{ 'is-favorite': isFavorite }"
          :disabled="favoriteLoading"
        >
          <i
            class="fas"
            :class="isFavorite ? 'fa-heart' : 'fa-regular fa-heart'"
          ></i>
        </button>
      </div>
    </div>

    <div class="action-buttons">
      <button
        @click="handleCartAction"
        class="action-btn cart-btn"
        :disabled="loading"
        :class="{ 'in-cart': isInCart }"
      >
        {{ cartButtonText }}
        <i class="fas" :class="isInCart ? 'fa-trash' : 'fa-shopping-cart'"></i>
      </button>

      <button
        @click="toggleWishlist"
        class="action-btn wishlist-btn"
        :disabled="wishlistLoading"
        :class="{ 'in-wishlist': isInWishlist }"
      >
        {{ wishlistButtonText }}
        <i class="fas fa-bookmark"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import api from '@/api/axios'

export default {
  name: 'BookOptionsSection',
  props: {
    book: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    // State
    const loading = ref(false)
    const favoriteLoading = ref(false)
    const wishlistLoading = ref(false)
    const cartItems = ref([])
    const isFavorite = ref(false)
    const isInWishlist = ref(false)
    const isInCart = ref(false)
    const cartItemId = ref(null)

    // Computed
    const cartButtonText = computed(() => {
      if (loading.value) return isInCart.value ? 'Removing...' : 'Adding...'
      if (isInCart.value) return 'Remove from Cart'
      return 'Add to Cart'
    })

    const wishlistButtonText = computed(() => {
      if (wishlistLoading.value) return 'Processing...'
      if (isInWishlist.value) return 'In Wishlist'
      return 'Add to Wishlist'
    })

    // Methods
    const formatPrice = price => {
      return Number(price).toFixed(2)
    }

    const checkAuth = () => {
      if (!store.getters.isAuthenticated) {
        router.push({
          path: '/login',
          query: { redirect: route.fullPath },
        })
        return false
      }
      return true
    }

    const checkCartStatus = async () => {
      try {
        const response = await api.get(`/cart/${props.book.id}/check`, {
          headers: { Authorization: `Bearer ${store.getters.token}` },
        })

        if (response.data.success) {
          isInCart.value = response.data.data.exists
          if (isInCart.value) {
            cartItemId.value = response.data.data.cart_item_id
          }
        }
      } catch (error) {
        console.error('Error checking cart status:', error)
      }
    }

    const fetchCart = async () => {
      try {
        const response = await api.get('/cart', {
          headers: { Authorization: `Bearer ${store.getters.token}` },
        })
        if (response.data.success) {
          cartItems.value = response.data.data
          const cartItem = cartItems.value.find(
            item => item.book.id === props.book.id
          )
          isInCart.value = !!cartItem
          cartItemId.value = cartItem?.id || null
        }
      } catch (error) {
        console.error('Error fetching cart:', error)
        if (error.response?.status === 401) {
          store.commit('logout')
        }
      }
    }

    const handleCartAction = async () => {
      if (!checkAuth() || loading.value) return

      loading.value = true
      try {
        if (isInCart.value && cartItemId.value) {
          const response = await api.delete(
            `/cart/${cartItemId.value}/remove`,
            {
              headers: {
                Authorization: `Bearer ${store.getters.token}`,
              },
            }
          )

          if (response.data.success) {
            isInCart.value = false
            cartItemId.value = null
            await fetchCart()
          }
        } else {
          const response = await api.post(
            `/cart/${props.book.id}/add`,
            { purchase_type: 'buy' },
            {
              headers: {
                Authorization: `Bearer ${store.getters.token}`,
                'Content-Type': 'application/json',
              },
            }
          )

          if (response.data.success) {
            isInCart.value = true
            cartItemId.value = response.data.data.id
            await fetchCart()
          }
        }
      } catch (error) {
        console.error('Error managing cart:', error)
        if (error.response?.status === 401) {
          store.commit('logout')
          router.push('/login')
        }
      } finally {
        loading.value = false
      }
    }

    const toggleFavorite = async () => {
      if (!checkAuth() || favoriteLoading.value) return

      favoriteLoading.value = true
      try {
        const endpoint = isFavorite.value
          ? '/users/favorites/remove/'
          : '/users/favorites/add/'

        const response = await api.request({
          method: isFavorite.value ? 'DELETE' : 'POST',
          url: endpoint,
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
            'Content-Type': 'application/json',
          },
          data: { book_id: props.book.id },
        })

        if (response.data.success) {
          isFavorite.value = !isFavorite.value
        }
      } catch (error) {
        console.error('Error toggling favorite:', error)
        if (error.response?.status === 401) {
          store.commit('logout')
          router.push('/login')
        }
      } finally {
        favoriteLoading.value = false
      }
    }

    const toggleWishlist = async () => {
      if (!checkAuth() || wishlistLoading.value) return

      wishlistLoading.value = true
      try {
        const endpoint = isInWishlist.value
          ? `/wishlist/books/${props.book.id}/remove`
          : `/wishlist/books/${props.book.id}/add`

        const response = await api.request({
          method: isInWishlist.value ? 'DELETE' : 'POST',
          url: endpoint,
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
            'Content-Type': 'application/json',
          },
        })

        if (response.data.success) {
          isInWishlist.value = !isInWishlist.value
        }
      } catch (error) {
        console.error('Error toggling wishlist:', error)
        if (error.response?.status === 401) {
          store.commit('logout')
          router.push('/login')
        }
      } finally {
        wishlistLoading.value = false
      }
    }

    const fetchInitialState = async () => {
      if (!store.getters.isAuthenticated) return

      try {
        // Check cart status first
        await checkCartStatus()
        await fetchCart()

        // Fetch favorites
        const favResponse = await api.get('/users/favorites/', {
          headers: { Authorization: `Bearer ${store.getters.token}` },
        })
        if (favResponse.data.success) {
          isFavorite.value = favResponse.data.data.favorite_books.some(
            book => book.id === props.book.id
          )
        }

        // Fetch wishlist
        const wishlistResponse = await api.get('/wishlist/', {
          headers: { Authorization: `Bearer ${store.getters.token}` },
        })
        if (wishlistResponse.data.success) {
          isInWishlist.value = wishlistResponse.data.data.items.some(
            item => item.book.id === props.book.id
          )
        }
      } catch (error) {
        console.error('Error fetching initial state:', error)
        if (error.response?.status === 401) {
          store.commit('logout')
        }
      }
    }

    onMounted(() => {
      fetchInitialState()
    })

    return {
      loading,
      favoriteLoading,
      wishlistLoading,
      isInCart,
      isFavorite,
      isInWishlist,
      cartButtonText,
      wishlistButtonText,
      formatPrice,
      handleCartAction,
      toggleFavorite,
      toggleWishlist,
    }
  },
}
</script>

<style scoped>
.options-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-size: 2rem;
  font-weight: 600;
  color: #f27cf9;
}

.action-icons {
  display: flex;
  gap: 0.5rem;
}

.icon-btn {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  color: #666;
  transition: all 0.2s ease;
}

.icon-btn:hover {
  transform: scale(1.1);
  color: #333;
}

.icon-btn.is-favorite {
  color: #ff4444;
}

.icon-btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.action-buttons {
  display: flex;
  gap: 1rem;
}

.action-btn {
  flex: 1;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  font-weight: 500;
}

.cart-btn {
  background: #f27cf9;
  color: white;
}

.cart-btn:hover:not(:disabled) {
  background: #e065e8;
  transform: translateY(-1px);
}

.cart-btn.in-cart {
  background: #dc3545;
}

.cart-btn.in-cart:hover:not(:disabled) {
  background: #c82333;
}

.wishlist-btn {
  background: white;
  color: #333;
  border: 2px solid #4caf50;
}

.wishlist-btn:hover:not(:disabled) {
  background: #f8f8f8;
  transform: translateY(-1px);
}

.wishlist-btn.in-wishlist {
  background: #4caf50;
  color: white;
  border: 2px solid #4caf50;
}

.action-btn:disabled {
  background: #ccc;
  border-color: #ccc;
  color: #666;
  cursor: not-allowed;
  transform: none;
}

.action-btn:focus,
.icon-btn:focus {
  outline: 2px solid #f27cf9;
  outline-offset: 2px;
}

.icon-btn:focus {
  outline-color: #ff4444;
}

@media (max-width: 768px) {
  .action-buttons {
    flex-direction: column;
  }

  .action-btn {
    width: 100%;
  }

  .price {
    font-size: 1.75rem;
  }
}

@media (max-width: 480px) {
  .price {
    font-size: 1.5rem;
  }

  .icon-btn {
    font-size: 1.25rem;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.icon-btn.is-favorite,
.wishlist-btn.in-wishlist,
.cart-btn.in-cart {
  animation: pulse 0.3s ease-in-out;
}
</style>
