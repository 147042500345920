import axios from 'axios'
import store from '../store/auth'
import router from '../router'

const BASE_URL = 'https://booksville.0xss.us'

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export const getAssetUrl = path => {
  if (!path) return null
  if (path.startsWith('http')) return path
  return `${BASE_URL}${path}`
}

const transformUserUrls = data => {
  if (!data) return data
  if (Array.isArray(data)) return data.map(transformUserUrls)

  if (typeof data === 'object') {
    if (data.user) {
      data.user = {
        ...data.user,
        profile_picture_url: data.user.profile_picture
          ? getAssetUrl(data.user.profile_picture)
          : null,
      }
    }
    if (data.profile_picture) {
      data.profile_picture_url = getAssetUrl(data.profile_picture)
    }
  }
  return data
}

api.interceptors.request.use(
  config => {
    if (config.url.includes('/users/login/') || config.url.includes('/users/register/')) {
      return config
    }

    const storedToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
    const currentToken = store.getters.getToken

    // Check if tokens match and exist
    if (!storedToken || storedToken !== currentToken) {
      store.dispatch('logout')
      return Promise.reject(new Error('Token mismatch'))
    }

    config.headers.Authorization = `Bearer ${storedToken}`
    return config
  },
  error => Promise.reject(error)
)

api.interceptors.response.use(
  response => {
    if (response.data?.results) {
      response.data.results = transformUserUrls(
        response.data.results.data || response.data.results
      )
    } else if (response.data?.data) {
      response.data.data = transformUserUrls(response.data.data)
    }
    return response
  },
  error => {
    // Only handle actual 401 responses
    if (error.response?.status === 401) {
      store.commit('CLEAR_AUTH')
      if (router.currentRoute.value.name !== 'login') {
        router.push('/login')
      }
    }
    return Promise.reject(error)
  }
)

export { api as default, BASE_URL }
