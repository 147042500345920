import Home from '@/views/Home.vue'
import Landing from '@/views/static/Landing.vue'
import AboutUs from '@/views/static/AboutUs.vue'
import ContactUs from '@/views/static/ContactUs.vue'
import NotFound from '@/views/error/NotFound.vue'

export default [
  {
    path: '/',
    name: 'landing',
    component: Landing,
    meta: {
      requiresAuth: false,
      title: 'Welcome to BooksVille',
      transition: 'fade',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
      title: 'Home',
      transition: 'slide-fade',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: AboutUs,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactUs,
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: NotFound,
    meta: {
      requiresAuth: false,
      title: 'Page Not Found',
      transition: 'fade',
    },
  },
]
