import staticRoutes from './static-routes'
import authRoutes from './auth-routes'
import bookRoutes from './book-routes'
import shopRoutes from './shop-routes'
import userRoutes from './user-routes'
import socialRoutes from './social-routes'
import legalRoutes from './legal-routes'

export default [
  ...staticRoutes,
  ...authRoutes,
  ...bookRoutes,
  ...shopRoutes,
  ...userRoutes,
  ...socialRoutes,
  ...legalRoutes,
]
