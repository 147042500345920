<template>
  <div class="reviews-list">
    <div v-for="review in reviewsData" :key="review.id" class="review-item">
      <div class="review-header">
        <div class="user-info">
          <div
            class="user-profile"
            @click="navigateToProfile(review.user.id)"
            role="button"
          >
            <img
              v-if="review.user.profile_picture_url"
              :src="review.user.profile_picture_url"
              :alt="review.user.username"
              class="profile-picture"
              @error="handleImageError"
            />
            <div v-else class="profile-placeholder">
              {{ getUserInitials(review.user) }}
            </div>
            <div class="user-details">
              <span class="username">
                {{ review.user.first_name }} {{ review.user.last_name }}
              </span>
              <span class="user-handle">@{{ review.user.username }}</span>
            </div>
          </div>
          <div class="rating-display">
            <i
              v-for="star in 5"
              :key="star"
              class="fa-star"
              :class="star <= parseFloat(review.rating) ? 'fas' : 'far'"
            ></i>
            <span class="rating-text">{{ review.rating }}/5</span>
          </div>
        </div>
        <div class="review-meta">
          <span
            class="review-date"
            :title="formatDate(review.created_at, true)"
          >
            {{ formatDate(review.created_at) }}
          </span>
          <span
            v-if="review.updated_at !== review.created_at"
            class="edited-tag"
            :title="'Edited on ' + formatDate(review.updated_at, true)"
          >
            Edited
          </span>
        </div>
      </div>

      <div class="review-content-wrapper" v-if="review.contains_spoiler">
        <div
          class="review-content"
          :class="{ 'is-blurred': !shownReviews.includes(review.id) }"
        >
          <h3 class="review-title">{{ review.title }}</h3>
          <p class="review-text">{{ review.content }}</p>
          <div class="spoiler-tag">Contains spoilers</div>
        </div>

        <div
          v-if="!shownReviews.includes(review.id)"
          class="spoiler-overlay"
          @click="toggleSpoiler(review.id)"
        >
          <div class="spoiler-warning">
            <i class="fas fa-exclamation-triangle"></i>
            Contains spoilers
          </div>
          <button class="show-spoiler-btn">Click to view</button>
        </div>
      </div>

      <div v-else class="review-content">
        <h3 class="review-title">{{ review.title }}</h3>
        <p class="review-text">{{ review.content }}</p>
      </div>
    </div>

    <div v-if="reviewsData.length === 0" class="no-reviews">
      No reviews yet. Be the first to review!
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  name: 'ReviewList',
  props: {
    reviews: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup() {
    const router = useRouter()

    const navigateToProfile = userId => {
      if (userId) {
        router.push(`/profile/${userId}`)
      }
    }

    return {
      navigateToProfile,
    }
  },
  data() {
    return {
      shownReviews: [],
    }
  },
  computed: {
    reviewsData() {
      // If reviews is already an array, use it
      if (Array.isArray(this.reviews)) {
        return this.reviews
      }
      // Fallback to empty array
      return []
    },
  },
  methods: {
    formatDate(dateString, includeTime = false) {
      if (!dateString) return ''

      const date = new Date(dateString)
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        ...(includeTime
          ? {
              hour: '2-digit',
              minute: '2-digit',
            }
          : {}),
      }

      return date.toLocaleDateString('en-US', options)
    },
    getUserInitials(user) {
      if (!user) return '??'
      return `${user.first_name?.charAt(0) || ''}${
        user.last_name?.charAt(0) || ''
      }`.toUpperCase()
    },
    toggleSpoiler(reviewId) {
      const index = this.shownReviews.indexOf(reviewId)
      if (index === -1) {
        this.shownReviews.push(reviewId)
      } else {
        this.shownReviews.splice(index, 1)
      }
    },
    handleImageError(e) {
      e.target.style.display = 'none'
      e.target.parentElement.querySelector(
        '.profile-placeholder'
      ).style.display = 'flex'
    },
  },
}
</script>

<style scoped>
.reviews-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
}

.review-item {
  padding: 1.5rem;
  border: 1px solid #eee;
  border-radius: 12px;
  background: #fff;
  transition: box-shadow 0.2s ease;
  position: relative;
}

.review-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.user-info {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  transition: opacity 0.2s;
}

.user-profile:hover {
  opacity: 0.8;
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f27cf9;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.9rem;
}

.user-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.username {
  font-weight: 600;
  color: #1a1a1a;
  font-size: 1.1rem;
  line-height: 1.2;
}

.user-handle {
  color: #666;
  font-size: 0.9rem;
}

.rating-display {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.rating-display i {
  color: #f27cf9;
  font-size: 1rem;
}

.rating-display i.far {
  color: #e0e0e0;
}

.rating-text {
  color: #666;
  font-size: 0.9rem;
  margin-left: 0.25rem;
}

.review-meta {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.review-date {
  color: #666;
  font-size: 0.85rem;
}

.edited-tag {
  font-size: 0.75rem;
  color: #666;
  background: #f5f5f5;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  cursor: help;
}

.review-content {
  transition: filter 0.3s ease;
  min-height: inherit;
}

.review-content.is-blurred {
  filter: blur(8px);
  pointer-events: none;
}

.review-content.is-blurred {
  filter: blur(8px);
}

.review-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}

.review-text {
  color: #444;
  line-height: 1.6;
  white-space: pre-line;
}

.spoiler-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  /* z-index: 100; */
  border-radius: 8px;
  cursor: pointer;
  transform: translateZ(0);
  backface-visibility: hidden;
  user-select: none;
}

.spoiler-warning {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #e65100;
  font-weight: 600;
  font-size: 1.1rem;
  user-select: none;
}

.spoiler-warning i {
  font-size: 1.2rem;
}

.show-spoiler-btn {
  padding: 0.5rem 1rem;
  background: #f27cf9;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  z-index: 3;
  transform: none;
}

.show-spoiler-btn:hover {
  background: #e065e6;
}

.spoiler-tag {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.4rem 0.8rem;
  background: #fff4e5;
  color: #8b6011;
  border-radius: 6px;
  font-size: 0.85rem;
  font-weight: 500;
}

.no-reviews {
  text-align: center;
  color: #666;
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 12px;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .review-content-wrapper {
    min-height: 120px;
  }

  .review-header {
    flex-direction: column;
    gap: 0.75rem;
  }

  .review-item {
    padding: 1.25rem;
  }

  .review-meta {
    margin-top: 0.5rem;
  }

  .spoiler-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 480px) {
  .reviews-list {
    gap: 1.5rem;
  }

  .review-item {
    padding: 1rem;
  }

  .username {
    font-size: 1rem;
  }

  .review-title {
    font-size: 1rem;
  }

  .rating-display i {
    font-size: 0.9rem;
  }

  .user-profile {
    gap: 0.5rem;
  }

  .profile-picture,
  .profile-placeholder {
    width: 32px;
    height: 32px;
    font-size: 0.8rem;
  }
}
</style>
