import api from './axios'

export const socialApi = {
  // Get all friends
  getFriends: () => api.get('/social/friends/'),

  // Get all friend requests (sent and received)
  getFriendRequests: () => api.get('/social/friends/requests/'),

  // Send friend request
  sendFriendRequest: userId => api.post(`/social/friends/${userId}/request/`),

  // Cancel sent request
  cancelRequest: requestId =>
    api.post(`/social/friends/requests/${requestId}/cancel/`),

  // Accept friend request
  acceptRequest: requestId =>
    api.post(`/social/friends/requests/${requestId}/accept/`),

  // Reject friend request
  rejectRequest: requestId =>
    api.post(`/social/friends/requests/${requestId}/reject/`),

  // Unfriend
  unfriend: friendshipId =>
    api.delete(`/social/friends/${friendshipId}/unfriend/`),

  // Get friendship status with specific user
  getFriendshipStatus: async userId => {
    const response = await api.get(`/social/friends/${userId}/status/`)
    return response.data
  },

  // Get friend recommendations
  getFriendRecommendations: async () => {
    const response = await api.get('/users/friend-recommendations/')
    return response.data
  },

  // Check friendship status with specific user (legacy method maintained for compatibility)
  checkFriendshipStatus: async userId => {
    const response = await api.get(`/social/friends/${userId}/status/`)
    const { status, data } = response.data

    switch (status) {
      case 'friends':
        return {
          status: 'friends',
          friendshipId: data.id,
        }
      case 'pending':
        return {
          status: 'pending',
          requestId: data.id,
        }
      case 'received':
        return {
          status: 'received',
          requestId: data.id,
        }
      default:
        return { status: 'none' }
    }
  },
}