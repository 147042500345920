<template>
  <div>
    <template v-if="loadingBooks">
      <div class="loading-state">
        <div class="loader"></div>
        <p>Loading books...</p>
      </div>
    </template>

    <template v-else>
      <div class="books-grid-container">
        <div :class="['books-grid', viewMode === 'grid' ? 'grid-view' : 'list-view']">
          <template v-if="books.length === 0">
            <div class="no-books">
              <p>No books found</p>
            </div>
          </template>
          <template v-else>
            <template v-if="viewMode === 'grid'">
              <BookCardWithCart v-for="book in books" :key="book.id" :book="book" class="book-card" />
            </template>
            <template v-else>
              <BookCardWithCartListView v-for="book in books" :key="book.id" :book="book" />
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BookCardWithCart from '@/components/shared/BookCardWithCart.vue'
import BookCardWithCartListView from '@/components/shared/BookCardWithCartListView.vue'

export default {
  name: 'BooksGrid',
  components: {
    BookCardWithCart,
    BookCardWithCartListView
  },
  props: {
    books: {
      type: Array,
      required: true,
    },
    loadingBooks: {
      type: Boolean,
      required: true,
    },
    viewMode: {
      type: String,
      required: true,
    }
  }
}
</script>

<style scoped>
.books-grid {
  display: grid;
  gap: 1rem;
}

.grid-view {
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 2rem;
}

.list-view {
  grid-template-columns: 1fr;
}

.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  color: #666;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #f27cf9;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.no-books {
  grid-column: 1 / -1;
  text-align: center;
  padding: 2rem;
  color: #666;
  font-size: 1.1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .grid-view {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .grid-view {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .grid-view {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 0.75rem;
  }
}
</style>