export function handleRouteTransitions(to, from) {
  // Remove old transition classes
  if (from.meta.transition) {
    document.documentElement.classList.remove(`${from.meta.transition}-leave`)
  }
  if (to.meta.transition) {
    document.documentElement.classList.remove(`${to.meta.transition}-enter`)
  }

  // Add new transition classes
  if (from.meta.transition) {
    document.documentElement.classList.add(`${from.meta.transition}-leave`)
  }
  if (to.meta.transition) {
    document.documentElement.classList.add(`${to.meta.transition}-enter`)
  }
}
