<template>
    <div class="section-content">
        <div class="section-header">
            <router-link to="/account/orders" class="back-btn">
                <i class="fas fa-arrow-left"></i>
                Back to Orders
            </router-link>
            <h2 class="section-title">
                <i class="fas fa-shopping-cart section-icon"></i>
                Order Details
            </h2>
        </div>

        <div v-if="loading" class="loading-state">
            <i class="fas fa-spinner fa-spin"></i>
            Loading...
        </div>
        <div v-else-if="error" class="error-state">
            <i class="fas fa-exclamation-circle"></i>
            {{ error }}
        </div>
        <div v-else-if="!order" class="empty-state">
            <i class="fas fa-exclamation-triangle"></i>
            <p>Order not found</p>
        </div>
        <div v-else class="order-detail">
            <div class="order-info-card">
                <div class="order-header">
                    <h3>Order #{{ order.order_id.slice(0, 8) }}</h3>
                    <div class="status-wrapper">
                        <span :class="['status-badge', { completed: order.is_completed }]">
                            {{ order.is_completed ? 'Completed' : 'Pending' }}
                        </span>
                        <router-link v-if="!order.is_completed" :to="`/payment/${order.order_id}`" class="pay-btn">
                            <i class="fas fa-credit-card"></i>
                            Pay Now
                        </router-link>
                    </div>
                </div>
                <div class="info-grid">
                    <div class="info-item">
                        <span class="label">Order Date</span>
                        <span class="value">{{ formatDate(order.created_at) }}</span>
                    </div>
                    <div class="info-item">
                        <span class="label">Total Amount</span>
                        <span class="value amount">${{ order.total_amount }}</span>
                    </div>
                </div>
            </div>

            <div class="shipping-card">
                <h3 class="card-title">
                    <i class="fas fa-shipping-fast"></i>
                    Shipping Details
                </h3>
                <div class="shipping-info">
                    <p class="name">{{ order.shipping_address.full_name }}</p>
                    <p class="address">{{ order.shipping_address.address_name }}</p>
                </div>
            </div>

            <div class="items-card">
                <h3 class="card-title">
                    <i class="fas fa-book"></i>
                    Order Items
                </h3>
                <div class="items-list">
                    <div v-for="item in order.items" :key="item.book.id" class="item">
                        <div class="item-image">
                            <img :src="item.book.cover_image" :alt="item.book.title">
                        </div>
                        <div class="item-details">
                            <h4 class="book-title">{{ item.book.title }}</h4>
                            <p class="author">by {{ item.book.author.name }}</p>
                            <div class="purchase-info">
                                <span class="quantity">Qty: {{ item.quantity }}</span>
                                <span class="type">{{ item.purchase_type }}</span>
                                <span class="price">${{ item.price }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/axios'

export default {
    name: 'OrderDetail',
    data() {
        return {
            loading: false,
            error: null,
            order: null
        }
    },
    methods: {
        formatDate(date) {
            return new Date(date).toLocaleString()
        },
        async fetchOrder() {
            try {
                this.loading = true
                this.error = null
                const response = await api.get(`/order/${this.$route.params.id}/`)
                if (response.data.success) {
                    this.order = response.data.order
                }
            } catch (error) {
                console.error('Error fetching order:', error)
                this.error = 'Failed to load order details'
            } finally {
                this.loading = false
            }
        }
    },
    created() {
        this.fetchOrder()
    }
}
</script>

<style scoped>
.section-content {
    padding: 1rem 0;
}

.section-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
}

.back-btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-secondary);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease;
}

.back-btn:hover {
    color: var(--text-primary);
}

.section-title {
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.section-icon {
    color: #f27cf9;
}

.order-detail {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 800px;
    margin: 0 auto;
}

.order-info-card,
.shipping-card,
.items-card {
    background: var(--background-white);
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.status-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.order-header h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0;
}

.status-badge {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    font-weight: 500;
    background: #fee2e2;
    color: #dc2626;
}

.status-badge.completed {
    background: #dcfce7;
    color: #059669;
}

.pay-btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    background: #f27cf9;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.2s ease;
}

.pay-btn:hover {
    background: #e554f3;
    transform: translateY(-1px);
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
}

.info-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.label {
    font-size: 0.9rem;
    color: var(--text-secondary);
}

.value {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--text-primary);
}

.value.amount {
    color: #059669;
    font-weight: 600;
}

.card-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
}

.shipping-info {
    color: var(--text-secondary);
}

.shipping-info .name {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
}

.items-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.item {
    display: flex;
    gap: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--border-color);
}

.item:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.item-image {
    width: 100px;
    height: 150px;
    flex-shrink: 0;
}

.item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item-details {
    flex-grow: 1;
}

.book-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0 0 0.5rem 0;
}

.author {
    color: var(--text-secondary);
    margin: 0 0 1rem 0;
}

.purchase-info {
    display: flex;
    gap: 1.5rem;
    color: var(--text-secondary);
    font-weight: 500;
}

.price {
    font-weight: 600;
    color: #059669;
}

.loading-state,
.error-state,
.empty-state {
    text-align: center;
    padding: 3rem;
    color: var(--text-secondary);
    font-size: 1.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.loading-state i,
.error-state i,
.empty-state i {
    font-size: 2.5rem;
    color: var(--text-secondary);
}

.error-state {
    color: #dc2626;
}

.error-state i {
    color: #dc2626;
}

@media (max-width: 768px) {

    .order-info-card,
    .shipping-card,
    .items-card {
        padding: 1.5rem;
    }

    .order-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }

    .status-wrapper {
        width: 100%;
        flex-direction: column;
        align-items: stretch;
        gap: 0.75rem;
    }

    .pay-btn {
        width: 100%;
        justify-content: center;
    }

    .info-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

@media (max-width: 480px) {
    .item {
        flex-direction: column;
        gap: 1rem;
    }

    .item-image {
        width: 100%;
        height: 200px;
    }

    .purchase-info {
        flex-wrap: wrap;
        gap: 1rem;
    }

    .section-title {
        font-size: 1.5rem;
    }

    .card-title {
        font-size: 1.1rem;
    }

    .book-title {
        font-size: 1.1rem;
    }
}
</style>