<template>
  <div class="catalog-header">
    <div class="breadcrumb">
      <a href="/home" class="crumb">Home</a>
      <span class="separator">/</span>
      <span class="current">Books</span>
    </div>
    <div class="sort-options">
      <div class="default-sort">
        <select
          v-model="filters.ordering"
          @change="applyFilters"
          class="sort-select"
        >
          <option value="">Default sorting</option>
          <option value="price">Price low to high</option>
          <option value="-price">Price high to low</option>
          <option value="title">Title A to Z</option>
          <option value="-title">Title Z to A</option>
        </select>
      </div>
      <div class="view-toggles">
        <button
          @click="setViewMode('grid')"
          class="view-btn"
          :class="{ active: viewMode === 'grid' }"
          aria-label="Grid view"
        >
          <i class="fas fa-th-large"></i>
        </button>
        <button
          @click="setViewMode('list')"
          class="view-btn"
          :class="{ active: viewMode === 'list' }"
          aria-label="List view"
        >
          <i class="fas fa-list"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CatalogHeader",
  props: {
    filters: {
      type: Object,
      required: true,
    },
    viewMode: {
      type: String,
      required: true,
    },
    setViewMode: {
      type: Function,
      required: true,
    },
    applyFilters: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Catalog Header Styles */
.catalog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.crumb {
  color: var(--text-light);
  text-decoration: none;
  font-size: 0.9rem;
}

.crumb:hover {
  color: var(--primary-color);
}

.separator {
  color: #ccc;
}

.current {
  color: var(--text-dark);
  font-weight: 500;
}

.sort-options {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.default-sort {
  position: relative;
  min-width: 200px;
}

.sort-select {
  width: 100%;
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background: white;
  font-size: 0.9rem;
  color: var(--text-dark);
  appearance: none;
  cursor: pointer;
}

.default-sort::after {
  content: "";
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--text-light);
  pointer-events: none;
}

.view-toggles {
  display: flex;
  gap: 0.5rem;
}

.view-btn {
  padding: 0.75rem;
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  cursor: pointer;
  color: var(--text-light);
  transition: all 0.2s ease;
}

.view-btn.active {
  background: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}
</style>
