import { createStore } from 'vuex'
import api from '../api/axios'
import router from '../router'
import socialModule from './modules/social'

const clearStorage = () => {
  localStorage.removeItem('accessToken')
  sessionStorage.removeItem('accessToken')
  delete api.defaults.headers.common['Authorization']
}

export default createStore({
  modules: {
    social: socialModule,
  },
  state: {
    accessToken:
      localStorage.getItem('accessToken') ||
      sessionStorage.getItem('accessToken') ||
      null,
    user: null,
    userProfile: null,
  },

  mutations: {
    SET_AUTH_TOKEN(state, { token, remember }) {
      state.accessToken = token
      const storage = remember ? localStorage : sessionStorage

      // Clear both storages first
      localStorage.removeItem('accessToken')
      sessionStorage.removeItem('accessToken')

      storage.setItem('accessToken', token)

      // Update axios headers
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`
    },

    SET_USER(state, user) {
      state.user = user
    },

    SET_USER_PROFILE(state, profile) {
      state.userProfile = profile
    },

    CLEAR_AUTH(state) {
      state.accessToken = null
      state.user = null
      state.userProfile = null

      // Clear token from both storage and headers
      localStorage.removeItem('accessToken')
      sessionStorage.removeItem('accessToken')
      delete api.defaults.headers.common['Authorization']
    },
  },

  actions: {
    async initializeAuth({ commit, dispatch }) {
      const token =
        localStorage.getItem('accessToken') ||
        sessionStorage.getItem('accessToken')
      if (token) {
        commit('SET_AUTH_TOKEN', {
          token,
          remember: !!localStorage.getItem('accessToken'),
        })
        await dispatch('fetchUserProfile')
      }
    },

    async fetchUserProfile({ commit, state }) {
      try {
        if (!state.accessToken) return null

        const response = await api.get('/users/profile/')
        if (response.data.success) {
          commit('SET_USER_PROFILE', response.data.data)
          return response.data.data
        }
        return null
      } catch (error) {
        console.error('Error fetching user profile:', error)
        return null
      }
    },

    async register({ dispatch }, userData) {
      try {
        let profilePictureBlob = null

        // Handle profile picture if it exists and is base64
        if (userData.profile_picture?.startsWith('data:')) {
          // Convert base64 to blob
          const base64Data = userData.profile_picture.split(',')[1]
          const byteCharacters = atob(base64Data)
          const byteNumbers = new Array(byteCharacters.length)

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }

          const byteArray = new Uint8Array(byteNumbers)
          profilePictureBlob = new Blob([byteArray], { type: 'image/jpeg' })
        }

        const formData = new FormData()
        formData.append('first_name', userData.firstName)
        formData.append('last_name', userData.lastName)
        formData.append('username', userData.username)
        formData.append('email', userData.email)
        formData.append(
          'date_of_birth',
          new Date(userData.dateOfBirth).toISOString().split('T')[0]
        )
        formData.append('password', userData.password)
        formData.append('bio', userData.status || '')

        // Append terms acceptance data
        formData.append('has_accepted_terms', userData.hasAcceptedTerms)
        formData.append(
          'has_accepted_rental_policy',
          userData.hasAcceptedRentalPolicy
        )
        formData.append('terms_acceptance_date', new Date().toISOString())

        // Append profile picture if exists
        if (profilePictureBlob) {
          formData.append('profile_picture', profilePictureBlob, 'profile.jpg')
        } else if (userData.profile_picture) {
          formData.append('profile_picture', userData.profile_picture)
        }

        const response = await api.post('/users/register/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        if (response.data.success) {
          return dispatch('login', {
            email: userData.email,
            password: userData.password,
            rememberMe: true,
          })
        }

        return { success: true }
      } catch (error) {
        console.error('Registration error:', error.response || error)
        return {
          success: false,
          error:
            error.response?.data?.message ||
            error.response?.data?.error ||
            'Registration failed',
        }
      }
    },

    async login({ commit, dispatch }, { email, password, rememberMe }) {
      try {
        const response = await api.post('/users/login/', { email, password })
        const { access } = response.data.data.tokens
        const userData = response.data.data.user

        commit('SET_AUTH_TOKEN', { token: access, remember: rememberMe })
        commit('SET_USER', userData)
        api.defaults.headers.common['Authorization'] = `Bearer ${access}`

        await dispatch('fetchUserProfile')

        return { success: true }
      } catch (error) {
        return {
          success: false,
          error: error.response?.data?.message || 'Login failed',
        }
      }
    },

    async logout({ commit }) {
      try {
        // Clear all auth state
        commit('CLEAR_AUTH')
        // Clear social state
        commit('social/CLEAR_SOCIAL_STATE', null, { root: true })

        if (router.currentRoute.value.name !== 'login') {
          await router.push('/login')
        }
      } catch (error) {
        console.error('Logout error:', error)
      }
    },
  },

  getters: {
    isAuthenticated: state => !!state.accessToken,
    getToken: state => state.accessToken,
    getUser: state => state.user,
    getUserProfile: state => state.userProfile,
    authHeader: state =>
      state.accessToken ? `Bearer ${state.accessToken}` : '',
  },
})
