<template>
  <section class="faq-section">
    <h2 class="section-title">Frequently Ask Questions</h2>
    <div class="faq-list">
      <div
        v-for="(faq, index) in faqs"
        :key="index"
        class="faq-item"
        :class="{ active: activeIndex === index }"
        @click="toggleFaq(index)"
      >
        <div class="faq-question">
          <h3>{{ faq.question }}</h3>
          <i
            class="fas"
            :class="activeIndex === index ? 'fa-minus' : 'fa-plus'"
          ></i>
        </div>
        <div class="faq-answer" v-show="activeIndex === index">
          <p>{{ faq.answer }}</p>
        </div>
      </div>
    </div>
    <!-- Separate function for the button if it has a different purpose -->
    <button class="more-faq" @click="showMoreFaqs">Find More FAQs</button>
  </section>
</template>

<script>
export default {
  props: {
    faqs: Array,
    activeIndex: Number,
  },
  methods: {
    toggleFaq(index) {
      // Toggle between expanding and collapsing the FAQ item
      this.$emit('toggle', index === this.activeIndex ? null : index)
    },
    showMoreFaqs() {
      this.$emit('show-more')
    },
  },
}
</script>

<style>
.faq-section {
  margin: 1rem 0;
  padding: 3rem;
}

.faq-item {
  border-bottom: 1px solid #e0e0e0;
  padding: 1.5rem 0;
  cursor: pointer;
  margin: 1rem 0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question h3 {
  font-size: 1.1rem;
  color: #282772;
}

.faq-answer {
  padding-top: 1rem;
  color: #666;
  line-height: 1.6;
}

.more-faq {
  display: block;
  margin: 1.5rem auto;
  background: #f27cf9;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 30px;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .category-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .hero-text h1 {
    font-size: 40px;
  }

  .deals-grid {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

@media (max-width: 992px) {
  .hero-content {
    flex-direction: column;
    text-align: center;
  }

  .search-form {
    margin: 0 auto;
  }

  .books-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  .steps {
    flex-direction: column;
  }

  .step-line {
    width: 2px;
    height: 40px;
  }

  .newsletter-content {
    flex-direction: column;
    text-align: center;
  }

  .countdown {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }
  .category-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .hero-text h1 {
    font-size: 32px;
  }

  .search-filters {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .category-grid {
    grid-template-columns: 1fr;
  }

  .books-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 15px;
  }

  .hero-section {
    padding: 20px;
  }

  .book-info {
    padding: 10px;
  }

  .price {
    font-size: 16px;
  }

  .add-cart {
    padding: 6px 12px;
    font-size: 12px;
  }
}
</style>
