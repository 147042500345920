<template>
  <div class="cart-items-container">
    <table class="cart-table">
      <thead>
        <tr>
          <th class="checkbox-cell"></th>
          <th class="image-cell">Book</th>
          <th>Product</th>
          <th class="type-cell">Type</th>
          <th class="price-cell">Price</th>
          <th class="quantity-cell">Quantity</th>
          <th class="total-cell">Total</th>
          <th class="action-cell"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in localItems" :key="item.id" class="cart-item">
          <td class="checkbox-cell">
            <input
              type="checkbox"
              v-model="item.selected"
              @change="handleSelectionChange(item)"
            />
          </td>
          <td class="image-cell">
            <img
              :src="item.book.cover_image"
              :alt="item.book.title"
              class="book-image"
            />
          </td>
          <td class="product-cell">
            <h3 class="product-title">{{ item.book.title }}</h3>
            <p class="author">
              {{ item.book.author.name }} |
              {{ formatDate(item.book.date_published) }} |
              {{ item.book.format }}
            </p>
            <p class="category">{{ item.book.language }}</p>
          </td>
          <td class="type-cell">
            <div class="type-container">
              <select
                v-model="item.type"
                class="type-select"
                @change="handleTypeChange(item)"
              >
                <option value="buy">Buy</option>
                <option value="rent">Rent</option>
              </select>
              <select
                v-if="item.type === 'rent'"
                v-model="item.rentalPeriod"
                class="rental-period-select"
                @change="handleRentalPeriodChange(item)"
              >
                <option value="15">15 days</option>
                <option value="30">30 days</option>
                <option value="45">45 days</option>
              </select>
            </div>
          </td>
          <td class="price-cell">
            Rs {{ parseFloat(item.book.price).toFixed(2) }}
          </td>
          <td class="quantity-cell">
            <div
              class="quantity-controls"
              :class="{ disabled: item.type === 'rent' }"
            >
              <button
                @click="handleDecrement(item)"
                :disabled="item.quantity <= 1 || item.type === 'rent'"
                class="quantity-btn"
              >
                -
              </button>
              <input
                type="number"
                v-model.number="item.quantity"
                min="1"
                @input="handleQuantityInput(item)"
                class="quantity-input"
                :disabled="item.type === 'rent'"
                :class="{ 'rent-disabled': item.type === 'rent' }"
              />
              <button
                @click="handleIncrement(item)"
                :disabled="item.type === 'rent'"
                class="quantity-btn"
              >
                +
              </button>
            </div>
          </td>
          <td class="total-cell">Rs {{ calculateItemTotal(item) }}</td>
          <td class="action-cell">
            <button class="remove-btn desktop-remove" @click="removeItem(item)">
              ×
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="mobile-cart-items">
      <div v-for="item in localItems" :key="item.id" class="mobile-cart-item">
        <button class="mobile-remove-btn" @click="removeItem(item)">×</button>

        <div class="mobile-content">
          <div class="mobile-checkbox">
            <input
              type="checkbox"
              v-model="item.selected"
              @change="handleSelectionChange(item)"
            />
          </div>
          <img
            :src="item.book.cover_image"
            :alt="item.book.title"
            class="mobile-image"
          />
          <div class="mobile-details">
            <h3>{{ item.book.title }}</h3>
            <p class="author">{{ item.book.author.name }}</p>
            <p class="specs">
              {{ formatDate(item.book.date_published) }} |
              {{ item.book.format }}
            </p>
            <p class="category">{{ item.book.language }}</p>
          </div>
        </div>

        <div class="mobile-purchase-options">
          <div class="mobile-type-controls">
            <select
              v-model="item.type"
              class="type-select"
              @change="handleTypeChange(item)"
            >
              <option value="buy">Buy</option>
              <option value="rent">Rent</option>
            </select>
            <select
              v-if="item.type === 'rent'"
              v-model="item.rentalPeriod"
              class="rental-period-select"
              @change="handleRentalPeriodChange(item)"
            >
              <option value="15">15 days</option>
              <option value="30">30 days</option>
              <option value="45">45 days</option>
            </select>
          </div>

          <div class="mobile-price-section">
            <div class="price-info">
              <span class="price-label">Price:</span>
              <span class="price-value"
                >Rs {{ parseFloat(item.book.price).toFixed(2) }}</span
              >
            </div>
            <div
              class="quantity-controls"
              :class="{ disabled: item.type === 'rent' }"
            >
              <button
                @click="handleDecrement(item)"
                :disabled="item.quantity <= 1 || item.type === 'rent'"
                class="quantity-btn"
              >
                −
              </button>
              <input
                type="number"
                v-model.number="item.quantity"
                min="1"
                @input="handleQuantityInput(item)"
                class="quantity-input"
                :disabled="item.type === 'rent'"
                :class="{ 'rent-disabled': item.type === 'rent' }"
              />
              <button
                @click="handleIncrement(item)"
                :disabled="item.type === 'rent'"
                class="quantity-btn"
              >
                +
              </button>
            </div>
          </div>
        </div>

        <div class="mobile-footer">
          <div class="mobile-total">
            <span class="total-label">Total:</span>
            <span class="total-value">Rs {{ calculateItemTotal(item) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CartItems',

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  emits: [
    'quantity-change',
    'selection-change',
    'type-change',
    'remove-item',
    'rental-period-change',
  ],

  data() {
    return {
      localItems: [],
    }
  },

  watch: {
    items: {
      immediate: true,
      handler(newItems) {
        this.localItems = JSON.parse(JSON.stringify(newItems)).map(item => ({
          ...item,
          selected: false, // Add selected property for checkbox
          type: item.purchase_type || 'buy',
          rentalPeriod: item.rental_period_id
            ? String(item.rental_period_id * 15)
            : '15',
        }))
      },
    },
  },

  methods: {
    formatDate(dateString) {
      return new Date(dateString).getFullYear()
    },

    calculateItemTotal(item) {
      const price = parseFloat(item.book.price)
      return (price * item.quantity).toFixed(2)
    },

    handleIncrement(item) {
      if (item.type !== 'rent') {
        item.quantity += 1
        this.$emit('quantity-change', {
          itemId: item.id,
          quantity: item.quantity,
          purchase_type: item.type,
        })
      }
    },

    handleDecrement(item) {
      if (item.type !== 'rent' && item.quantity > 1) {
        item.quantity -= 1
        this.$emit('quantity-change', {
          itemId: item.id,
          quantity: item.quantity,
          purchase_type: item.type,
        })
      }
    },

    handleQuantityInput(item) {
      if (item.type === 'rent') {
        item.quantity = 1
      } else {
        if (item.quantity < 1) {
          item.quantity = 1
        }
        item.quantity = parseInt(item.quantity) || 1
      }

      this.$emit('quantity-change', {
        itemId: item.id,
        quantity: item.quantity,
        purchase_type: item.type,
      })
    },

    handleSelectionChange(item) {
      // Simply emit the selection change to parent
      this.$emit('selection-change', {
        itemId: item.id,
        selected: item.selected,
      })
    },

    handleTypeChange(item) {
      if (item.type === 'rent') {
        item.quantity = 1
        if (!item.rentalPeriod) {
          item.rentalPeriod = '15'
        }
      }

      // Convert rental period to ID when emitting
      const rental_period_id =
        item.type === 'rent'
          ? parseInt(item.rentalPeriod) === 15
            ? 1
            : parseInt(item.rentalPeriod) === 30
            ? 2
            : 3
          : null

      this.$emit('type-change', {
        itemId: item.id,
        purchase_type: item.type,
        rental_period_id,
      })

      this.emitUpdatedItem(item)
    },

    handleRentalPeriodChange(item) {
      // Convert days to rental_period_id
      let rental_period_id
      switch (item.rentalPeriod) {
        case '15':
          rental_period_id = 1
          break
        case '30':
          rental_period_id = 2
          break
        case '45':
          rental_period_id = 3
          break
        default:
          rental_period_id = 1
      }

      this.$emit('rental-period-change', {
        itemId: item.id,
        rental_period_id: rental_period_id,
      })
    },

    removeItem(item) {
      this.$emit('remove-item', item.id)
    },

    emitUpdatedItem(item) {
      this.$emit('quantity-change', {
        itemId: item.id,
        quantity: item.quantity,
      })
    },
  },
}
</script>

<style scoped>
/* Desktop Styles */
.cart-items-container {
  width: 100%;
}

.cart-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 1.5rem;
  display: none;
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
}

.cart-table th {
  background: #f8f9fa;
  padding: 1.25rem 1rem;
  text-align: left;
  vertical-align: middle;
  border-bottom: 2px solid #eee;
  font-weight: 600;
  color: #333;
}

.cart-table td {
  padding: 1.5rem 1rem;
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid #eee;
}

.cart-table tr:last-child td {
  border-bottom: none;
}

.checkbox-cell {
  width: 40px;
  text-align: center;
}

.image-cell {
  width: 120px;
}

.product-cell {
  min-width: 300px;
}

.type-cell {
  min-width: 140px;
}

.price-cell,
.total-cell {
  width: 100px;
  text-align: right;
  font-weight: 500;
}

.quantity-cell {
  width: 150px;
}

.action-cell {
  width: 50px;
  text-align: center;
}

.product-title {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #282772;
}

.author {
  color: #666;
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
}

.category {
  color: #888;
  font-size: 0.85rem;
}

.book-image {
  width: 100px;
  height: 140px;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Common Form Elements */
.type-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.type-select,
.rental-period-select {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  width: 100px;
  font-size: 0.9rem;
  color: #333;
}

.rental-period-select {
  margin-top: 0.25rem;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.quantity-controls.disabled {
  opacity: 0.7;
}

.quantity-btn {
  width: 32px;
  height: 32px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  transition: all 0.2s ease;
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #f5f5f5;
}

.quantity-btn:hover:not(:disabled) {
  background: #f5f5f5;
  border-color: #ccc;
}

.quantity-input {
  width: 60px;
  height: 32px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.95rem;
}

.quantity-input.rent-disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.desktop-remove.remove-btn {
  background: none;
  border: none;
  color: #ff4444;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  line-height: 1;
  transition: all 0.2s ease;
}

.desktop-remove.remove-btn:hover {
  background-color: #ffeeee;
  transform: scale(1.1);
}

/* Mobile Styles */
.mobile-cart-item {
  background: white;
  border-radius: 12px;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  position: relative;
}

.mobile-cart-item {
  background: white;
  border-radius: 12px;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.mobile-remove-btn {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background: none;
  border: none;
  color: #ff4444;
  font-size: 1.5rem;
  line-height: 1;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.mobile-remove-btn:hover {
  background-color: #ffeeee;
  transform: scale(1.1);
}

.mobile-content {
  display: grid;
  grid-template-columns: auto 100px 1fr;
  gap: 1rem;
  align-items: start;
  margin-bottom: 1rem;
}

.mobile-checkbox {
  padding-top: 0.5rem;
}

.mobile-image {
  width: 100px;
  height: 140px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mobile-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.mobile-details h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #282772;
  margin: 0;
  line-height: 1.3;
}

.mobile-details .author {
  font-size: 0.9rem;
  color: #555;
  margin: 0;
}

.mobile-details .specs {
  font-size: 0.85rem;
  color: #666;
  margin: 0;
}

.mobile-details .category {
  font-size: 0.85rem;
  color: #888;
  margin: 0;
}

.mobile-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}

.mobile-purchase-options {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mobile-type-controls {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.mobile-total {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.mobile-price-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.price-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.price-label {
  font-size: 0.9rem;
  color: #666;
}

.price-value {
  font-weight: 600;
  color: #282772;
}

.mobile-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}

.total-label {
  font-size: 0.9rem;
  color: #666;
}

.total-value {
  font-size: 1.1rem;
  font-weight: 600;
  color: #282772;
}

.mobile-footer .remove-btn {
  background: #ff4444;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.mobile-footer .remove-btn:hover {
  background-color: #ff2222;
}

input[type='checkbox'] {
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 2px solid #ddd;
  border-radius: 4px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (min-width: 768px) {
  .cart-table {
    display: table;
  }

  .mobile-cart-items {
    display: none;
  }
}

@media (max-width: 768px) {
  .cart-items-container {
    padding: 0.5rem;
  }

  .mobile-cart-items {
    display: block;
  }

  .quantity-btn {
    width: 28px;
    height: 28px;
    font-size: 1rem;
  }

  .quantity-input {
    width: 50px;
    height: 28px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .cart-items-container {
    padding: 0.25rem;
  }

  .mobile-content {
    grid-template-columns: auto 80px 1fr;
  }

  .mobile-image {
    width: 80px;
    height: 112px;
  }

  .mobile-remove-btn {
    top: 0.5rem;
    right: 0.5rem;
  }

  .mobile-details h3 {
    font-size: 0.95rem;
  }

  .mobile-details .author {
    font-size: 0.85rem;
  }

  .mobile-details .specs,
  .mobile-details .category {
    font-size: 0.8rem;
  }

  .mobile-type-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .type-select,
  .rental-period-select {
    width: 100%;
    max-width: none;
  }

  .mobile-price-section {
    flex-direction: column;
    align-items: stretch;
    gap: 0.75rem;
  }

  .price-info {
    justify-content: space-between;
  }

  .mobile-footer {
    margin-top: 0.5rem;
  }

  .mobile-total {
    justify-content: space-between;
  }

  .mobile-footer .remove-btn {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
  }

  .quantity-controls {
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 360px) {
  .mobile-content {
    grid-template-columns: auto 70px 1fr;
  }

  .mobile-remove-btn {
    font-size: 1.25rem;
    width: 20px;
    height: 20px;
  }

  .mobile-image {
    width: 70px;
    height: 98px;
  }

  .mobile-details h3 {
    font-size: 0.9rem;
  }

  .mobile-details .author,
  .mobile-details .specs,
  .mobile-details .category {
    font-size: 0.75rem;
  }

  .price-label,
  .price-value,
  .total-label {
    font-size: 0.85rem;
  }

  .total-value {
    font-size: 1rem;
  }

  .quantity-btn {
    width: 26px;
    height: 26px;
  }

  .quantity-input {
    width: 45px;
    height: 26px;
    font-size: 0.85rem;
  }
}

/* Print styles */
@media print {
  .cart-table {
    display: table !important;
    border: 1px solid #000;
  }

  .mobile-cart-items {
    display: none !important;
  }

  .cart-table th {
    background: #f0f0f0 !important;
    color: #000 !important;
  }

  .remove-btn,
  .quantity-controls {
    display: none;
  }

  .cart-items-container {
    padding: 0;
  }
}
</style>
