import SocialPage2 from '@/views/social/SocialPage.vue'

export default [
  {
    path: '/social',
    name: 'social',
    component: SocialPage2,
    meta: {
      requiresAuth: true,
      title: 'Book Social',
      transition: 'slide-fade',
    },
  },
]
