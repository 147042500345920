<template>
  <div class="search-page">
    <div class="search-container">
      <SearchHeader :searchType="searchType" @update:searchType="searchType = $event" />

      <SearchInput v-model="searchQuery" :searchType="searchType" :loading="loading" @search="handleSearch"
        @clear="clearSearch" />

      <SearchResults :loading="loading" :error="error" :searchQuery="searchQuery" :books="books" @retry="handleSearch"
        @add-to-cart="addToCart" />

      <!-- Pagination controls -->
      <div v-if="totalItems > 0" class="pagination-controls">
        <button @click="changePage(currentPage - 1)" :disabled="!previousPage || loading" class="pagination-btn">
          Previous
        </button>

        <span class="pagination-info">
          Page {{ currentPage }} | Total Results: {{ totalItems }}
        </span>

        <button @click="changePage(currentPage + 1)" :disabled="!nextPage || loading" class="pagination-btn">
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import api from '@/api/axios'
import { debounce } from 'lodash'

import SearchHeader from '@/components/search/SearchHeader.vue'
import SearchInput from '@/components/search/SearchInput.vue'
import SearchResults from '@/components/search/SearchResults.vue'

export default {
  name: 'SearchPage',
  components: {
    SearchHeader,
    SearchInput,
    SearchResults,
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    // Existing refs
    const searchQuery = ref('')
    const searchType = ref('title')
    const books = ref([])
    const loading = ref(false)
    const error = ref(null)

    // Pagination refs
    const currentPage = ref(1)
    const totalItems = ref(0)
    const nextPage = ref(null)
    const previousPage = ref(null)

    const handleSearch = async (initialSearch = false) => {
      if (!searchQuery.value.trim()) {
        books.value = []
        return
      }

      loading.value = true
      error.value = null

      try {
        const params = new URLSearchParams()

        // Add pagination parameter
        params.append('page', currentPage.value)

        // Handle both title and author search
        if (route.query.title && (searchType.value === 'title' || initialSearch)) {
          params.append('title', route.query.title)
        }
        if (route.query.author && (searchType.value === 'author' || initialSearch)) {
          params.append('author', route.query.author)
        }

        // If it's not an initial search, use the current search type and query
        if (!initialSearch) {
          if (searchType.value === 'title') {
            params.append('title', searchQuery.value)
          } else {
            params.append('author', searchQuery.value)
          }
        }

        const response = await api.get('/books/', { params })

        if (response.data?.status === 'success') {
          books.value = response.data.results['book(s)'] || []
          totalItems.value = response.data.count
          nextPage.value = response.data.next
          previousPage.value = response.data.previous
        } else {
          throw new Error('Invalid response format')
        }
      } catch (err) {
        console.error('Search error:', err)
        error.value = 'Failed to search books. Please try again.'
      } finally {
        loading.value = false
      }
    }

    const debouncedSearch = debounce(handleSearch, 300)

    const clearSearch = () => {
      searchQuery.value = ''
      books.value = []
      currentPage.value = 1
      totalItems.value = 0
      nextPage.value = null
      previousPage.value = null
      // Clear URL parameters
      router.replace({ query: {} })
    }

    const changePage = (newPage) => {
      currentPage.value = newPage
      // Scroll to top of results
      window.scrollTo({ top: 0, behavior: 'smooth' })
      handleSearch(false)
    }

    const addToCart = async book => {
      try {
        if (!store.getters.isAuthenticated) {
          router.push({
            path: '/login',
            query: { redirect: router.currentRoute.value.fullPath },
          })
          return
        }

        loading.value = true
        const response = await api.post(
          `/cart/${book.id}/add`,
          { quantity: 1 },
          {
            headers: {
              Authorization: `Bearer ${store.getters.token}`,
            },
          }
        )

        if (response.data.success) {
          router.push('/cart')
        }
      } catch (err) {
        console.error('Error adding to cart:', err)
        error.value = 'Failed to add book to cart'
      } finally {
        loading.value = false
      }
    }

    // Watch for changes
    watch(searchType, () => {
      if (searchQuery.value) {
        currentPage.value = 1  // Reset to first page on search type change
        debouncedSearch()
      }
    })

    watch(searchQuery, () => {
      currentPage.value = 1  // Reset to first page on new search
      debouncedSearch()
    })

    // Watch for route query changes
    watch(
      () => route.query,
      newQuery => {
        if (newQuery.title || newQuery.author) {
          searchType.value = newQuery.title ? 'title' : 'author'
          searchQuery.value = newQuery.title || newQuery.author
          currentPage.value = 1  // Reset to first page
          handleSearch(true)
        }
      },
      { immediate: true }
    )

    onMounted(() => {
      if (route.query.title || route.query.author) {
        searchType.value = route.query.title ? 'title' : 'author'
        searchQuery.value = route.query.title || route.query.author
        handleSearch(true)
      }
    })

    return {
      searchQuery,
      searchType,
      books,
      loading,
      error,
      currentPage,
      totalItems,
      nextPage,
      previousPage,
      handleSearch: () => handleSearch(false),
      clearSearch,
      addToCart,
      changePage,
    }
  },
}
</script>

<style scoped>
.search-page {
  min-height: 100vh;
  padding: 2rem;
  background: #f8f9fa;
}

.search-container {
  max-width: 1200px;
  margin: 0 auto;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  padding: 1rem 0;
}

.pagination-btn {
  padding: 0.5rem 1rem;
  background-color: #ff8fe5;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.2s;
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-info {
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .search-page {
    padding: 1rem;
  }

  .pagination-controls {
    flex-direction: row;
    gap: 0.5rem;
  }

  .pagination-btn {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }
}
</style>