<template>
  <div class="billing-page">
    <billing-header />

    <div class="billing-container">
      <billing-form :form="form" @form-updated="updateForm" />

      <order-summary
        :order-items="orderItems"
        :totals="orderTotals"
        :payment-method="paymentMethod"
        @payment-method-changed="updatePaymentMethod"
        @place-order="placeOrder"
      />
    </div>
  </div>
</template>

<script>
import BillingHeader from '@/components/checkout/BillingHeader.vue'
import BillingForm from '@/components/checkout/BillingForm.vue'
import OrderSummary from '@/components/checkout/OrderSummary.vue'

export default {
  name: 'BillingPage',

  components: {
    BillingHeader,
    BillingForm,
    OrderSummary,
  },

  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        streetAddress: '',
        country: '',
        province: '',
        city: '',
        zipCode: '',
        phone: '',
        email: '',
        notes: '',
      },
      paymentMethod: 'bank',
      orderItems: [
        {
          name: 'Biography of Maria Strovensky',
          format: 'Paperback',
          quantity: 1,
          price: 43.0,
        },
        {
          name: 'Mental Health Awareness',
          format: 'Kindle',
          quantity: 2,
          price: 60.0,
        },
        {
          name: "Don't Mix 'Em",
          format: 'Kindle',
          quantity: 1,
          price: 19.0,
        },
        {
          name: 'Company of One',
          format: 'Kindle',
          quantity: 1,
          price: 19.0,
        },
      ],
      orderTotals: {
        subtotal: 150.0,
        shipping: 0.0,
        coupon: 10.0,
        discount: 0.0,
        total: 140.0,
      },
    }
  },

  methods: {
    updateForm(formData) {
      this.form = { ...formData }
    },

    updatePaymentMethod(method) {
      this.paymentMethod = method
    },

    placeOrder() {
      console.log('Order placed', {
        billingDetails: this.form,
        paymentMethod: this.paymentMethod,
        orderTotals: this.orderTotals,
      })
    },
  },
}
</script>

<style scoped>
.billing-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: system-ui, -apple-system, sans-serif;
}

.billing-container {
  display: flex;
  gap: 30px;
}

@media (max-width: 768px) {
  .billing-container {
    flex-direction: column;
  }
}
</style>
