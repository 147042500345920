import { socialApi } from '@/api/social'

export default {
  namespaced: true,

  state: {
    friends: [],
    friendRequests: {
      sent: [],
      received: [],
    },
    // Cache friendship statuses by userId
    friendshipStatuses: {},
  },

  mutations: {
    SET_FRIENDS(state, friends) {
      state.friends = friends
    },

    SET_FRIEND_REQUESTS(state, requests) {
      state.friendRequests = requests
    },

    SET_FRIENDSHIP_STATUS(state, { userId, status }) {
      state.friendshipStatuses[userId] = status
    },

    REMOVE_FRIEND(state, friendshipId) {
      state.friends = state.friends.filter(
        friend => friend.friendship_id !== friendshipId
      )
    },

    REMOVE_SENT_REQUEST(state, requestId) {
      state.friendRequests.sent = state.friendRequests.sent.filter(
        req => req.id !== requestId
      )
    },

    REMOVE_RECEIVED_REQUEST(state, requestId) {
      state.friendRequests.received = state.friendRequests.received.filter(
        req => req.id !== requestId
      )
    },

    ADD_SENT_REQUEST(state, request) {
      state.friendRequests.sent.push(request)
    },

    ADD_FRIEND(state, friend) {
      state.friends.push(friend)
    },

    CLEAR_SOCIAL_STATE(state) {
      state.friends = []
      state.friendRequests = { sent: [], received: [] }
      state.friendshipStatuses = {}
    },
  },

  actions: {
    async fetchFriends({ commit }) {
      try {
        const response = await socialApi.getFriends()
        if (response.data.success) {
          commit('SET_FRIENDS', response.data.data)
        }
        return response.data.data
      } catch (error) {
        console.error('Error fetching friends:', error)
        return []
      }
    },

    async fetchFriendRequests({ commit }) {
      try {
        const response = await socialApi.getFriendRequests()
        if (response.data.success) {
          commit('SET_FRIEND_REQUESTS', response.data.data)
        }
        return response.data.data
      } catch (error) {
        console.error('Error fetching friend requests:', error)
        return { sent: [], received: [] }
      }
    },

    async checkFriendshipStatus({ commit }, userId) {
      try {
        const response = await socialApi.getFriendshipStatus(userId)
        const { status, data } = response

        commit('SET_FRIENDSHIP_STATUS', {
          userId,
          status: {
            status: status,
            friendshipId: data?.id,
            requestId: data?.id,
          },
        })

        return { status, data }
      } catch (error) {
        console.error('Error checking friendship status:', error)
        return { status: 'error' }
      }
    },

    async sendFriendRequest({ commit }, userId) {
      try {
        const response = await socialApi.sendFriendRequest(userId)
        if (response.data.success) {
          commit('ADD_SENT_REQUEST', response.data.data)
          commit('SET_FRIENDSHIP_STATUS', {
            userId,
            status: { status: 'pending', requestId: response.data.data.id },
          })
        }
        return response.data
      } catch (error) {
        console.error('Error sending friend request:', error)
        throw error
      }
    },

    async cancelRequest({ commit }, { requestId, userId }) {
      try {
        await socialApi.cancelRequest(requestId)
        commit('REMOVE_SENT_REQUEST', requestId)
        commit('SET_FRIENDSHIP_STATUS', {
          userId,
          status: { status: 'none' },
        })
      } catch (error) {
        console.error('Error canceling friend request:', error)
        throw error
      }
    },

    async acceptRequest({ commit, dispatch }, { requestId, userId }) {
      try {
        const response = await socialApi.acceptRequest(requestId)
        if (response.data.success) {
          commit('REMOVE_RECEIVED_REQUEST', requestId)

          const friendsResponse = await socialApi.getFriends()
          if (friendsResponse.data.success) {
            const newFriendship = friendsResponse.data.data.find(
              friend => friend.user_id === userId
            )

            if (newFriendship) {
              commit('ADD_FRIEND', newFriendship)
              commit('SET_FRIENDSHIP_STATUS', {
                userId,
                status: {
                  status: 'friends',
                  friendshipId: newFriendship.friendship_id,
                },
              })
            }
          }
        }
      } catch (error) {
        console.error('Error accepting friend request:', error)
        throw error
      }
    },

    async unfriend({ commit }, { friendshipId, userId }) {
      try {
        await socialApi.unfriend(friendshipId)
        commit('REMOVE_FRIEND', friendshipId)
        commit('SET_FRIENDSHIP_STATUS', {
          userId,
          status: { status: 'none' },
        })
      } catch (error) {
        console.error('Error unfriending:', error)
        throw error
      }
    },

    async rejectRequest({ commit }, { requestId, userId }) {
      try {
        await socialApi.rejectRequest(requestId)
        commit('REMOVE_RECEIVED_REQUEST', requestId)
        commit('SET_FRIENDSHIP_STATUS', {
          userId,
          status: { status: 'none' },
        })
      } catch (error) {
        console.error('Error rejecting friend request:', error)
        throw error
      }
    },
  },

  getters: {
    getFriendshipStatus: state => userId => {
      return state.friendshipStatuses[userId] || { status: 'none' }
    },

    isFriend: state => userId => {
      return state.friends.some(friend => friend.user_id === userId)
    },

    hasPendingRequestTo: state => userId => {
      return state.friendRequests.sent.some(req => req.to_user.id === userId)
    },

    hasPendingRequestFrom: state => userId => {
      return state.friendRequests.received.some(
        req => req.from_user.id === userId
      )
    },
  },
}
