<template>
    <div class="contact-page">
        <!-- Hero Section -->
        <section class="contact-hero">
            <div class="hero-content">
                <h1>Contact B<span class="accent">oo</span>ksVille</h1>
                <p class="hero-subtitle">We'd Love to Hear From You</p>
            </div>
        </section>

        <div class="contact-container">
            <!-- Contact Information Cards -->
            <section class="contact-info-section">
                <div class="info-cards">
                    <div class="info-card">
                        <i class="fa-solid fa-phone"></i>
                        <h3>Call Us</h3>
                        <p>+977 (555) 123-4567</p>
                        <p class="subtitle">Monday to Friday, 9am to 6pm</p>
                    </div>
                    <div class="info-card">
                        <i class="fa-solid fa-envelope"></i>
                        <h3>Email Us</h3>
                        <p>support@booksville.com</p>
                        <p class="subtitle">We'll respond within 24 hours</p>
                    </div>
                    <div class="info-card">
                        <i class="fa-solid fa-location-dot"></i>
                        <h3>Visit Us</h3>
                        <p>123 Book Street</p>
                        <p class="subtitle">Reading City, RC 12345</p>
                    </div>
                </div>
            </section>

            <!-- Contact Form Section -->
            <section class="contact-form-section">
                <div class="form-container">
                    <h2>Send Us a Message</h2>
                    <form @submit.prevent="handleSubmit" class="contact-form">
                        <div class="form-group">
                            <label for="name">Full Name</label>
                            <input type="text" id="name" v-model="formData.name" required
                                placeholder="Your full name" />
                        </div>

                        <div class="form-group">
                            <label for="email">Email Address</label>
                            <input type="email" id="email" v-model="formData.email" required
                                placeholder="Your email address" />
                        </div>

                        <div class="form-group">
                            <label for="subject">Subject</label>
                            <input type="text" id="subject" v-model="formData.subject" required
                                placeholder="What is this about?" />
                        </div>

                        <div class="form-group">
                            <label for="message">Message</label>
                            <textarea id="message" v-model="formData.message" required
                                placeholder="Your message here..." rows="5"></textarea>
                        </div>

                        <button type="submit" class="submit-button" :disabled="isSubmitting">
                            {{ isSubmitting ? 'Sending...' : 'Send Message' }}
                        </button>

                        <!-- Success/Error Message -->
                        <div v-if="submitStatus" :class="['status-message', submitStatus.type]">
                            {{ submitStatus.message }}
                        </div>
                    </form>
                </div>
            </section>

            <!-- FAQ Section -->
            <section class="faq-section">
                <h2>Frequently Asked Questions</h2>
                <div class="faq-container">
                    <div v-for="(faq, index) in faqs" :key="index" class="faq-item"
                        :class="{ 'active': activeFaq === index }">
                        <div class="faq-question" @click="toggleFaq(index)">
                            <span>{{ faq.question }}</span>
                            <i class="fa-solid" :class="activeFaq === index ? 'fa-minus' : 'fa-plus'"></i>
                        </div>
                        <div class="faq-answer" v-show="activeFaq === index">
                            {{ faq.answer }}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { ref, reactive } from 'vue'

export default {
    name: 'ContactUs',
    setup() {
        const formData = reactive({
            name: '',
            email: '',
            subject: '',
            message: ''
        })

        const isSubmitting = ref(false)
        const submitStatus = ref(null)
        const activeFaq = ref(null)

        const faqs = [
            {
                question: "What is your delivery timeline?",
                answer: "We typically deliver within 72 hours within the city limits. Delivery time may vary for other locations."
            },
            {
                question: "Do you offer international shipping?",
                answer: "Currently, we only serve within Nepal. We're working on expanding our services internationally."
            },
            {
                question: "What is your return policy?",
                answer: "We have a strict no-return policy. Please carefully review your order before confirming the purchase."
            },
            {
                question: "How can I track my order?",
                answer: "Once your order is dispatched, you'll receive a tracking number via email to monitor your delivery."
            }
        ]

        const handleSubmit = async () => {
            isSubmitting.value = true
            submitStatus.value = null

            try {
                // Simulate API call
                await new Promise(resolve => setTimeout(resolve, 1500))

                // Success
                submitStatus.value = {
                    type: 'success',
                    message: 'Thank you for your message. We will get back to you soon!'
                }

                // Reset form
                formData.name = ''
                formData.email = ''
                formData.subject = ''
                formData.message = ''

            } catch (error) {
                submitStatus.value = {
                    type: 'error',
                    message: 'Sorry, something went wrong. Please try again later.'
                }
            } finally {
                isSubmitting.value = false
            }
        }

        const toggleFaq = (index) => {
            activeFaq.value = activeFaq.value === index ? null : index
        }

        return {
            formData,
            isSubmitting,
            submitStatus,
            faqs,
            activeFaq,
            handleSubmit,
            toggleFaq
        }
    }
}
</script>

<style scoped>
.contact-page {
    width: 100%;
}

.contact-hero {
    height: 40vh;
    background-image: linear-gradient(rgba(40, 39, 114, 0.8), rgba(40, 39, 114, 0.8)), url('@/assets/img/bg1.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

.hero-content h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
}

.accent {
    color: #f27cf9;
}

.hero-subtitle {
    font-size: 1.5rem;
    opacity: 0.9;
}

.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 2rem;
}

/* Info Cards Styling */
.info-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;
}

.info-card {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

.info-card:hover {
    transform: translateY(-5px);
}

.info-card i {
    font-size: 2rem;
    color: #f27cf9;
    margin-bottom: 1rem;
}

.info-card h3 {
    color: #282772;
    margin-bottom: 0.5rem;
}

.info-card .subtitle {
    color: #666;
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

/* Form Styling */
.contact-form-section {
    background: white;
    padding: 3rem;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 4rem;
}

.contact-form-section h2 {
    color: #282772;
    margin-bottom: 2rem;
    text-align: center;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #282772;
    font-weight: 500;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #f27cf9;
}

.submit-button {
    background-color: #f27cf9;
    color: white;
    border: none;
    padding: 1rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
    transition: background-color 0.3s ease;
}

.submit-button:hover:not(:disabled) {
    background-color: #282772;
}

.submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Status Message Styling */
.status-message {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
}

.status-message.success {
    background-color: #d4edda;
    color: #155724;
}

.status-message.error {
    background-color: #f8d7da;
    color: #721c24;
}

/* FAQ Styling */
.faq-section {
    margin-top: 4rem;
}

.faq-section h2 {
    color: #282772;
    margin-bottom: 2rem;
    text-align: center;
}

.faq-item {
    background: white;
    margin-bottom: 1rem;
    border-radius: 5px;
    overflow: hidden;
}

.faq-question {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    color: #282772;
}

.faq-question i {
    color: #f27cf9;
}

.faq-answer {
    padding: 0 1.5rem 1.5rem;
    color: #666;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .hero-content h1 {
        font-size: 2.5rem;
    }

    .contact-container {
        padding: 2rem 1rem;
    }

    .contact-form-section {
        padding: 2rem;
    }

    .info-cards {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 480px) {
    .hero-content h1 {
        font-size: 2rem;
    }

    .contact-form-section {
        padding: 1.5rem;
    }
}
</style>