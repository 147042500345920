<template>
  <div class="bg">
    <div class="content-wrapper">
      <!-- Logo/Brand -->
      <h1 class="brand">
        B<span class="brand-highlight">OO</span>KSVILLE
      </h1>

      <!-- Error Message -->
      <Transition name="fade">
        <div v-if="error" class="error-message" role="alert">
          <span class="error-icon">⚠</span>
          {{ error }}
        </div>
      </Transition>

      <!-- Login Form -->
      <form @submit.prevent="handleLogin" class="form-container">
        <h3>Welcome Back!</h3>

        <div class="form-fields">
          <CustomInput :modelValue="credentials.email" @update:modelValue="credentials.email = $event" type="email"
            placeholder="Email address" :disabled="loading" required />

          <CustomInput :modelValue="credentials.password" @update:modelValue="credentials.password = $event"
            type="password" placeholder="Password" :disabled="loading" required />
        </div>

        <div class="form-actions">
          <div class="remember">
            <input type="checkbox" :disabled="loading" v-model="rememberMe" @change="handleRememberMe"
              id="remember-me" />
            <label for="remember-me">
              <span class="small-text">Remember me</span>
            </label>
          </div>

          <button type="submit" class="submit-button" :disabled="loading || !isFormValid">
            <span v-if="loading" class="loading-spinner"></span>
            <span class="button-text">{{ loading ? 'Signing in...' : 'Sign in' }}</span>
          </button>
        </div>
      </form>

      <!-- Footer Links -->
      <div class="auth-footer">
        <p class="auth-footer-text">
          Don't have an account?
          <button class="text-link" @click="$router.push({ name: 'signup' })" type="button">
            Sign up
          </button>
        </p>
        <button class="text-link-subtle" @click="$router.push({ name: 'forgot-password' })" type="button">
          Forgot your password?
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import CustomInput from '@/components/shared/CustomInput.vue'

export default {
  name: 'Login',

  components: {
    CustomInput
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const credentials = reactive({
      email: '',
      password: ''
    })

    const loading = ref(false)
    const error = ref('')
    const rememberMe = ref(false)

    const isFormValid = computed(() => {
      return credentials.email.trim() && credentials.password.trim()
    })

    const handleLogin = async () => {
      loading.value = true
      error.value = ''

      try {
        const result = await store.dispatch('login', {
          ...credentials,
          rememberMe: rememberMe.value
        })

        if (result.success) {
          const redirectPath = route.query.redirect || '/home'
          router.push(decodeURIComponent(redirectPath.toString()))
        } else {
          error.value = result.error || 'Login failed'
        }
      } catch (e) {
        console.error('Login error:', e)
        error.value = 'An unexpected error occurred'
      } finally {
        loading.value = false
      }
    }

    const handleRememberMe = (event) => {
      rememberMe.value = event.target.checked
    }

    return {
      credentials,
      loading,
      error,
      rememberMe,
      isFormValid,
      handleLogin,
      handleRememberMe
    }
  }
}
</script>

<style scoped>
.bg {
  background-image: linear-gradient(to bottom,
      rgba(246, 248, 255, 0.72),
      rgba(241, 241, 253, 0.75)), url('@/assets/img/bg1.jpg');
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.bg::before,
.bg::after {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  background: linear-gradient(45deg, rgba(242, 112, 249, 0.08), rgba(40, 39, 114, 0.08));
  border-radius: 50%;
  filter: blur(40px);
  z-index: 0;
  pointer-events: none;
}

.bg::before {
  top: -200px;
  right: -100px;
  animation: float-slow 15s infinite ease-in-out;
}

.bg::after {
  bottom: -200px;
  left: -100px;
  animation: float-slow 15s infinite ease-in-out reverse;
}

.content-wrapper {
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 2.5rem;
  width: 90%;
  max-width: 440px;
  margin: 20px;
  box-shadow:
    0 10px 40px rgba(0, 0, 0, 0.08),
    0 0 0 1px rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand {
  text-align: center;
  color: #282772;
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 2.8rem;
  letter-spacing: -0.5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.brand-highlight {
  color: #f270f9;
  position: relative;
  display: inline-block;
  animation: float 3s ease-in-out infinite;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

h3 {
  color: #282772;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
}

.form-fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.error-message {
  background-color: rgba(255, 242, 242, 0.95);
  color: #d32f2f;
  padding: 14px 24px;
  border-radius: 12px;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: center;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid rgba(211, 47, 47, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.error-icon {
  font-size: 1.2em;
}

.remember {
  display: flex;
  align-items: center;
  gap: 8px;
}

.remember input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 2px solid #e2e8f0;
  border-radius: 4px;
  accent-color: #f270f9;
}

.small-text {
  font-size: 0.9rem;
  color: #64748b;
  font-weight: 500;
}

.submit-button {
  background: linear-gradient(45deg, #f270f9, #e85ee9);
  color: white;
  padding: 15px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.submit-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent);
  transition: 0.5s;
}

.submit-button:hover:not(:disabled)::before {
  left: 100%;
}

.submit-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(242, 112, 249, 0.3);
}

.submit-button:disabled {
  background: #e2e8f0;
  cursor: not-allowed;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

.auth-footer {
  margin-top: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.auth-footer-text {
  color: #666;
  font-size: 0.95rem;
}

.text-link {
  background: none;
  border: none;
  color: #f270f9;
  font-weight: 600;
  padding: 4px 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 6px;
}

.text-link:hover {
  color: #282772;
  background: rgba(242, 112, 249, 0.08);
  transform: translateY(-1px);
}

.text-link-subtle {
  background: none;
  border: none;
  color: #666;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 4px 12px;
  border-radius: 6px;
}

.text-link-subtle:hover {
  color: #282772;
  background: rgba(102, 102, 102, 0.08);
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

@keyframes float-slow {

  0%,
  100% {
    transform: translate(0, 0) rotate(0deg);
  }

  50% {
    transform: translate(30px, 30px) rotate(5deg);
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .bg {
    padding: 16px;
  }

  .content-wrapper {
    padding: 2rem;
    width: 95%;
  }

  .brand {
    font-size: 2.2rem;
    margin-bottom: 20px;
  }

  .error-message {
    padding: 12px 16px;
    font-size: 0.9rem;
    width: 100%;
  }

  .bg::before,
  .bg::after {
    width: 300px;
    height: 300px;
  }
}

@media (max-height: 700px) {
  .content-wrapper {
    margin: 10px;
    padding: 1.5rem;
  }

  .brand {
    margin-bottom: 20px;
  }
}

/* Focus states for accessibility */
.submit-button:focus-visible,
.text-link:focus-visible,
.text-link-subtle:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px rgba(242, 112, 249, 0.4);
}

input:focus-visible {
  outline: none;
  box-shadow: 0 0 0 3px rgba(242, 112, 249, 0.4);
}
</style>