<template>
    <div class="rental-policy">
        <!-- Hero Section -->
        <section class="policy-hero">
            <div class="hero-content">
                <h1>Rental P<span class="accent">oo</span>licy</h1>
                <p class="hero-subtitle">Easy and Flexible Book Rentals</p>
            </div>
        </section>

        <div class="policy-container">
            <!-- Quick Info Cards -->
            <div class="policy-highlights">
                <div class="highlight-card">
                    <i class="fa-regular fa-clock"></i>
                    <h3>Flexible Duration</h3>
                    <p>Choose from 15, 30, or 45-day rental periods</p>
                </div>
                <div class="highlight-card">
                    <i class="fa-solid fa-percent"></i>
                    <h3>Fair Pricing</h3>
                    <p>Pay only 15-50% of book's price for rentals</p>
                </div>
                <div class="highlight-card">
                    <i class="fa-solid fa-cart-shopping"></i>
                    <h3>Purchase Option</h3>
                    <p>Convert your rental to purchase anytime</p>
                </div>
            </div>

            <!-- Main Policy Content -->
            <div class="policy-content">
                <!-- Rental Terms -->
                <section class="policy-section">
                    <h2><i class="fa-solid fa-book-open"></i> Rental Terms</h2>
                    <div class="rental-options">
                        <div class="rental-option">
                            <div class="duration">15 Days</div>
                            <div class="price">15% of book price</div>
                        </div>
                        <div class="rental-option">
                            <div class="duration">30 Days</div>
                            <div class="price">30% of book price</div>
                        </div>
                        <div class="rental-option">
                            <div class="duration">45 Days</div>
                            <div class="price">50% of book price</div>
                        </div>
                    </div>
                </section>

                <!-- Payment Process -->
                <section class="policy-section">
                    <h2><i class="fa-solid fa-wallet"></i> Payment Process</h2>
                    <div class="process-steps">
                        <div class="step">
                            <div class="step-number">1</div>
                            <div class="step-content">
                                <h4>Initial Payment</h4>
                                <p>Pay full book price at checkout</p>
                            </div>
                        </div>
                        <div class="step">
                            <div class="step-number">2</div>
                            <div class="step-content">
                                <h4>During Rental</h4>
                                <p>Enjoy your book for the selected period</p>
                            </div>
                        </div>
                        <div class="step">
                            <div class="step-number">3</div>
                            <div class="step-content">
                                <h4>Return & Refund</h4>
                                <p>Get refund minus rental fee upon return</p>
                            </div>
                        </div>
                    </div>
                </section>

                <!-- Important Notes -->
                <section class="policy-section">
                    <h2><i class="fa-solid fa-circle-exclamation"></i> Important Notes</h2>
                    <div class="notes-grid">
                        <div class="note">
                            <h4>Book Condition</h4>
                            <p>Books must be returned in original condition. Damage will result in purchase.</p>
                        </div>
                        <div class="note">
                            <h4>Purchase Option</h4>
                            <p>5% discount if you decide to buy within 5 days of rental end.</p>
                        </div>
                        <div class="note">
                            <h4>Late Returns</h4>
                            <p>1% daily late fee. Auto-purchase after 1 day overdue.</p>
                        </div>
                        <div class="note">
                            <h4>Return Process</h4>
                            <p>Initiate returns through website for shipping instructions.</p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RentalPolicy'
}
</script>

<style scoped>
.rental-policy {
    width: 100%;
}

.policy-hero {
    height: 40vh;
    background-image: linear-gradient(rgba(40, 39, 114, 0.8), rgba(40, 39, 114, 0.8)), url('@/assets/img/bg1.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

.hero-content h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
}

.accent {
    color: #f27cf9;
}

.hero-subtitle {
    font-size: 1.5rem;
    opacity: 0.9;
}

.policy-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 2rem;
}

/* Policy Highlights */
.policy-highlights {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;
}

.highlight-card {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

.highlight-card:hover {
    transform: translateY(-5px);
}

.highlight-card i {
    font-size: 2rem;
    color: #f27cf9;
    margin-bottom: 1rem;
}

.highlight-card h3 {
    color: #282772;
    margin-bottom: 0.5rem;
}

/* Policy Sections */
.policy-section {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

.policy-section h2 {
    color: #282772;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.policy-section h2 i {
    color: #f27cf9;
}

/* Rental Options */
.rental-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
}

.rental-option {
    padding: 1.5rem;
    border-radius: 8px;
    background: #f8f9fa;
    text-align: center;
    transition: transform 0.3s ease;
}

.rental-option:hover {
    transform: scale(1.05);
}

.rental-option .duration {
    font-size: 1.25rem;
    color: #282772;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.rental-option .price {
    color: #f27cf9;
}

/* Process Steps */
.process-steps {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
}

.step {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.step-number {
    width: 40px;
    height: 40px;
    background: #f27cf9;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.step-content h4 {
    color: #282772;
    margin-bottom: 0.5rem;
}

/* Notes Grid */
.notes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
}

.note {
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.note:hover {
    transform: translateY(-5px);
}

.note h4 {
    color: #282772;
    margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
    .hero-content h1 {
        font-size: 2.5rem;
    }

    .policy-container {
        padding: 2rem 1rem;
    }

    .process-steps {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 480px) {
    .hero-content h1 {
        font-size: 2rem;
    }

    .policy-section {
        padding: 1.5rem;
    }
}
</style>