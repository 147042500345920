<template>
    <div class="terms-container">
        <h1 class="page-title">Terms and Conditions</h1>

        <div class="terms-content">
            <section>
                <h2 class="section-title">1. Introduction</h2>
                <p>
                    Welcome to BooksVille. These terms and conditions govern your use of BooksVille's website and
                    services, including our e-commerce platform and social features. By using our website, you accept
                    these terms and conditions in full.
                </p>
            </section>

            <section>
                <h2 class="section-title">2. Account Registration</h2>
                <p>
                    To use certain features of the website, you must register for an account. You agree to:
                </p>
                <ul>
                    <li>Provide accurate and complete information</li>
                    <li>Maintain the security of your account credentials</li>
                    <li>Accept responsibility for all activities that occur under your account</li>
                </ul>
            </section>

            <section>
                <h2 class="section-title">3. Purchase and Payment Terms</h2>
                <p>
                    When making a purchase on BooksVille:
                </p>
                <ul>
                    <li>All prices are in Nepali Rupees and include applicable taxes</li>
                    <li>Payment must be made in full at the time of purchase</li>
                    <li>We accept Esewa, Khalti</li>
                    <li>Orders are subject to availability and confirmation</li>
                    <li>Digital receipts will be sent to your registered email</li>
                </ul>
            </section>

            <section>
                <h2 class="section-title">4. Shipping and Delivery</h2>
                <p>
                    Our shipping and delivery terms:
                </p>
                <ul>
                    <li>Delivery times are estimates and not guaranteed</li>
                    <li>Shipping costs are calculated at checkout</li>
                    <li>We do shipments for Kathmandu and Pokhara</li>
                    <li>Risk of loss transfers upon delivery to the carrier</li>
                    <li>Customers are responsible for providing accurate shipping information</li>
                </ul>
            </section>

            <section>
                <h2 class="section-title">5. Returns and Refunds Policy</h2>
                <p>
                    BooksVille maintains a strict no-returns policy. Please note:
                </p>
                <ul>
                    <li>We do not accept returns or exchanges of purchased books</li>
                    <li>All sales are final once the order is placed and confirmed</li>
                    <li>Customers are strongly advised to carefully review their orders before purchase</li>
                    <li>Check book details, edition, and condition description thoroughly</li>
                    <li>In case of damaged delivery or wrong item shipped, contact customer service within 48 hours with
                        photo evidence</li>
                </ul>
                <p class="policy-note">
                    We encourage our customers to make informed decisions and carefully confirm their book selections
                    before completing their purchase. If you have any questions about a book's condition or details,
                    please contact our customer service before placing your order.
                </p>
            </section>

            <section>
                <h2 class="section-title">6. Social Features and User Content</h2>
                <p>
                    When using our social features:
                </p>
                <ul>
                    <li>You retain ownership of your content</li>
                    <li>You grant us a license to use, display, and distribute your content</li>
                    <li>Content must not violate our community guidelines</li>
                    <li>We reserve the right to remove inappropriate content</li>
                    <li>You are responsible for interactions with other users</li>
                </ul>
            </section>

            <section>
                <h2 class="section-title">7. Prohibited Activities</h2>
                <p>
                    Users must not:
                </p>
                <ul>
                    <li>Use the platform for illegal purposes</li>
                    <li>Post inappropriate or offensive content</li>
                    <li>Attempt to access restricted areas</li>
                    <li>Impersonate others or misrepresent relationships</li>
                    <li>Collect user information without consent</li>
                </ul>
            </section>

            <section>
                <h2 class="section-title">8. Intellectual Property</h2>
                <p>
                    All content on BooksVille, including logos, designs, text, and graphics, is protected by
                    intellectual property rights. Users may not copy, reproduce, or use this content without permission.
                </p>
            </section>

            <section>
                <h2 class="section-title">9. Privacy and Data Protection</h2>
                <p>
                    We collect and process personal data as described in our Privacy Policy. By using BooksVille, you
                    consent to our data practices.
                </p>
            </section>

            <section>
                <h2 class="section-title">10. Limitation of Liability</h2>
                <p>
                    BooksVille is not liable for:
                </p>
                <ul>
                    <li>Indirect or consequential losses</li>
                    <li>Service interruptions or technical issues</li>
                    <li>User-generated content</li>
                    <li>Third-party links or services</li>
                    <li>Delivery delays or shipping issues beyond our control</li>
                </ul>
            </section>

            <section>
                <h2 class="section-title">11. Changes to Terms</h2>
                <p>
                    We reserve the right to modify these terms at any time. Changes will be effective immediately upon
                    posting to the website. Continued use of the platform constitutes acceptance of modified terms.
                </p>
            </section>

            <section>
                <h2 class="section-title">12. Contact Information</h2>
                <p>
                    For questions about these terms, contact us at:
                    <br />
                    Email: booksville@books.com
                    <br />
                    Address: Pokhara
                </p>
            </section>

            <p class="last-updated">
                Last updated: 2024-11-12
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TermsAndConditions'
}
</script>

<style scoped>
.terms-container {
    max-width: 64rem;
    margin: 0 auto;
    padding: 2rem 3rem;
    color: #333;
}

.page-title {
    font-size: 2rem;
    font-weight: 700;
    color: #282772;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #282772;
}

.terms-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

section {
    margin-bottom: 1.5rem;
}

.section-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #282772;
    margin-bottom: 1rem;
}

p {
    margin-bottom: 1rem;
    line-height: 1.6;
}

ul {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
}

li {
    margin-bottom: 0.5rem;
    line-height: 1.6;
}

.last-updated {
    font-style: italic;
    color: #666;
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .terms-container {
        padding: 1.5rem;
    }

    .page-title {
        font-size: 1.75rem;
    }

    .section-title {
        font-size: 1.1rem;
    }
}

@media (max-width: 480px) {
    .terms-container {
        padding: 1rem;
    }

    .page-title {
        font-size: 1.5rem;
    }

    .section-title {
        font-size: 1rem;
    }
}
</style>