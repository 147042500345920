<template>
  <div class="person-card">
    <div class="person-header">
      <div class="person-info">
        <div class="avatar">
          <img :src="person?.avatar || '/default-avatar.jpg'" :alt="person?.name || 'User'" />
        </div>
        <div class="details">
          <h3>{{ person?.name || 'Unknown User' }}</h3>
        </div>
      </div>
      <div class="menu-wrapper" v-if="showMenu">
        <button class="menu-button" @click="toggleMenu">
          <i class="fas fa-ellipsis-h"></i>
        </button>
        <div v-if="isMenuOpen" class="menu-dropdown">
          <div class="menu-item" @click="$emit('view-profile', person?.id)">
            <i class="fas fa-user"></i>
            View Profile
          </div>
        </div>
      </div>
    </div>

    <div class="interests" v-if="person?.commonInterests?.length">
      <div v-for="interest in person.commonInterests" :key="interest" class="interest-tag">
        {{ interest }}
      </div>
    </div>

    <div class="common-books" v-if="person?.commonBooks?.length">
      <h4>Books in Common</h4>
      <div class="book-list">
        <div v-for="book in person.commonBooks" :key="book.id" class="book-item">
          <img :src="book.cover" :alt="book.title" />
          <span class="book-title">{{ book.title }}</span>
        </div>
      </div>
    </div>

    <button :class="['add-friend-button', { sent: person?.requestSent }]" @click="$emit('send-request', person?.id)"
      :disabled="person?.requestSent">
      <i :class="['fas', person?.requestSent ? 'fa-check' : 'fa-user-plus']"></i>
      {{ person?.requestSent ? 'Request Sent' : 'Add Friend' }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'PersonCard',
  props: {
    person: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        name: 'Unknown User',
        avatar: '/default-avatar.jpg',
        commonInterests: [],
        commonBooks: [],
        requestSent: false,
      }),
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isMenuOpen: false,
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    closeMenu() {
      this.isMenuOpen = false
    },
    handleClickOutside(event) {
      if (this.isMenuOpen && !event.target.closest('.menu-wrapper')) {
        this.closeMenu()
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
}
</script>

<style scoped>
.person-card {
  position: relative;
  background-color: #f8f9fa;
  backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 1.75rem;
  overflow: hidden;
  transition: all 0.4s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.card-glass {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.05) 100%);
  z-index: 0;
}

.card-content {
  position: relative;
  z-index: 1;
}

.person-card:hover {
  transform: translateY(-8px) scale(1.02);
  box-shadow: 0 20px 40px rgba(138, 43, 226, 0.1);
  border-color: rgba(138, 43, 226, 0.2);
}

.person-header {
  position: relative;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.person-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 16px;
  overflow: hidden;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details h3 {
  font-size: 1.25rem;
  font-weight: 700;
  background: linear-gradient(135deg,
      var(--primary-purple),
      var(--primary-pink));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
}

.interest-tag {
  background: linear-gradient(135deg,
      rgba(138, 43, 226, 0.1),
      rgba(255, 105, 180, 0.1));
  border: 1px solid rgba(138, 43, 226, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 12px;
  font-size: 0.875rem;
  color: var(--primary-purple);
  transition: all 0.3s ease;
  display: inline-block;
  margin: 0.25rem;
}

.interest-tag:hover {
  background: linear-gradient(135deg,
      var(--primary-purple),
      var(--primary-pink));
  color: white;
  transform: translateY(-2px);
}

.common-books {
  margin: 1.5rem 0;
}

.common-books h4 {
  font-size: 0.875rem;
  color: var(--text-gray);
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.book-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  gap: 1rem;
}

.book-item {
  position: relative;
  transition: transform 0.3s ease;
}

.book-item:hover {
  transform: translateY(-4px);
}

.book-item img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.add-friend-button {
  width: 100%;
  padding: 1rem;
  border-radius: 14px;
  font-size: 0.9375rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  background: linear-gradient(135deg,
      var(--primary-purple),
      var(--primary-pink));
  color: white;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.add-friend-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent);
  transition: left 0.3s ease;
}

.add-friend-button:hover::before {
  left: 100%;
}

.add-friend-button.sent {
  background: var(--success-green);
}

.menu-wrapper {
  position: relative;
}

.menu-button {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: var(--text-gray);
  transition: color 0.3s ease;
}

.menu-button:hover {
  color: var(--primary-purple);
}

.menu-dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  z-index: 10;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: var(--text-dark);
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.menu-item:hover {
  background: var(--primary-light);
  color: var(--primary-purple);
}

@media (max-width: 768px) {
  .person-card {
    margin: 0.5rem 0;
  }

  .avatar {
    width: 60px;
    height: 60px;
  }

  .details h3 {
    font-size: 1.125rem;
  }
}
</style>