// components/BillingForm.vue
<template>
  <div class="billing-form">
    <h2>Billing Details</h2>

    <div class="form-row">
      <div class="form-group">
        <label>First Name</label>
        <input type="text" v-model="localForm.firstName" @input="emitUpdate" />
      </div>
      <div class="form-group">
        <label>Last Name</label>
        <input type="text" v-model="localForm.lastName" @input="emitUpdate" />
      </div>
    </div>

    <div class="form-group">
      <label>Street Address</label>
      <input
        type="text"
        v-model="localForm.streetAddress"
        @input="emitUpdate"
      />
    </div>

    <div class="form-row">
      <div class="form-group">
        <label>Country</label>
        <input type="text" v-model="localForm.country" @input="emitUpdate" />
      </div>
      <div class="form-group">
        <label>Province</label>
        <input type="text" v-model="localForm.province" @input="emitUpdate" />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group">
        <label>Town/City</label>
        <input type="text" v-model="localForm.city" @input="emitUpdate" />
      </div>
      <div class="form-group">
        <label>ZIP Code</label>
        <input type="text" v-model="localForm.zipCode" @input="emitUpdate" />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group">
        <label>Phone</label>
        <input type="tel" v-model="localForm.phone" @input="emitUpdate" />
      </div>
      <div class="form-group">
        <label>Email address</label>
        <input type="email" v-model="localForm.email" @input="emitUpdate" />
      </div>
    </div>

    <div class="form-group">
      <label>Order notes (optional)</label>
      <textarea
        v-model="localForm.notes"
        @input="emitUpdate"
        rows="4"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BillingForm',

  props: {
    form: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      localForm: { ...this.form },
    }
  },

  methods: {
    emitUpdate() {
      this.$emit('form-updated', this.localForm)
    },
  },

  watch: {
    form: {
      handler(newValue) {
        this.localForm = { ...newValue }
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.billing-form {
  flex: 1;
  background: #f8f9fa;
  padding: 30px;
  border-radius: 8px;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
  font-weight: 500;
}

input,
textarea {
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 14px;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 15px;
  }
}
</style>
