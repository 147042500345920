<template>
  <div class="review-content">
    <div class="reviews-container">
      <div v-if="loading" class="loading-reviews">
        <i class="fas fa-spinner fa-spin"></i>
        Loading reviews...
      </div>
      <div v-else-if="error" class="error-message">
        {{ error }}
      </div>
      <template v-else>
        <div class="reviews-header">
          <h2 class="reviews-title">Customer Reviews</h2>
          <div class="reviews-summary">
            <span class="reviews-count">
              {{ book.total_ratings }}
              {{ book.total_ratings === 1 ? 'review' : 'reviews' }}
            </span>
            <div class="average-rating" v-if="book.total_ratings > 0">
              <div class="stars">
                <i
                  v-for="star in 5"
                  :key="star"
                  class="fa-star"
                  :class="
                    star <= Math.round(Number(book.rating)) ? 'fas' : 'far'
                  "
                ></i>
              </div>
              <span class="rating-average"
                >{{ Number(book.rating).toFixed(1) }} out of 5</span
              >
            </div>
          </div>
        </div>

        <ReviewList v-if="reviews.length > 0" :reviews="reviews" />

        <div v-if="!loading && reviews.length === 0" class="no-reviews">
          No reviews yet. Be the first to review this book!
        </div>

        <div v-if="totalReviews > 0" class="pagination-controls">
          <button
            @click="changePage(currentPage - 1)"
            :disabled="!previousPage || loading"
            class="pagination-btn"
          >
            Previous
          </button>

          <span class="page-info">Page {{ currentPage }}</span>

          <button
            @click="changePage(currentPage + 1)"
            :disabled="!nextPage || loading"
            class="pagination-btn"
          >
            Next
          </button>
        </div>
      </template>
    </div>

    <div class="review-form-container">
      <ReviewForm
        :book-id="book.id"
        @submit-success="handleReviewSubmit"
        @review-updated="handleReviewUpdate"
        @review-deleted="handleReviewDelete"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, defineComponent } from 'vue'
import api from '@/api/axios'
import ReviewList from '../review/ReviewList.vue'
import ReviewForm from '../review/ReviewForm.vue'

export default defineComponent({
    name: 'ReviewTab',
    components: {
        ReviewList,
        ReviewForm,
    },
    props: {
        book: {
            type: Object,
            required: true,
            validator(book) {
                return typeof book.id !== 'undefined'
            },
        },
    },
    emits: ['review-submitted'],
    setup(props, { emit }) {
        const reviews = ref([])
        const loading = ref(true)
        const error = ref('')
        const currentPage = ref(1)
        const totalReviews = ref(0)
        const nextPage = ref(null)
        const previousPage = ref(null)

        const fetchReviews = async (page = 1) => {
            try {
                loading.value = true
                error.value = ''

                const response = await api.get(
                    `/books/${props.book.id}/reviews/?page=${page}`
                )

                if (response.data.status === 'success') {
                    reviews.value = response.data.results || []
                    totalReviews.value = response.data.count
                    nextPage.value = response.data.next
                    previousPage.value = response.data.previous
                    currentPage.value = page
                }
            } catch (err) {
                error.value = 'Failed to load reviews. Please try again later.'
                console.error('Error fetching reviews:', err)
                reviews.value = []
            } finally {
                loading.value = false
            }
        }

        const changePage = (newPage) => {
            if (newPage < 1) return
            fetchReviews(newPage)
            const container = document.querySelector('.reviews-container')
            if (container) {
                container.scrollIntoView({ behavior: 'smooth' })
            }
        }

        const handleReviewSubmit = async () => {
            emit('review-submitted')
            await fetchReviews(1)
        }

        const handleReviewUpdate = async () => {
            emit('review-submitted')
            await fetchReviews(currentPage.value)
        }

        const handleReviewDelete = async () => {
            emit('review-submitted')
            await fetchReviews(1)
        }

        onMounted(() => fetchReviews(1))

        watch(
            () => props.book.id,
            () => {
                currentPage.value = 1
                fetchReviews(1)
            }
        )

        return {
            reviews,
            loading,
            error,
            currentPage,
            totalReviews,
            nextPage,
            previousPage,
            handleReviewSubmit,
            handleReviewUpdate,
            handleReviewDelete,
            changePage,
        }
    },
})
</script>

<style scoped>
.review-content {
  display: grid;
  grid-template-columns: 1fr 380px;
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.reviews-container {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.reviews-header {
  margin-bottom: 2rem;
}

.reviews-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1rem;
}

.reviews-summary {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.stars {
  display: flex;
  gap: 0.25rem;
  color: #f27cf9;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.review-form-container {
  position: sticky;
  top: 2rem;
  height: fit-content;
}

@media (max-width: 1200px) {
  .review-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .review-form-container {
    position: static;
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .reviews-container {
    padding: 1.5rem;
  }

  .reviews-summary {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }
}

@media (max-width: 480px) {
  .reviews-container {
    padding: 1rem;
  }

  .reviews-title {
    font-size: 1.25rem;
  }

  .pagination-controls {
    gap: 0.5rem;
  }
}
</style>
