<template>
    <div class="books-section">

        <div v-if="profile.favorite_books?.length" class="favorite-books">
            <div class="section-header">
                <h3>
                    <i class="fas fa-heart"></i>
                    Favorite Books
                </h3>
                <span class="section-subtitle">{{ profile.favorite_books.length }} favorite(s)</span>
            </div>

            <transition-group name="books-grid" tag="div" class="books-grid">
                <BookCard v-for="book in profile.favorite_books" :key="book.id" :book="book" />
            </transition-group>
        </div>

        <div v-if="!profile.currently_reading && !profile.favorite_books?.length" class="empty-state">
            <div class="empty-content">
                <i class="fas fa-books"></i>
                <h3>No Books Yet</h3>
                <p v-if="isOwnProfile">
                    Start adding books to your collection!
                    <router-link to="/catalog" class="browse-link">Browse Books</router-link>
                </p>
                <p v-else>
                    {{ profile.first_name }} hasn't added any books yet.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import BookCard from '@/components/shared/BookCard.vue'

export default defineComponent({
    name: 'ProfileBooks',

    components: {
        BookCard
    },

    props: {
        profile: {
            type: Object,
            required: true
        },
        isOwnProfile: {
            type: Boolean,
            default: false
        }
    }
})
</script>

<style scoped>
.books-section {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.section-header {
    margin-bottom: 1.5rem;
}

.section-header h3 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
    font-size: 1.25rem;
    color: #1a1a1a;
}

.section-header i {
    color: #f27cf9;
}

.section-subtitle {
    display: block;
    margin-top: 0.25rem;
    color: #666;
    font-size: 0.9rem;
}


.favorite-books {
    background: white;
    border-radius: 16px;
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.books-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 2rem;
}

/* Empty State Styles */
.empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
    background: white;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.empty-content {
    text-align: center;
    color: #666;
}

.empty-content i {
    font-size: 3rem;
    color: #f27cf9;
    margin-bottom: 1rem;
}

.empty-content h3 {
    color: #1a1a1a;
    margin: 0 0 0.5rem 0;
    font-size: 1.25rem;
}

.empty-content p {
    margin: 0;
    line-height: 1.6;
}

.browse-link {
    color: #f27cf9;
    text-decoration: none;
    font-weight: 500;
    margin-left: 0.5rem;
}

.browse-link:hover {
    text-decoration: underline;
}

/* Grid Transitions */
.books-grid-enter-active,
.books-grid-leave-active {
    transition: all 0.3s ease;
}

.books-grid-enter-from,
.books-grid-leave-to {
    opacity: 0;
    transform: translateY(20px);
}

.books-grid-move {
    transition: transform 0.3s ease;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .books-grid {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        gap: 1.5rem;
    }
}

@media (max-width: 768px) {
    .books-section {
        padding: 0.5rem;
        gap: 1rem;
    }


    .books-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 1rem;
    }

    .section-header h3 {
        font-size: 1.1rem;
    }
}

@media (max-width: 480px) {
    .books-grid {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
        gap: 0.75rem;
    }

    .empty-state {
        padding: 2rem 1rem;
    }

    .empty-content i {
        font-size: 2.5rem;
    }
}
</style>